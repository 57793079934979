@charset "utf-8";
/** 
* 
* -----------------------------------------------------------------------------
*
* Template : Braintech - Technology & IT Solutions HTML Template
* Author : rs-theme
* Author URI : http://www.rstheme.com/ 
*
* -----------------------------------------------------------------------------
* 
**//* TABLE OF CONTENT
---------------------------------------------------------
    01. General CSS
    02. Global Class CSS
    03. Header Section CSS
    04. Sticky Menu CSS
    05. About Section CSS
    06. Banner Section CSS
    07. Breadcrumbs Section CSS
    08. Slider Section CSS    
    09. Video Section CSS
    10. Faq Section CSS
    11. Counter Section CSS
    12. Contact Section CSS
    13. Cta Section CSS
    14. Skill-bar Section CSS
    15. Choose Section CSS
    16. Team Section CSS
    17. Services Section CSS
    18. Process Section CSS
    19. Pricing Section CSS
    20. Technology Section CSS
    21. Industry Section CSS
    22. Project Section CSS
    23. Progress Section CSS
    24. Case Study Section CSS
    25. Blog Section CSS
    26. Features Section CSS
    27. Call Action Section CSS
    28. Partner Section CSS
    29. Testimonial Section CSS
    30. Shop Section CSS
    31. Single Shop Section CSS
    32. Cart Section CSS
    33. Checkout Section CSS
    34. Account Section CSS
    35. Footer Section CSS
    36. Error Page CSS
    37. Scroll Up CSS
    38. Preloader CSS
    39. Animation Part CSS



--------------------------------------------------------*/
@font-face {
  font-family: 'Livvic';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(http://fonts.gstatic.com/s/livvic/v11/rnCr-x1S2hzjrlfXbeM-.ttf) format('truetype');
}
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(http://fonts.gstatic.com/s/livvic/v11/rnCq-x1S2hzjrlffw8Eesg.ttf) format('truetype');
}
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(http://fonts.gstatic.com/s/livvic/v11/rnCp-x1S2hzjrlfnbw.ttf) format('truetype');
}
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(http://fonts.gstatic.com/s/livvic/v11/rnCq-x1S2hzjrlffm8Aesg.ttf) format('truetype');
}
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(http://fonts.gstatic.com/s/livvic/v11/rnCq-x1S2hzjrlfft8cesg.ttf) format('truetype');
}
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(http://fonts.gstatic.com/s/livvic/v11/rnCq-x1S2hzjrlff08Yesg.ttf) format('truetype');
}
@font-face {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(http://fonts.gstatic.com/s/livvic/v11/rnCq-x1S2hzjrlff68Qesg.ttf) format('truetype');
}
/* -----------------------------------
    01. General CSS
-------------------------------------*/
html,
body {
  font-size: 16px;
  color: #454545;
  font-family: 'Livvic', sans-serif;
  vertical-align: baseline;
  line-height: 26px;
  font-weight: 400;
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
}
p {
  margin: 0 0 26px;
  line-height: 1.8;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Livvic', sans-serif;
  color: #101010;
  margin: 0 0 26px;
  line-height: 1.2;
}
h1 {
  font-size: 70px;
  font-weight: 700;
}
h2 {
  font-size: 36px;
  font-weight: 700;
}
h3 {
  font-size: 28px;
  font-weight: 700;
}
h4 {
  font-size: 24px;
  font-weight: 700;
}
h5 {
  font-size: 18px;
  font-weight: 700;
}
h6 {
  font-size: 16px;
  font-weight: 700;
}
.pb-230 {
  padding-bottom: 230px;
}
.pb-435 {
  padding-bottom: 435px;
}
.pr-220 {
  padding-right: 220px;
}
.offwrap {
  cursor: url(../images/close.png), auto;
  width: 100%;
  left: 100%;
  transition: all 0.8s ease-out 0s;
  position: fixed;
  background: rgba(255, 255, 255, 0.3);
  height: 100vh;
  top: 0;
  bottom: 0;
  z-index: 999;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.home11 {
  background: #000000;
}
body.nav-expanded .offwrap {
  left: 0%;
  transition: all 0.8s ease-out 0s;
}
.home-style2 h1,
.home-style2 h2,
.home-style2 h3,
.home-style2 h4,
.home-style2 h5,
.home-style2 h6 {
  color: #0a0a0a;
}
.no-border {
  border: none !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.bold-text {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Livvic', sans-serif;
}
.extra-bold {
  font-weight: 800 !important;
}
.gray-color {
  background-color: #F6F7F9;
}
.gray-bg2 {
  background: #f7f9ff;
}
.gray-bg3 {
  background: #F5F6FE;
}
.black-dark {
  background: #131313 !important;
}
.gray-color-important {
  background-color: #F6F7F9 !important;
}
.primary-background {
  background-color: #106eea;
}
.white-bg {
  background: #ffffff;
}
.white-color {
  color: #ffffff !important;
}
.pt-255 {
  padding-top: 255px;
}
.pb-390 {
  padding-bottom: 390px;
}
.md-pt-215 {
  padding-top: 215px;
}
.pb-215 {
  padding-bottom: 215px;
}
.rs-call-us .image-part img {
  max-height: 585px;
}
.text-right {
  text-align: right;
}
a {
  color: #106eea;
  transition: all 0.3s ease;
  text-decoration: none !important;
  outline: none !important;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0 none;
  color: #106eea;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.clear {
  clear: both;
}
::-moz-selection {
  background: #106eea;
  text-shadow: none;
  color: #ffffff;
}
::selection {
  background: #106eea;
  text-shadow: none;
  color: #ffffff;
}
.bg1 {
  background: url(../images/bg/testimonial-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000060;
}
.bg2 {
  background: url(../images/bg/counter-bg.png);
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: -1px 21px 25px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px 10px 10px 10px;
  padding: 40px 40px 40px 40px;
  z-index: 10;
}
.bg3 {
  background: url(../images/bg/process-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #03228F;
  margin: 0px 31px 0px 0px;
  padding: 80px 40px 75px 40px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  border-radius: 10px 10px 10px 10px;
}
.bg4 {
  background: url(../images/bg/about-2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bg5 {
  background: url(../images/bg/project-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #000060;
}
.bg6 {
  background: url(../images/bg/project-bg.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-color: #000060;
}
.bg7 {
  background: url(../images/bg/cta-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bg8 {
  background: url(../images/bg/call-action.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bg9 {
  background: url(../images/bg/work-bg.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 100px 60px 100px 50px;
  border-radius: 10px 10px 10px 10px;
}
.bg10 {
  background: url(../images/bg/testi-home7.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 100px 90px 100px 90px;
  border-radius: 5px 5px 5px 5px;
}
.bg11 {
  background: url(../images/bg/index-bg.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-color: #0C54B8;
}
.bg12 {
  background: url(../images/bg/case-study.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 110px 160px 185px 90px;
}
.bg-banner {
  background: url(../images/banner/b4-dot-6.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg12.mod {
  padding: 113px 160px 194px 90px;
}
.bg13 {
  background: url(../images/bg/services-bg.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.bg14 {
  background: url(../images/bg/choose-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg15 {
  background: url(../images/bg/steps-area.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #F3F7FF;
}
.bg16 {
  background: url(../images/bg/tech-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #020E38;
}
.bg17 {
  background: url(../images/bg/call-to-action2.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg18 {
  background: url(../images/bg/shape-bg2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.bg19 {
  background: url(../images/bg/3counter-.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 80px 60px 70px 60px;
}
.bg20 {
  background: url(../images/bg/services-bg-shape.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bg21 {
  background: url(../images/bg/counter-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg22 {
  background: url(../images/bg/testi12-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg23 {
  background: url(../images/bg/call-action3.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg24 {
  background: url(../images/bg/cta-bg13.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg25 {
  background: url(../images/bg/skill-bg16.jpg);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg26 {
  background: url(../images/bg/service-bg16.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg27 {
  background: url(../images/bg/counter-bg16.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0px 460px 0px;
}
.bg28 {
  background: url(../images/bg/testi-bg16.jpg);
  background-position: center right;
  background-repeat: no-repeat;
}
.bg29 {
  background: url(../images/bg/cta-bg16.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg30 {
  background: url(../images/bg/counter-bg16.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-step {
  background: url(../images/bg/steps-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 10px 10px;
  padding: 258px 0;
}
.shape-part .right-side,
.shape-part .left-side {
  position: absolute;
  bottom: 0px;
  left: 0;
}
.shape-part .right-side {
  left: auto;
  right: 0;
}
.bg-unset {
  background: unset !important;
}
.rs-menu-toggle {
  color: #106eea;
  text-align: right;
}
button {
  cursor: pointer;
}
.glyph-icon:before {
  margin: 0;
}
.z-index-1 {
  position: relative;
  z-index: 1;
}
ul.services-list li a {
  display: block;
  border: 2px solid #e8e8e8;
  padding: 16px 18px;
  transition: all .5s ease;
  border-radius: 5px;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  color: #101010;
  margin-bottom: 15px;
}
ul.services-list li a:before {
  content: "\f113";
  position: absolute;
  right: 12px;
  top: 16px;
  font-family: Flaticon;
  color: #1c1b1b;
}
ul.services-list li a:hover,
ul.services-list li a.active {
  background-image: linear-gradient(250deg, #1273eb 19%, #03228f 100%);
  color: #ffffff;
  border-radius: 5px;
  border-color: #1273eb;
}
ul.services-list li a:hover:before,
ul.services-list li a.active:before {
  color: #ffffff;
}
ul.listing-style li {
  margin-bottom: 7px;
}
ul.listing-style li i {
  color: #03228F;
  font-size: 24px;
  line-height: 31px;
  margin-right: 10px;
}
ul.rs-features-list li {
  margin: 0px 0px 21px 0px;
}
ul.rs-features-list li i {
  color: #fff;
  background-color: transparent;
  background-image: linear-gradient(180deg, #dd4c23 0%, #f27c1e 100%);
  padding: 10px 10px 10px 10px;
  margin: 0px 20px 0px 0px;
  border-radius: 50px 50px 50px 50px;
}
ul.rs-features-list li span {
  color: #575757;
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
}
ul.rs-features-list li span:hover {
  color: #FC4F29;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}
.pagination-part {
  display: inline-block;
  border: 1px solid #f9f9f9;
  padding: 11px 0;
  border-radius: 3px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  background: #fff;
}
.pagination-part li {
  padding: 2px 20px 0 21px;
  line-height: 23px;
  font-size: 18px;
  text-decoration: none;
  transition: all 0.4s ease-in-out 0s;
  text-align: center;
  background: 0 0;
  color: #000;
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #e6e6e6;
}
.pagination-part li a {
  color: #454545;
}
.pagination-part li:last-child {
  border-right: unset;
}
.pagination-part .next-page {
  position: relative;
  transition: all 0.4s ease-in-out 0s;
  padding-right: 12px;
}
.pagination-part .next-page:before,
.pagination-part .next-page:after {
  content: "\f105";
  position: absolute;
  top: 6px;
  right: 0;
  color: #454545;
  line-height: 16px;
  font-family: fontawesome;
  font-size: 12px;
}
.pagination-part .next-page:before {
  right: 4px;
}
.pagination-part .active a {
  color: #106eea;
}
.gridFilter button {
  font-size: 16px;
  background: 0 0!important;
  color: #0a0a0a;
  display: inline-block;
  transition: .4s;
  outline: 0;
  -webkit-transition: .4s;
  -ms-transition: .4s;
  position: relative;
  padding: 5px 20px;
  border-radius: 30px;
  border: 0;
  border-radius: 3px;
}
.gridFilter button.active {
  background: #fff;
  color: #ff5421;
  box-shadow: 0 0 30px #eee;
}
.gridFilter button + button {
  margin-left: 20px;
}
blockquote {
  margin: 35px 0px;
  padding: 40px 60px;
  color: #666;
  position: relative;
  background: rgba(0, 0, 0, 0.01);
  font-weight: normal;
  font-style: italic;
  text-align: left;
  clear: both;
  font-weight: 400;
  border-radius: 8px;
}
blockquote:before {
  content: "\f129";
  font-size: 35px;
  color: #0b70e1;
  padding-bottom: 0;
  display: inline-block;
  font-family: flaticon;
  font-weight: 400;
  text-align: center;
  top: 0;
  background: 0 0;
  transform: rotate(180deg);
}
blockquote cite {
  font-size: 15px;
  display: block;
  margin-top: 10px;
}
blockquote cite:before {
  content: "";
  font-size: 28px;
  color: #ff5421;
  padding-bottom: 0px;
  display: inline-block;
  background: #0b70e1;
  height: 2px;
  width: 40px;
  font-weight: 400;
  text-align: center;
  top: -4px;
  margin-right: 10px;
  position: relative;
}
.dots {
  list-style: disc;
  margin: 0 0 1.5em 3em;
}
.arrow-btn {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -15%);
}
.arrow-btn a {
  font-weight: 400;
  font-size: 17px;
  border-color: #f2541b;
  border-style: solid;
  border-width: 1px;
  margin: 0px;
  border-radius: 23px;
  padding: 12px 15px;
  color: #f2541b;
}
.arrow-btn a span {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  border-radius: 50%;
  background: #f2541b;
}
.rs-carousel.nav-style1 {
  position: relative;
}
.rs-carousel.nav-style1 .owl-nav {
  display: block;
}
.rs-carousel.nav-style1 .owl-nav .owl-next,
.rs-carousel.nav-style1 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  background: #106eea;
  text-align: center;
  color: #ffffff;
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-carousel.nav-style1 .owl-nav .owl-next i:before,
.rs-carousel.nav-style1 .owl-nav .owl-prev i:before {
  content: "\f138";
  font-family: Flaticon;
}
.rs-carousel.nav-style1 .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-carousel.nav-style1 .owl-nav .owl-next i:before {
  content: "\f137";
}
.rs-carousel.nav-style1:hover .owl-nav .owl-next,
.rs-carousel.nav-style1:hover .owl-nav .owl-prev {
  left: -50px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-carousel.nav-style1:hover .owl-nav .owl-next {
  right: -50px;
  left: unset;
}
.rs-carousel.nav-style2 {
  position: relative;
}
.rs-carousel.nav-style2 .owl-nav {
  display: block;
  position: absolute;
  top: -80px;
  right: 0;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev,
.rs-carousel.nav-style2 .owl-nav .owl-next {
  display: inline-block;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev i,
.rs-carousel.nav-style2 .owl-nav .owl-next i {
  transition: all 0.3s ease;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev i:before,
.rs-carousel.nav-style2 .owl-nav .owl-next i:before {
  font-family: Flaticon;
  font-size: 22px;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev:hover i,
.rs-carousel.nav-style2 .owl-nav .owl-next:hover i {
  color: #106eea;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev i:before {
  content: "\f134";
}
.rs-carousel.nav-style2 .owl-nav .owl-prev:after {
  content: "/";
  padding: 0 5px 0 5px;
  position: relative;
  top: -3px;
}
.rs-carousel.nav-style2 .owl-nav .owl-next i:before {
  content: "\f133";
}
.rs-carousel .owl-dots {
  text-align: center;
  margin: 40px auto 0;
  line-height: 15px;
  display: block;
}
.rs-carousel .owl-dots .owl-dot {
  width: 30px;
  height: 10px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 30px;
  border: 1px solid #106eea;
  background: transparent;
  opacity: 0.7;
  cursor: pointer;
}
.rs-carousel .owl-dots .owl-dot:hover {
  background: #106eea;
}
.rs-carousel .owl-dots .active {
  background: #106eea;
  opacity: 1;
}
/* ------------------------------------
    02. Global Class CSS
---------------------------------------*/
.y-middle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.y-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.project-item .vertical-middle {
  position: relative;
  z-index: 9;
}
.vertical-middle {
  display: table;
  height: 100%;
  width: 100%;
}
.vertical-middle-cell {
  display: table-cell;
  vertical-align: middle;
}
.readon {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all 0.4s;
  z-index: 1;
  background-color: transparent;
}
.readon.learn-more {
  padding: 14px 40px 14px 40px;
  background-image: linear-gradient(250deg, #4e95ed 19%, #03228f 100%);
  border-radius: 30px 30px 30px 30px;
}
.readon.learn-more:hover {
  background-image: linear-gradient(180deg, #03228f 19%, #4e95ed 100%);
  color: #ffffff;
}
.readon.learn-more.sub-con {
  background-image: linear-gradient(250deg, #4e95ed 0%, #03228f 100%);
  padding: 18px 36px 16px 36px;
  font-size: 14px;
  font-weight: 500;
}
.readon.learn-more.sub-con:hover {
  background-image: linear-gradient(250deg, #03228f 0%, #4e95ed 100%);
  color: #ffffff;
}
.readon.learn-more.submit {
  background-image: linear-gradient(90deg, #03228f 0%, #4e95ed 100%);
  padding: 16px 40px 16px 40px;
  font-weight: 500;
  font-size: 16px;
}
.readon.learn-more.submit:hover {
  background-image: linear-gradient(200deg, #03228f 0%, #4e95ed 100%);
  color: #ffffff;
}
.readon.learn-more.post {
  background-image: linear-gradient(50deg, #03228f 10%, #0e73e4 100%);
  padding: 12px 30px;
  font-size: 14px;
  font-weight: 500;
}
.readon.learn-more.post:hover {
  background-image: linear-gradient(50deg, #0e73e4 10%, #03228f 100%);
  color: #fff;
}
.readon.learn-more.pdf i {
  margin-left: 10px;
}
.readon.learn-more.contact-us {
  border-radius: 8px 8px 8px 8px !important;
}
.readon.learn-more.learn-btn {
  background-image: linear-gradient(90deg, #03228f 0%, #1d62f0 100%);
  border-radius: 3px 3px 3px 3px !important;
}
.readon.learn-more.learn-btn:hover {
  background-image: linear-gradient(180deg, #03228f 19%, #4e95ed 100%);
}
.readon.learn-more.slider-btn {
  background: #ffffff;
  color: #106eea;
  padding: 15px 37px;
  border-radius: 8px 8px 8px 8px !important;
}
.readon.learn-more.slider-btn:hover {
  background-image: linear-gradient(250deg, #4e95ed 19%, #03228f 100%);
  color: #ffffff;
}
.readon.started {
  padding: 14px 40px 14px 40px;
  background-image: linear-gradient(220deg, #f27c1e 0%, #dd4c23 79%);
  border-radius: 30px 30px 30px 30px;
}
.readon.started:hover {
  background-image: linear-gradient(180deg, #dd4c23 19%, #f27c1e 100%);
  color: #ffffff;
}
.readon.started.get-new:hover {
  background: rgba(0, 0, 0, 0) linear-gradient(79deg, #dd4c23 0%, #f27c1e 63%, #f27c1e);
}
.readon.started.get-ready {
  background-image: linear-gradient(90deg, #03228f 0, #0e73e4 100%);
  border-radius: 3px;
  text-transform: uppercase;
}
.readon.started.get-ready:hover {
  opacity: 0.9;
}
.readon.started.get-ready2 {
  border-radius: 5px 5px 5px 5px;
}
.readon.started.get-ready3 {
  border-radius: 5px 5px 5px 5px;
  text-transform: uppercase;
  font-weight: 500;
}
.readon.started.get-ready4 {
  background-image: linear-gradient(80deg, #6e71db 0%, #9c9ff9 100%);
  padding: 18px 40px 18px 40px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 3px 3px 3px 3px;
}
.readon.started.get-ready4:hover {
  background-image: linear-gradient(120deg, #9c9ff9 0%, #6e71db 100%);
}
.readon.started.get-ready5 {
  background-image: linear-gradient(250deg, #f18e27 19%, #f14d1a 100%);
  text-transform: uppercase;
  border-radius: 5px 5px 5px 5px;
}
.readon.started.get-ready5:hover {
  background-image: linear-gradient(270deg, #f14d1a 20%, #f18e27 100%);
}
.readon.started.more {
  background-image: linear-gradient(250deg, #b22af3 19%, #1742fd 100%);
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 5px 5px 5px 5px;
}
.readon.started.more:hover {
  background-color: transparent;
  background-image: linear-gradient(270deg, #1742fd 20%, #b22af3 100%);
}
.readon.lets-talk {
  padding: 14px 40px 14px 40px;
  background-image: linear-gradient(250deg, #4e95ed 19%, #03228f 100%);
  border-radius: 30px 30px 30px 30px;
}
.readon.lets-talk:hover {
  background-image: linear-gradient(180deg, #03228f 19%, #4e95ed 100%);
  color: #ffffff;
}
.readon.buy-now {
  padding: 10px 40px 10px 40px;
  background-image: linear-gradient(170deg, #03228f 0%, #4e95ed 100%);
  border-radius: 30px 30px 30px 30px;
  text-transform: uppercase;
  position: relative;
  top: 25px;
}
.readon.buy-now:hover {
  background-image: linear-gradient(360deg, #03228f 0%, #4e95ed 100%);
  color: #ffffff;
}
.readon.buy-now.get-in {
  padding: 15px 34px;
  border: 1px solid #fff;
  color: #ffffff;
  background: transparent;
}
.readon.buy-now.get-in:hover {
  background: #03228F;
  color: #ffffff;
  border: 1px solid #03228F;
}
.readon.buy-now.table-btn {
  background-image: linear-gradient(90deg, #032390 0%, #032390 100%);
  color: #ffffff;
  border-radius: 3px 3px 3px 3px;
  font-weight: 500;
}
.readon.buy-now.table-btn:hover {
  background-image: linear-gradient(90deg, #0d6ada 0%, #0d6ada 100%);
}
.readon.buy-now.sl-btn {
  background: rgba(0, 0, 0, 0) linear-gradient(90deg, #03228f 0%, #1d62f0 100%);
  color: #ffffff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  padding: 17px 28px;
}
.readon.buy-now.sl-btn:hover {
  background-image: linear-gradient(90deg, #0d6ada 0%, #0d6ada 100%);
}
.readon.buy-now.price {
  background-image: linear-gradient(170deg, #03228f 0%, #4e95ed 100%);
  border-radius: 8px 8px 8px 8px;
}
.readon.buy-now.price:hover {
  background-image: linear-gradient(200deg, #03228f 0%, #4e95ed 100%);
}
.readon.buy-now.pricing:hover {
  background-image: linear-gradient(170deg, #03228f 0%, #4e95ed 100%);
  color: #ffffff;
}
.readon.view-more {
  padding: 12px 25px 12px 25px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #111111;
  background-color: #FFFFFF;
  border-radius: 30px 30px 30px 30px;
}
.readon.discover {
  font-size: 16px;
  font-weight: 500;
  color: #032491;
  padding: 17px 30px 17px 30px;
  background: transparent;
  background-image: linear-gradient(90deg, #ffffff 19%, #ffffff 100%);
  border-radius: 4px 4px 4px 4px;
}
.readon.discover.started {
  padding: 16px 30px 16px 30px;
}
.readon.discover.started:hover {
  background-image: linear-gradient(90deg, #f6f7f9 19%, #f6f7f9 100%);
  color: #032491;
}
.readon.discover:hover {
  background-image: linear-gradient(90deg, #f6f7f9 19%, #f6f7f9 100%);
}
.readon.discover.more {
  background-image: linear-gradient(90deg, #032491 19%, #032491 100%);
  padding: 16px 30px 16px 30px;
  color: #ffffff;
}
.readon.discover.more:hover {
  background-image: linear-gradient(90deg, #0d6ada 19%, #0d6ada 100%);
  color: #ffffff;
}
.readon.qoute-btn {
  font-size: 16px;
  padding: 15px 32px;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  color: #101010;
  border: 1px solid #f2541b;
  background: transparent;
}
.readon.qoute-btn:hover {
  color: #ffffff;
  background-image: linear-gradient(150deg, #dd4c23 19%, #f27c1e 100%);
}
.readon.reply {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  padding: 3px 20px 6px;
  line-height: 22px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff !important;
  background-image: linear-gradient(50deg, #03228f 10%, #0e73e4 100%);
  z-index: 1;
  border: none;
}
.readon.reply:hover {
  background-image: linear-gradient(50deg, #0e73e4 10%, #03228f 100%);
  color: #ffffff;
  opacity: 0.99;
}
.readon.more-about {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 16px 47px 16px 30px;
  color: #ffffff;
  background-color: transparent;
  background-image: linear-gradient(110deg, #6e71db 0%, #9c9ff9 100%);
  border-radius: 3px 3px 3px 3px;
}
.readon.more-about:before {
  content: "\f113";
  position: absolute;
  font-family: flaticon;
  font-size: 10px;
  top: 17px;
  right: 24px;
  transition: all 0.3s;
  color: #fff;
}
.readon.more-about:hover {
  background-color: transparent;
  background-image: linear-gradient(240deg, #6e71db 0%, #9c9ff9 100%);
}
/*-- Services Button Css --*/
.services-button a {
  position: relative;
  display: inline-block;
  transition: all ease .3s;
  padding: 18px 0px 0px 0px;
  font-size: 16px;
  font-weight: 500;
  color: #101010;
}
.services-button a:after {
  content: "\f113";
  font-family: Flaticon;
  font-size: 10px;
  position: relative;
  right: 0;
  top: 0;
  display: inline-block;
  margin-left: 15px;
  transition: all ease .4s;
  color: #101010;
}
/*-- Services-btn--*/
.services-btn2 a {
  position: relative;
  display: inline-block;
  transition: all ease .3s;
  font-weight: 500;
  color: #032390;
}
.services-btn2 a:after {
  position: absolute;
  width: 30px;
  height: 1px;
  background: #0b70e1;
  content: '';
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  transition: 0.4s;
  opacity: 0;
}
.services-btn2 a:hover:after {
  right: -40px;
  opacity: 1;
}
/*-- Services-btn--*/
.services-btn3 a {
  position: relative;
  color: #101010;
  display: inline-block;
  transition: all 0.9s ease;
  text-decoration: underline !important;
}
.services-btn3 a:after {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 15px;
  font-weight: 500;
  color: #f2541b;
  font-family: "Flaticon";
  transform: translateY(-50%);
  transition: all 0.2s ease;
}
.services-btn3 a:hover {
  color: #f2541b;
}
.services-btn3 a:hover:after {
  right: -28px;
  content: "\f110";
  transition: all 0.9s ease;
}
/*-- Blog Button Css --*/
.blog-button a {
  position: relative;
  display: inline-block;
  color: #061340;
  font-size: 16px;
  font-weight: 600;
  transition: all ease .4s;
}
.blog-button a:after {
  content: "\f113";
  font-family: Flaticon;
  font-size: 8px;
  position: relative;
  right: 0;
  top: 0;
  display: inline-block;
  margin-left: 8px;
  transition: all ease .4s;
  color: #101010;
}
.blog-button a:hover {
  color: #106eea;
}
.blog-button a:hover:after {
  transform: translateX(10px);
  color: #106eea;
}
.blog-button.style2 a:hover {
  color: #FF5600;
}
.blog-button.style2 a:hover:after {
  color: #FF5600;
}
.blog-button.style3 a {
  color: #061340;
}
.blog-button.style3 a:hover {
  color: #787cf2;
}
.blog-button.style3 a:hover:after {
  color: #787cf2;
}
.blog-button.inner-blog a {
  text-align: center;
  display: inline-block;
  color: #0b70e1;
  padding-right: 25px;
  position: relative;
  z-index: 1;
  font-family: 'Livvic', sans-serif;
  font-weight: 500;
  font-size: 15px;
  transition: all 0.3s;
}
.blog-button.inner-blog a:after {
  content: "\f114";
  position: absolute;
  font-family: flaticon;
  font-size: 15px;
  top: 1px;
  right: 0;
  opacity: 1;
  transition: all 0.3s;
  color: #0b70e1;
}
.blog-button.inner-blog a:hover {
  color: #0B70E1;
}
.blog-button.inner-blog a:hover:after {
  right: -5px;
  opacity: 1;
  transform: unset;
}
/*-- Submit Button Css --*/
.submit-btn {
  box-shadow: 0 28px 50px 0 rgba(0, 0, 0, 0.05);
  outline: 0;
  border: none;
  padding: 18px 18px 18px 18px;
  border-radius: 3px;
  display: inline-block;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: #fff;
  background-image: linear-gradient(160deg, #03228f 0%, #0b70e1 77%);
  transition: all .3s ease;
  position: relative;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
}
.submit-btn:hover {
  opacity: 0.9;
}
.submit-btn.orange-btn {
  background-image: linear-gradient(160deg, #f27c1e 0%, #dd4c23 79%);
}
.submit {
  color: #FFFFFF;
  background-color: transparent;
  background-image: linear-gradient(95deg, #03228f 0%, #4e95ed 100%);
  padding: 18px 40px 17px 40px;
  font-size: 15px;
  line-height: 1;
  border-radius: 30px 30px 30px 30px;
  border: none;
}
.submit:hover {
  opacity: 0.99;
}
.submit.sub-small {
  padding: 17px 40px 17px 40px;
}
.submit.sub-small:hover {
  color: #ffffff;
  background-image: linear-gradient(290deg, #03228f 0%, #4e95ed 100%);
  opacity: unset;
}
/*-- Add Cart Button Css --*/
.add-btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  padding: 10px 40px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  background-image: linear-gradient(90deg, #03228f 0%, #0e73e4 100%);
  color: #ffffff;
  z-index: 1;
  border: none;
}
.add-btn:hover {
  color: #fff;
  background-image: linear-gradient(90deg, #0e73e4 19%, #03228f 100%);
  z-index: 1;
}
/*Video Icon*/
.media-icon {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.media-icon .popup-videos {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  background: #106eea;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  text-align: center;
}
.media-icon .popup-videos:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  background-color: #1042C0;
  transition: 0.3s ease all;
}
.media-icon .popup-videos i:before {
  font-size: 30px;
  line-height: 70px;
  transition: 0.3s ease all;
}
.media-icon .popup-videos:hover:before {
  background: #106eea;
}
.media-icon.video-item {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slider-video .popup-videos {
  display: inline-block;
  position: relative;
  height: 70px;
  width: 70px;
  line-height: 70px;
  border-radius: 100%;
  text-align: center;
  background: #106eea;
}
.slider-video .popup-videos i {
  text-align: center;
  color: #ffffff;
  border-radius: 100%;
  font-size: 25px;
  position: relative;
  line-height: 70px;
  z-index: 9;
  width: 100%;
  vertical-align: middle;
  margin-left: 0;
  height: auto;
}
.slider-video .popup-videos:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  animation: pulse-border 3000ms ease-out infinite;
  background: #106eea;
}
.address-item .address-icon {
  display: inline-block;
  position: relative;
  height: 70px;
  width: 70px;
  line-height: 70px;
  border-radius: 100%;
  text-align: center;
  background: #ffffff;
}
.address-item .address-icon i {
  text-align: center;
  color: #106eea;
  border-radius: 100%;
  font-size: 25px;
  position: relative;
  line-height: 70px;
  z-index: 9;
  width: 100%;
  vertical-align: middle;
  margin-left: 0;
  height: auto;
}
.address-item .address-icon:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  background: #ffffff;
}
.tab-videos .popup-videos {
  color: #de5022;
  display: flex;
  overflow: hidden;
}
.tab-videos .popup-videos i {
  width: 35px;
  height: 35px;
  line-height: 32px;
  border: 2px solid #de5022;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  float: left;
  margin-right: 18px;
}
.tab-videos .popup-videos:hover {
  color: #de5022;
}
.tab-videos .popup-videos .title {
  font-size: 22px;
  line-height: 34px;
  font-weight: 600;
  color: #102b3e;
}
.tab-videos .popup-videos .title:hover {
  color: #f2541b;
}
.rs-videos .animate-border .popup-border {
  background-image: linear-gradient(180deg, #03228f 0%, #0b70e1 100%);
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.rs-videos .animate-border .popup-border i {
  font-size: 20px;
  line-height: 80px;
  display: block;
  color: #ffffff;
}
.rs-videos .animate-border .popup-border:before {
  content: "";
  border: 2px solid #106eea;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: .75s;
  animation-delay: .75s;
}
.rs-videos .animate-border .popup-border:after {
  content: "";
  border: 2px solid #106eea;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.rs-videos .animate-border.main-home .popup-border {
  left: 8%;
}
.rs-videos .animate-border.main-home.style2 .popup-border {
  left: 50%;
}
.rs-videos .animate-border.white-color .popup-border {
  background: #ffffff;
  bottom: -14px;
  left: 44%;
}
.rs-videos .animate-border.white-color .popup-border i {
  color: #03228f;
}
.rs-videos .animate-border.white-color .popup-border:before {
  border: 2px solid #ffffff;
}
.rs-videos .animate-border.white-color .popup-border:after {
  border: 2px solid #ffffff;
}
.rs-videos .animate-border.white-color.style3 .popup-border {
  bottom: 362px;
  left: 10%;
}
@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}
@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}
.margin-remove {
  margin: 0 !important;
}
.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin: 0;
}
.container-fluid {
  padding-left: 60px;
  padding-right: 60px;
}
.display-table {
  display: table;
  height: 100%;
  width: 100%;
}
.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.relative {
  position: relative;
}
.sec-title {
  position: relative;
}
.sec-title .sub-text {
  position: relative;
  padding-left: 95px;
  display: inline-block;
  line-height: 28px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 400;
  color: #03228F;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.sec-title .sub-text:after {
  content: "";
  position: absolute;
  border: 0;
  height: 4px;
  width: 50px;
  background-color: #03228F;
  z-index: 1;
  margin-left: 0;
  left: 24px;
  top: 12px;
}
.sec-title .sub-text:before {
  content: '';
  position: absolute;
  left: 0;
  top: 12px;
  height: 4px;
  width: 12px;
  background-color: #03228F;
}
.sec-title .sub-text.new {
  color: #ffffff;
  font-size: 15px;
  padding-left: 0;
}
.sec-title .sub-text.new:before {
  display: none;
}
.sec-title .sub-text.new:after {
  display: none;
}
.sec-title .sub-text.new-text {
  padding-left: 0;
  margin-bottom: 7px;
  color: #ffffff;
}
.sec-title .sub-text.new-text:before {
  display: none;
}
.sec-title .sub-text.new-text:after {
  display: none;
}
.sec-title .sub-text.style2:after {
  background-color: #106eea;
}
.sec-title .sub-text.style2:before {
  background-color: #106eea;
}
.sec-title .sub-text.style-bg {
  background-color: #1273EB17;
  display: inline-block;
  padding: 6px 25px;
  color: #1273eb;
  border-radius: 30px;
  text-transform: uppercase;
}
.sec-title .sub-text.style-bg:before {
  display: none;
}
.sec-title .sub-text.style-bg:after {
  display: none;
}
.sec-title .sub-text.style4-bg {
  color: #03228F;
}
.sec-title .sub-text.style4-bg:before {
  background-color: #FD6509;
}
.sec-title .sub-text.style4-bg:after {
  background-color: #FD6509;
}
.sec-title .sub-text.choose {
  font-size: 16px;
  font-weight: 500;
}
.sec-title .title {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  color: #101010;
  margin: 0;
}
.sec-title .title.white-color {
  color: #ffffff;
}
.sec-title .desc {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}
.sec-title .desc.white-color {
  color: #fff;
}
.sec-title .desc-big {
  font-size: 28px;
  line-height: 48px;
  font-weight: 500;
  margin: 0;
}
.sec-title2 {
  position: relative;
}
.sec-title2 .sub-text {
  margin: 0 0 10px;
  display: block;
  line-height: 28px;
  font-family: 'Livvic', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #03228F;
  text-transform: uppercase;
}
.sec-title2 .sub-text.white-color {
  color: #ffffff;
}
.sec-title2 .sub-text.gold-color {
  font-weight: 600;
  color: #F27C1E;
}
.sec-title2 .sub-text.contact {
  color: #1273EB;
}
.sec-title2 .sub-text.orange-color {
  color: #f2541b !important;
}
.sec-title2 .sub-text.style-bg {
  background-color: #1273EB17;
  display: inline-block;
  padding: 6px 25px;
  color: #1273eb;
  border-radius: 30px;
  text-transform: uppercase;
}
.sec-title2 .sub-text.style-bg.white-color {
  color: #ffffff;
}
.sec-title2 .sub-text.style-bg.con-bg {
  background-color: #03228F;
}
.sec-title2 .title {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.4;
  color: #0a0a0a;
  max-width: 490px;
  margin: 0 auto;
}
.sec-title2 .title.white-color {
  color: #ffffff;
}
.sec-title2 .title.testi-title {
  max-width: unset;
}
.sec-title2 .title.testi-title.new {
  color: #102B3E;
}
.sec-title2 .title.title2 {
  max-width: 550px;
}
.sec-title2 .title.title3 {
  color: #102B3E;
  max-width: 430px;
  margin: 0;
}
.sec-title2 .title.title4 {
  max-width: 660px;
}
.sec-title2 .title.title5 {
  color: #102B3E;
}
.sec-title2 .title.title6 {
  font-weight: 600;
  max-width: unset;
}
.sec-title2 .title.title7 {
  color: #102B3E;
  max-width: unset;
}
.sec-title2 .title.orange-color {
  color: #f2541b !important;
}
.sec-title2 .heading-line {
  position: relative;
  background-color: #03228F;
  height: 5px;
  width: 90px;
  border-radius: 30px;
  margin: 20px auto;
}
.sec-title2 .heading-line:before {
  background-color: #1940AA;
  content: '';
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  -webkit-animation-direction: alternate-reverse;
  -webkit-animation-name: watermark-animate;
  animation-name: watermark-animate;
}
.sec-title2 .desc {
  font-size: 18px;
  line-height: 31px;
  font-weight: 400;
  color: #454545;
  max-width: 429px;
  margin: 13px auto 0;
}
.sec-title2 .desc.desc-text {
  max-width: 540px;
}
.sec-title2 .desc.white-color {
  color: #fff;
}
.sec-title2 .desc.desc2 {
  max-width: 700px;
  margin: 0;
}
.sec-title2 .desc.desc3 {
  max-width: 930px;
}
.sec-title2 .desc-big {
  font-size: 18px;
  line-height: 31px;
  color: #ffffff;
}
.sec-title3 .sub-text {
  font-size: 19px;
  line-height: 1.4;
  font-weight: 700;
  color: #ffffff;
  display: block;
  margin-bottom: 20px;
}
.sec-title3 .sub-text.style2 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.sec-title3 .title {
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  line-height: 40px;
  margin-bottom: 30px;
}
.sec-title3 .title.title2 {
  margin: 0;
  line-height: 52px;
}
.sec-title3 .desc {
  font-size: 19px;
  font-weight: 500;
  color: #fff;
  margin: 0;
}
.sec-title4 .title-img img {
  margin: 0px 0px 0px 15px;
}
.sec-title4 .sub-title {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #787BF2;
  display: block;
  margin: -26px 0px 10px 0px;
}
.sec-title4 .sub-text {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;
  color: #03228F;
  margin: 0 0 10px;
  display: block;
  text-transform: uppercase;
}
.sec-title4 .title {
  font-size: 36px;
  line-height: 1.3;
  font-weight: 700;
  color: #101010;
  margin: 0 0 17px;
}
.sec-title4 .heading-line {
  height: 5px;
  width: 90px;
  background: #B9C7F6;
  margin: 0px;
  position: relative;
  border-radius: 30px;
}
.sec-title4 .heading-line:before {
  content: '';
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #0D66D5;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  -webkit-animation-direction: alternate-reverse;
  -webkit-animation-name: watermark-animate;
  animation-name: watermark-animate;
}
.sec-title4 .desc {
  margin: 0;
}
.sec-title4 .desc.desc-big {
  font-size: 20px;
  line-height: 30px;
  margin: 26px 0px 30px 0px;
}
.sec-title5 .logo-icon {
  margin-bottom: 15px;
}
.sec-title5 .logo-icon img {
  width: 71px;
}
.sec-title5 .title {
  font-size: 36px;
  line-height: 1.4;
  font-weight: 700;
  color: #102B3E;
  margin-bottom: 10px;
}
.sec-title5 .title span {
  color: #F9780F;
}
.sec-title5 .title.title2 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #0a0a0a;
  margin-bottom: 0;
}
.sec-title5 .desc {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #454545;
  max-width: 626px;
  margin: 0 auto;
}
.sec-title5 .desc2 {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #454545;
  max-width: 507px;
  margin: 0;
}
.pt-relative {
  position: relative !important;
}
.sec-title6 .sub-text {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 32px;
  color: #F24C1A;
  display: block;
  margin-bottom: 5px;
}
.sec-title6 .sub-text.new-text {
  margin-bottom: 10px;
}
.sec-title6 .sub-text.new-text-style {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
}
.sec-title6 .title {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.4;
  color: #102B3E;
  margin-bottom: 5px;
}
.sec-title6 .title.new-title {
  max-width: 700px;
  margin: 0 auto;
}
.sec-title6 .title span {
  color: #ff7045;
}
.sec-title6 .desc {
  padding-right: 125px;
}
.sec-right {
  position: absolute;
  right: -17%;
  top: 45%;
  transform: rotate(90deg);
  z-index: 1;
}
.sec-right .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #101010;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.sec-left {
  transform: rotate(-90deg) translatex(-50%);
  transform-origin: left;
  position: absolute;
  left: -2%;
  top: 46%;
  z-index: 1;
}
.sec-left .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #101010;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.check-square li {
  position: relative;
  padding-left: 17px;
  line-height: 35px;
  border: none;
  font-size: 15px;
  font-weight: 500;
  color: #454545;
}
.check-square li:before {
  position: absolute;
  left: 0;
  z-index: 0;
  content: "\f058";
  font-weight: 400;
  font-family: fontawesome;
  color: #787cf2;
}
/* -----------------------
    03. Header Section CSS
--------------------------*/
.logo-part img {
  transition: 0.4s;
  max-height: 100px;
  margin-bottom:40px
}
.full-width-header {
  position: relative;
}
.full-width-header .rs-header {
  position: relative;
}
.full-width-header .rs-header .topbar-area {
  padding-top: 20px;
  padding-bottom: 10px;
}
.full-width-header .rs-header .topbar-area .rs-contact-info .contact-part {
  display: inline-block;
  padding-right: 25px;
  padding-left: 25px;
  border-right: 1px solid #eee;
  font-size: 14px;
}
.full-width-header .rs-header .topbar-area .rs-contact-info .contact-part i {
  float: left;
  margin-right: 15px;
  line-height: 38px;
}
.full-width-header .rs-header .topbar-area .rs-contact-info .contact-part i:before {
  font-size: 26px;
  color: #106eea;
}
.full-width-header .rs-header .topbar-area .rs-contact-info .contact-part .contact-info {
  float: left;
  text-align: left;
  display: block;
}
.full-width-header .rs-header .topbar-area .rs-contact-info .contact-part .contact-info span {
  color: #0a0a0a;
  display: block;
  text-align: left;
  font-size: 15px;
  padding: 0;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}
.full-width-header .rs-header .topbar-area .rs-contact-info .contact-part .contact-info a {
  color: #454545;
  font-size: 14px;
}
.full-width-header .rs-header .topbar-area .rs-contact-info .contact-part .contact-info a:hover {
  color: #106eea;
}
.full-width-header .rs-header .topbar-area.style2 {
  background-image: linear-gradient(90deg, #03228f 10%, #0e73e4 100%);
  padding-top: 0px;
  padding-bottom: 0px;
}
.full-width-header .rs-header .topbar-area.style2 .topbar-contact li {
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  line-height: 50px;
}
.full-width-header .rs-header .topbar-area.style2 .topbar-contact li i {
  margin-right: 5px;
}
.full-width-header .rs-header .topbar-area.style2 .topbar-contact li i:before {
  font-size: 14px;
  color: #ffffff;
}
.full-width-header .rs-header .topbar-area.style2 .topbar-contact li a {
  color: #ffffff;
  font-size: 14px;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #2a8af5;
}
.full-width-header .rs-header .topbar-area.style2 .topbar-contact li a:hover {
  color: #ccc;
}
.full-width-header .rs-header .topbar-area.style2 .topbar-contact li:last-child {
  margin: 0;
  border: none;
}
.full-width-header .rs-header .topbar-area.style2 .toolbar-sl-share ul .opening {
  color: #ffffff;
}
.full-width-header .rs-header .topbar-area.style2 .toolbar-sl-share ul .opening em {
  border-right: 1px solid #2a8af5;
  margin-right: 5px;
  padding-right: 20px;
  font-style: normal;
}
.full-width-header .rs-header .topbar-area.style2 .toolbar-sl-share ul .opening em i {
  margin-right: 8px;
}
.full-width-header .rs-header .topbar-area.style2 .toolbar-sl-share ul .opening em i:before {
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
}
.full-width-header .rs-header .topbar-area.style2 .toolbar-sl-share ul li {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-right: 12px;
}
.full-width-header .rs-header .topbar-area.style2 .toolbar-sl-share ul li a {
  font-size: 14px;
  color: #ffffff;
}
.full-width-header .rs-header .topbar-area.style2 .toolbar-sl-share ul li a:hover {
  color: #ccc;
}
.full-width-header .rs-header .topbar-area.style2 .toolbar-sl-share ul li:last-child {
  margin: 0;
}
.full-width-header .rs-header .topbar-area.style2.modify1 {
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.full-width-header .rs-header .topbar-area.style2.modify1 .topbar-contact li {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 20px;
  padding-right: 20px;
}
.full-width-header .rs-header .topbar-area.style2.modify1 .topbar-contact li a {
  border-right: unset;
}
.full-width-header .rs-header .topbar-area.style2.modify1 .topbar-contact li:last-child {
  margin: 0;
  border: none;
}
.full-width-header .rs-header .topbar-area.style2.modify1 .toolbar-sl-share ul .opening {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.full-width-header .rs-header .topbar-area.style2.modify1 .toolbar-sl-share ul .opening em {
  border-right: unset;
}
.full-width-header .rs-header .menu-area {
  transition: all 0.3s ease;
  background-image: linear-gradient(90deg, #03228f 10%, #0e73e4 100%);
}
.full-width-header .rs-header .menu-area .logo-area a img {
  transition: 0.4s;
  max-height: 40px;
}
.full-width-header .rs-header .menu-area .logo-area a .sticky-logo {
  display: none;
}
.full-width-header .rs-header .menu-area .rs-menu-area {
  align-items: center;
  justify-content: flex-end;
  float: left;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li {
  display: inline-block;
  margin-right: 52px;
  padding: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
  transition: all 0.3s ease;
  font-size: 15px;
  font-weight: 600;
  margin-right: 10px;
  height: 65px;
  line-height: 65px;
  padding: 0;
  color: #ffffff;
  position: relative;
  font-family: Livvic;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #bbb;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children > a {
  padding-right: 17px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children > a:before {
  content: "+";
  position: absolute;
  left: auto;
  right: 0;
  top: 50%;
  text-align: center;
  z-index: 9999;
  font-size: 16px;
  display: block;
  cursor: pointer;
  transform: translateY(-50%);
  font-weight: 400;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children:hover > a:before {
  content: "-";
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #bbb;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li:last-child {
  margin-right: 0!important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li:last-child a {
  margin-right: 0!important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li:last-child i {
  margin: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #ffffff;
  margin: 0;
  padding: 20px 0;
  border-radius: 0 0 5px 5px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li {
  margin: 0 !important;
  border: none;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  line-height: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #101010;
  height: unset;
  line-height: 16px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:before {
  right: 30px !important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #106eea;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.current-menu-item > a,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li.active > a {
  color: #106eea !important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li:last-child {
  margin: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu.left {
  left: auto;
  right: 100%;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu {
  position: absolute;
  left: 0;
  min-width: 1210px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container {
  float: left;
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0 !important;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu {
  width: 25%;
  float: left;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu {
  position: relative;
  padding: 30px 35px 35px;
  border-radius: 0;
  box-shadow: none;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu .menu-title {
  color: #101010;
  font-size: 17px;
  font-weight: 600;
  padding: 10px 30px;
  text-align: left;
  text-transform: capitalize;
  transition: all 0.3s ease;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu li {
  width: 100%;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu .sub-menu li a {
  width: 100%;
  padding: 10px 30px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
  color: #106eea;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu.two-column {
  min-width: 650px;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu.two-column .mega-menu-container .single-megamenu {
  width: 50%;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.mega-menu.three-column .mega-menu-container .single-megamenu {
  width: 33.33%;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.current-menu-item > a {
  color: #fff;
}
.full-width-header .rs-header .menu-area.sticky {
  background: #fff;
}
.full-width-header .rs-header .menu-area.sticky .logo-area {
  display: inline-block;
  top: 18px;
}
.full-width-header .rs-header .menu-area.sticky .logo-area a .sticky-logo {
  display: block;
  max-height: 30px;
}
.full-width-header .rs-header .menu-area.sticky .rs-menu-area {
  float: right;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #0a0a0a;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #106eea;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a {
  color: #106eea;
}
.full-width-header .rs-header .menu-area.sticky .main-menu .rs-menu ul.mega-menu {
  left: unset;
  right: 0;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner {
  line-height: unset;
  height: unset;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner.search-icon .sidebarmenu-search > a {
  color: #101010;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner li .cart-icon .cart-count {
  background: #106eea;
  color: #ffffff;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner li > a {
  color: #494949;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner li > a:hover {
  color: #106eea;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner.hidden-sticky {
  display: none;
}
.full-width-header .rs-header .expand-btn-inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 65px;
  height: 65px;
  position: relative;
}
.full-width-header .rs-header .expand-btn-inner .sidebarmenu-search > a {
  color: #ffffff;
  border-right: 1px solid #fff;
  padding-right: 20px;
}
.full-width-header .rs-header .expand-btn-inner .sidebarmenu-search > a i:before {
  font-size: 15px;
  font-weight: 600;
}
.full-width-header .rs-header .expand-btn-inner .sidebarmenu-search > a i.flaticon-shopping-bag-1:before {
  font-weight: normal;
}
.full-width-header .rs-header .expand-btn-inner .sidebarmenu-search > a:hover {
  color: #ffffff;
}
.full-width-header .rs-header .expand-btn-inner .toolbar-sl-share {
  padding-left: 25px;
}
.full-width-header .rs-header .expand-btn-inner .toolbar-sl-share .social li {
  display: inline-block;
  margin-right: 14px;
}
.full-width-header .rs-header .expand-btn-inner .toolbar-sl-share .social li a {
  color: #ffffff;
  font-size: 14px;
}
.full-width-header .rs-header .expand-btn-inner .toolbar-sl-share .social li a:hover {
  opacity: 0.8;
}
.full-width-header .rs-header .expand-btn-inner .toolbar-sl-share .social li:last-child {
  margin-right: 0;
}
.full-width-header .rs-header.style2 .menu-area {
  background: #ffffff;
}
.full-width-header .rs-header.style2 .menu-area .logo-part img {
  transition: 0.4s;
  max-height: 40px;
}
.full-width-header .rs-header.style2 .menu-area .logo-part .dark {
  display: none;
}
.full-width-header .rs-header.style2 .menu-area .logo-part .light {
  display: inherit;
}
.full-width-header .rs-header.style2 .menu-area .rs-menu-area {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: flex-end;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.nav-menu li {
  display: inline-block;
  margin-right: 32px;
  padding: 0;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #101010;
  line-height: 100px;
  height: 100px;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #106eea;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children:hover > a:before {
  content: "-";
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #106eea;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.sub-menu li {
  margin: 0;
  border: none;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.sub-menu li a {
  height: unset;
  line-height: 16px;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #106eea;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.sub-menu li.menu-item-has-children a:before {
  display: none;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #106eea;
}
.full-width-header .rs-header.style2 .menu-area .main-menu .rs-menu ul.mega-menu {
  left: unset;
  right: -45px;
}
.full-width-header .rs-header.style2 .menu-area.sticky .expand-btn-inner ul {
  display: flex;
}
.full-width-header .rs-header.style2 .menu-area.sticky .expand-btn-inner ul li {
  margin-left: 25px;
}
.full-width-header .rs-header.style2 .menu-area.sticky .expand-btn-inner ul li .quote-btn {
  font-size: 14px;
  background-image: linear-gradient(90deg, #03228f 0%, #0e73e4 100%);
  padding: 13px 22px;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
}
.full-width-header .rs-header.style2 .menu-area.sticky .expand-btn-inner ul li .quote-btn:hover {
  color: #ffffff;
  background-image: linear-gradient(90deg, #0e73e4 0%, #03228f 100%);
}
.full-width-header .rs-header.style2 .expand-btn-inner .sidebarmenu-search > a {
  color: #101010;
}
.full-width-header .rs-header.style2 .expand-btn-inner .sidebarmenu-search > a i:before {
  font-size: 15px;
  font-weight: 600;
}
.full-width-header .rs-header.style2 .expand-btn-inner .sidebarmenu-search > a i.flaticon-shopping-bag-1:before {
  font-weight: normal;
}
.full-width-header .rs-header.style2 .expand-btn-inner .sidebarmenu-search > a:hover {
  color: #106eea;
}
.full-width-header .rs-header.style2 .expand-btn-inner li .humburger {
  max-width: 45px;
  display: flex !important;
  flex-wrap: wrap;
  margin: -5px;
}
.full-width-header .rs-header.style2 .expand-btn-inner li .humburger span {
  height: 5px;
  width: 5px;
  display: block;
  background: #0a0a0a;
  border-radius: 50%;
  transition: none;
  list-style: none;
  transition: all 0.3s ease;
  margin: 4px 5px;
}
.full-width-header .rs-header.style2 .expand-btn-inner li .humburger span.dot2,
.full-width-header .rs-header.style2 .expand-btn-inner li .humburger span.dot4,
.full-width-header .rs-header.style2 .expand-btn-inner li .humburger span.dot6,
.full-width-header .rs-header.style2 .expand-btn-inner li .humburger span.dot8 {
  background: #106eea;
}
.full-width-header .rs-header.style2 .expand-btn-inner ul {
  display: flex;
}
.full-width-header .rs-header.style2 .expand-btn-inner ul li {
  margin-left: 25px;
}
.full-width-header .rs-header.style2 .expand-btn-inner ul li .quote-btn {
  font-size: 14px;
  background-image: linear-gradient(90deg, #03228f 0%, #0e73e4 100%);
  padding: 13px 22px;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
}
.full-width-header .rs-header.style2 .expand-btn-inner ul li .quote-btn:hover {
  color: #ffffff;
  background-image: linear-gradient(90deg, #0e73e4 0%, #03228f 100%);
}
.full-width-header .rs-header.style2.btn-style .menu-area .main-menu .rs-menu ul.mega-menu {
  right: -215px;
}
.full-width-header .rs-header.style3 .menu-area {
  background: transparent;
  padding: 13px 0;
}
.full-width-header .rs-header.style3 .menu-area .logo-part a .sticky-logo {
  display: none;
}
.full-width-header .rs-header.style3 .menu-area .logo-part img {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  max-height: 40px;
}
.full-width-header .rs-header.style3 .menu-area .logo-part .dark {
  display: none;
}
.full-width-header .rs-header.style3 .menu-area .logo-part .light {
  display: inherit;
}
.full-width-header .rs-header.style3 .menu-area .rs-menu-area {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: flex-end;
}
.full-width-header .rs-header.style3 .menu-area .main-menu .rs-menu ul.nav-menu li {
  display: inline-block;
  padding: 0;
}
.full-width-header .rs-header.style3 .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children:hover > a:before {
  content: "-";
}
.full-width-header .rs-header.style3 .menu-area .main-menu .rs-menu ul.sub-menu li a {
  line-height: 16px;
  height: unset;
}
.full-width-header .rs-header.style3 .menu-area .main-menu .rs-menu ul.sub-menu li.menu-item-has-children a:before {
  display: none;
}
.full-width-header .rs-header.style3 .menu-area .main-menu .rs-menu ul.mega-menu {
  left: unset;
  right: -15px;
}
.full-width-header .rs-header.style3 .menu-area.sticky {
  background: #ffffff;
}
.full-width-header .rs-header.style3 .menu-area.sticky .logo-part a .sticky-logo {
  display: block;
}
.full-width-header .rs-header.style3 .menu-area.sticky .logo-part a .normal-logo {
  display: none;
}
.full-width-header .rs-header.style3 .menu-area.sticky .main-menu .rs-menu ul.nav-menu li a {
  color: #101010;
}
.full-width-header .rs-header.style3 .menu-area.sticky .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #106eea;
}
.full-width-header .rs-header.style3 .menu-area.sticky .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #106eea;
}
.full-width-header .rs-header.style3 .expand-btn-inner ul {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header.style3 .expand-btn-inner ul .sidebarmenu-search > a {
  border: unset;
  padding-right: unset;
}
.full-width-header .rs-header.style3 .expand-btn-inner ul li .humburger {
  max-width: 45px;
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}
.full-width-header .rs-header.style3 .expand-btn-inner ul li .humburger span {
  height: 5px;
  width: 5px;
  display: block;
  background: #ffffff;
  border-radius: 50%;
  transition: none;
  list-style: none;
  transition: all 0.3s ease;
  margin: 3px 4px;
}
.full-width-header .rs-header.style3 .expand-btn-inner ul li .humburger span.dot2,
.full-width-header .rs-header.style3 .expand-btn-inner ul li .humburger span.dot4,
.full-width-header .rs-header.style3 .expand-btn-inner ul li .humburger span.dot6,
.full-width-header .rs-header.style3 .expand-btn-inner ul li .humburger span.dot8 {
  background: #6394bf;
}
.full-width-header .rs-header.style3.modify1 .menu-area .main-menu .rs-menu ul.nav-menu li {
  display: inline-block;
  padding: 0;
}
.full-width-header .rs-header.style3.modify1 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  color: #101010;
}
.full-width-header .rs-header.style3.modify1 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #106eea;
}
.full-width-header .rs-header.style3.modify1 .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children:hover > a:before {
  content: "-";
}
.full-width-header .rs-header.style3.modify1 .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #106eea;
}
.full-width-header .rs-header.style3.modify1 .menu-area .main-menu .rs-menu ul.sub-menu li a {
  color: #101010;
}
.full-width-header .rs-header.style3.modify1 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #106eea;
}
.full-width-header .rs-header.style3.modify1 .menu-area .main-menu .rs-menu ul.sub-menu li.menu-item-has-children a:before {
  display: none;
}
.full-width-header .rs-header.style3.modify1 .menu-area .main-menu .rs-menu ul.sub-menu li.active a {
  color: #106eea;
}
.full-width-header .rs-header.style3.modify1 .menu-area .main-menu .rs-menu ul.mega-menu {
  right: -75px;
  margin-top: 13px;
}
.full-width-header .rs-header.style3.modify1 .expand-btn-inner ul .sidebarmenu-search a i {
  color: #101010;
}
.full-width-header .rs-header.style3.modify1 .expand-btn-inner ul li {
  margin-right: 30px;
}
.full-width-header .rs-header.style3.modify1 .expand-btn-inner ul li .humburger span {
  background: #101010;
}
.full-width-header .rs-header.style3.modify1 .expand-btn-inner ul li .humburger span.dot2,
.full-width-header .rs-header.style3.modify1 .expand-btn-inner ul li .humburger span.dot4,
.full-width-header .rs-header.style3.modify1 .expand-btn-inner ul li .humburger span.dot6,
.full-width-header .rs-header.style3.modify1 .expand-btn-inner ul li .humburger span.dot8 {
  background: #6394bf;
}
.full-width-header .rs-header.style3.modify1 .expand-btn-inner ul li:last-child {
  margin-right: 0;
}
.full-width-header .rs-header.style3.modify2 .menu-area .main-menu .rs-menu ul.mega-menu {
  right: -75px;
  margin-top: 13px;
}
.full-width-header .rs-header.style3.modify2 .menu-area.sticky .expand-btn-inner ul li .humburger span {
  background: #0e44dd;
}
.full-width-header .rs-header.style3.modify2 .menu-area.sticky .expand-btn-inner ul li .humburger span.dot2,
.full-width-header .rs-header.style3.modify2 .menu-area.sticky .expand-btn-inner ul li .humburger span.dot4,
.full-width-header .rs-header.style3.modify2 .menu-area.sticky .expand-btn-inner ul li .humburger span.dot6,
.full-width-header .rs-header.style3.modify2 .menu-area.sticky .expand-btn-inner ul li .humburger span.dot8 {
  background: #333333;
}
.full-width-header .rs-header.style3.modify2 .expand-btn-inner ul li {
  margin-right: 30px;
}
.full-width-header .rs-header.style3.modify2 .expand-btn-inner ul li .humburger span {
  background: #ffffff;
}
.full-width-header .rs-header.style3.modify2 .expand-btn-inner ul li .humburger span.dot2,
.full-width-header .rs-header.style3.modify2 .expand-btn-inner ul li .humburger span.dot4,
.full-width-header .rs-header.style3.modify2 .expand-btn-inner ul li .humburger span.dot6,
.full-width-header .rs-header.style3.modify2 .expand-btn-inner ul li .humburger span.dot8 {
  background: #6394bf;
}
.full-width-header .rs-header.style3.modify2 .expand-btn-inner ul li:last-child {
  margin-right: 0;
}
.full-width-header .rs-header.style3.modify3 .menu-area {
  padding: 0;
}
.full-width-header .rs-header.style3.modify3 .menu-area .row-table {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul.nav-menu li {
  display: inline-block;
  padding: 0;
  margin-right: 20px;
}
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul.nav-menu li a {
  color: #ffffff;
}
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #101010;
}
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul.nav-menu li.menu-item-has-children:hover > a:before {
  content: "-";
}
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #101010;
}
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul.sub-menu li a {
  line-height: 16px;
  height: unset;
  color: #102b3e;
}
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #787cf2;
}
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul.sub-menu li.menu-item-has-children a:before {
  display: none;
}
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul.sub-menu li.current-menu-item > a,
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul.sub-menu li.active > a {
  color: #787cf2 !important;
}
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul.mega-menu {
  right: -310px;
}
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul li a:hover,
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul li.active a,
.full-width-header .rs-header.style3.modify3 .menu-area .row-table .main-menu .rs-menu ul li.current-menu-item > a {
  color: #787cf2;
}
.full-width-header .rs-header.style3.modify3 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a {
  color: #101010;
}
.full-width-header .rs-header.style3.modify3 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li > a:hover {
  color: #787cf2;
}
.full-width-header .rs-header.style3.modify3 .menu-area.sticky .main-menu .rs-menu ul.nav-menu > li.current-menu-item > a {
  color: #787cf2;
}
.full-width-header .rs-header.style3.modify3 .menu-area.sticky .expand-btn-inner ul .sidebarmenu-search > a:hover {
  color: #787cf2;
}
.full-width-header .rs-header.style3.modify3 .menu-area.sticky .expand-btn-inner ul li .humburger span {
  background: #787cf2;
  margin: 2px 4px;
}
.full-width-header .rs-header.style3.modify3 .menu-area.sticky .expand-btn-inner ul li .humburger span.dot2,
.full-width-header .rs-header.style3.modify3 .menu-area.sticky .expand-btn-inner ul li .humburger span.dot4,
.full-width-header .rs-header.style3.modify3 .menu-area.sticky .expand-btn-inner ul li .humburger span.dot6,
.full-width-header .rs-header.style3.modify3 .menu-area.sticky .expand-btn-inner ul li .humburger span.dot8 {
  background: #101010;
}
.full-width-header .rs-header.style3.modify3 .expand-btn-inner ul .sidebarmenu-search > a {
  color: #ffffff;
}
.full-width-header .rs-header.style3.modify3 .expand-btn-inner ul .sidebarmenu-search > a i:before {
  font-size: 18px;
  font-weight: 600;
}
.full-width-header .rs-header.style3.modify3 .expand-btn-inner ul .sidebarmenu-search > a:hover {
  color: #101010;
}
.full-width-header .rs-header.style3.modify3 .expand-btn-inner ul li {
  margin-right: 30px;
}
.full-width-header .rs-header.style3.modify3 .expand-btn-inner ul li .humburger span {
  background: #ffffff;
  margin: 2px 4px;
}
.full-width-header .rs-header.style3.modify3 .expand-btn-inner ul li .humburger span.dot2,
.full-width-header .rs-header.style3.modify3 .expand-btn-inner ul li .humburger span.dot4,
.full-width-header .rs-header.style3.modify3 .expand-btn-inner ul li .humburger span.dot6,
.full-width-header .rs-header.style3.modify3 .expand-btn-inner ul li .humburger span.dot8 {
  background: #101010;
}
.full-width-header .rs-header.style3.modify3 .expand-btn-inner ul li:last-child {
  margin-right: 0;
}
.full-width-header .rs-header.style3.modify3.home14-style .expand-btn-inner ul .sidebarmenu-search > a:hover,
.full-width-header .rs-header.style3.modify3.home14-style .menu-area .row-table .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #ff7045;
}
.full-width-header .rs-header.style3.modify3.home14-style .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
  color: #ff7045;
}
.full-width-header .rs-header.style3.modify3.home14-style .menu-area .row-table .main-menu .rs-menu ul.sub-menu li.active > a {
  color: #ff7045 !important;
}
.full-width-header .rs-header.style3.modify3.home14-style .menu-area .row-table .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #ff7045;
  line-height: 100px;
  height: 100px;
}
.full-width-header .rs-header.style3.modify3.home14-style .menu-area .row-table .main-menu .rs-menu .nav-menu.onepage-menu li a {
  line-height: 100px;
  height: 100px;
}
.full-width-header .rs-header.style3.modify3.home14-style .menu-area.sticky .expand-btn-inner ul .sidebarmenu-search > a:hover {
  color: #ff7045;
}
.full-width-header .rs-header.style3.modify3.home14-style .menu-area.sticky .expand-btn-inner ul li .humburger span {
  background: #ff7045;
}
.full-width-header .rs-header.style3.modify3.home14-style .menu-area.sticky .expand-btn-inner ul li .humburger span.dot2,
.full-width-header .rs-header.style3.modify3.home14-style .menu-area.sticky .expand-btn-inner ul li .humburger span.dot4,
.full-width-header .rs-header.style3.modify3.home14-style .menu-area.sticky .expand-btn-inner ul li .humburger span.dot6,
.full-width-header .rs-header.style3.modify3.home14-style .menu-area.sticky .expand-btn-inner ul li .humburger span.dot8 {
  background: #787cf2;
}
.full-width-header .rs-header.style3.modify3.home14-style .expand-btn-inner ul li {
  margin-right: 30px;
}
.full-width-header .rs-header.style3.modify3.home14-style .expand-btn-inner ul li .humburger span {
  background: #ffffff;
  margin: 2px 4px;
}
.full-width-header .rs-header.style3.modify3.home14-style .expand-btn-inner ul li .humburger span.dot2,
.full-width-header .rs-header.style3.modify3.home14-style .expand-btn-inner ul li .humburger span.dot4,
.full-width-header .rs-header.style3.modify3.home14-style .expand-btn-inner ul li .humburger span.dot6,
.full-width-header .rs-header.style3.modify3.home14-style .expand-btn-inner ul li .humburger span.dot8 {
  background: #ff7045;
}
.full-width-header .rs-header.style3.modify3.home14-style .expand-btn-inner ul li:last-child {
  margin-right: 0;
}
.full-width-header .rs-header.style4 .menu-area {
  transition: all 0.3s ease;
  background-image: linear-gradient(90deg, #03228f 10%, #0e73e4 100%);
}
.full-width-header .rs-header.style4 .menu-area .main-menu .rs-menu ul.nav-menu li {
  margin-right: 32px;
}
.full-width-header .rs-header.style4 .menu-area .main-menu .rs-menu ul.sub-menu li a {
  line-height: 16px;
  height: unset;
}
.full-width-header .rs-header.style4 .menu-area .main-menu .rs-menu ul.sub-menu li:last-child {
  margin: 0;
}
.full-width-header .rs-header.style4 .menu-area .main-menu .rs-menu ul.mega-menu {
  min-width: 1240px;
  left: unset;
  right: -340px;
}
.full-width-header .rs-header.style4 .menu-area.sticky .main-menu .rs-menu ul.mega-menu {
  min-width: 1170px;
  right: -300px;
}
.full-width-header .rs-header.style4 .expand-btn-inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 50px;
  height: 50px;
  position: relative;
}
.full-width-header .rs-header.style4 .expand-btn-inner .sidebarmenu-search > a {
  border-right: 1px solid #fff;
  padding-right: 20px;
}
.full-width-header .rs-header.style4.modify1 .menu-area {
  background: unset;
}
.full-width-header .rs-header.style4.modify1 .menu-area .box-layout {
  background: #ffffff;
  margin-top: 50px;
  padding: 0 40px;
  z-index: 1;
  border-radius: 5px;
}
.full-width-header .rs-header.style4.modify1 .menu-area .rs-menu-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.full-width-header .rs-header.style4.modify1 .menu-area .main-menu .rs-menu ul.nav-menu li a {
  font-size: 16px;
  color: #454545;
  height: 110px;
  line-height: 110px;
}
.full-width-header .rs-header.style4.modify1 .menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
  color: #f2541b;
}
.full-width-header .rs-header.style4.modify1 .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children > a {
  color: #0a0a0a;
}
.full-width-header .rs-header.style4.modify1 .menu-area .main-menu .rs-menu ul.nav-menu li.menu-item-has-children > a:hover {
  color: #f2541b;
}
.full-width-header .rs-header.style4.modify1 .menu-area .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
  color: #f2541b;
}
.full-width-header .rs-header.style4.modify1 .menu-area .main-menu .rs-menu ul.sub-menu li a {
  line-height: 16px;
  height: unset;
}
.full-width-header .rs-header.style4.modify1 .menu-area .main-menu .rs-menu ul.sub-menu li a:hover {
  color: #f2541b;
}
.full-width-header .rs-header.style4.modify1 .menu-area .main-menu .rs-menu ul.sub-menu li.current-menu-item > a,
.full-width-header .rs-header.style4.modify1 .menu-area .main-menu .rs-menu ul.sub-menu li.active > a {
  color: #f2541b !important;
}
.full-width-header .rs-header.style4.modify1 .menu-area .main-menu .rs-menu ul.sub-menu li:last-child {
  margin: 0;
}
.full-width-header .rs-header.style4.modify1 .menu-area.sticky .box-layout {
  margin-top: unset;
}
.full-width-header .rs-header.style4.modify1 .expand-btn-inner ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.full-width-header .rs-header.style4.modify1 .expand-btn-inner ul .sidebarmenu-search > a {
  border-right: 1px solid #ccc;
  padding-right: 20px;
}
.full-width-header .rs-header.style4.modify1 .expand-btn-inner ul .sidebarmenu-search > a i {
  color: #101010;
}
.full-width-header .rs-header.style4.modify1 .expand-btn-inner ul .sidebarmenu-search:hover {
  color: #106eea;
}
.full-width-header .rs-header.style4.modify1 .expand-btn-inner ul li i:before {
  font-size: 15px;
  font-weight: 600;
  color: #101010;
  padding-left: 20px;
}
.full-width-header .rs-header.style4.modify1 .expand-btn-inner ul .quote-btn a {
  font-size: 14px;
  background-image: linear-gradient(150deg, #dd4c23 19%, #f27c1e 100%);
  padding: 16px 26px;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  padding-right: 30px;
  text-transform: uppercase;
}
.full-width-header .rs-header.style4.modify1 .expand-btn-inner ul .quote-btn a:hover {
  background-image: linear-gradient(90deg, #e45d21 19%, #e45d21 100%);
  color: #ffffff;
}
.full-width-header .rs-header.style5 .menu-area .row-table {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.full-width-header .mobile-menu {
  display: none;
}
.full-width-header .mobile-menu .mobile-logo {
  float: left;
}
.full-width-header .mobile-menu .mobile-logo img {
  max-height: 30px;
}
.full-width-header .mobile-menu .rs-menu-toggle {
  color: #ffffff;
  float: right;
}
.full-width-header .mobile-menu .rs-menu-toggle:hover {
  opacity: 0.8;
}
.full-width-header .mobile-menu .rs-menu-toggle.primary {
  color: #106eea;
}
.full-width-header .mobile-menu .rs-menu-toggle.secondary {
  color: #03228f;
}
.full-width-header .right_menu_togle {
  background-image: linear-gradient(90deg, #e2e9f7 0%, #ffffff 100%);
  padding: 50px;
  width: 500px;
  right: -500px;
  z-index: 999999;
  transition: all 0.3s ease;
  position: fixed;
}
.full-width-header .right_menu_togle .close-btn {
  overflow: visible;
}
.full-width-header .right_menu_togle .close-btn .nav-link {
  position: absolute;
  right: 25px;
  top: 25px;
}
.full-width-header .right_menu_togle .close-btn .nav-link .humburger {
  max-width: 45px;
  display: flex !important;
  flex-wrap: wrap;
  margin: -5px;
}
.full-width-header .right_menu_togle .close-btn .nav-link .humburger span {
  height: 5px;
  width: 5px;
  display: block;
  background: #0a0a0a;
  border-radius: 50%;
  transition: none;
  list-style: none;
  transition: all 0.3s ease;
  margin: 4px 5px;
}
.full-width-header .right_menu_togle .close-btn .nav-link .humburger span.dot2,
.full-width-header .right_menu_togle .close-btn .nav-link .humburger span.dot4,
.full-width-header .right_menu_togle .close-btn .nav-link .humburger span.dot6,
.full-width-header .right_menu_togle .close-btn .nav-link .humburger span.dot8 {
  background: #106eea;
}
.full-width-header .right_menu_togle .canvas-logo {
  padding-left: 0;
  padding-bottom: 25px;
}
.full-width-header .right_menu_togle .canvas-logo img {
  max-height: 36px;
}
.full-width-header .right_menu_togle .sidebarnav_menu li a {
  font-size: 17px;
  color: #222;
  padding-left: 0;
}
.full-width-header .right_menu_togle .sidebarnav_menu li a:hover {
  color: #106eea;
}
.full-width-header .right_menu_togle .canvas-contact {
  padding: 25px 0 0;
}
.full-width-header .right_menu_togle .canvas-contact .address-area .address-list {
  display: flex;
  margin-top: 18px;
}
.full-width-header .right_menu_togle .canvas-contact .address-area .address-list .info-icon i:before {
  margin: 0 20px 0 0;
  font-size: 25px;
  color: #0b70e1;
}
.full-width-header .right_menu_togle .canvas-contact .address-area .address-list .info-content .title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #101010;
  margin-bottom: 5px;
}
.full-width-header .right_menu_togle .canvas-contact .address-area .address-list .info-content em {
  display: block;
  font-style: normal;
  line-height: 22px;
  font-size: 15px;
}
.full-width-header .right_menu_togle .canvas-contact .address-area .address-list .info-content em a {
  color: #333333;
}
.full-width-header .right_menu_togle .canvas-contact .social {
  margin: 50px 0 0;
}
.full-width-header .right_menu_togle .canvas-contact .social li {
  display: inline-block;
  padding-right: 10px;
}
.full-width-header .right_menu_togle .canvas-contact .social li a i {
  font-size: 15px;
  color: #fff;
  text-align: center;
  background: #032390;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 3px;
}
.full-width-header .right_menu_togle .canvas-contact .social li a:hover {
  opacity: 0.82;
}
.full-width-header .right_menu_togle .canvas-contact .social li:last-child {
  padding: 0;
}
.full-width-header .right_menu_togle.home14-style-togle .canvas-contact .address-area .address-list .info-content em a:hover {
  color: #ff7045;
}
.full-width-header .right_menu_togle.home14-style-togle .canvas-contact .social li a i {
  background: #ff7045;
}
body .search-modal {
  background: #0B70E1;
  position: fixed;
  top: 0;
  bottom: 0;
  background: rgba(16, 110, 234, 0.98);
  width: 100%;
  height: 100%;
  z-index: 9999;
  left: 0;
}
body .search-modal .modal-content {
  position: initial;
  border: 0;
  background: transparent;
}
body .search-modal .search-block input {
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
  border-color: rgba(255, 255, 255, 0.4);
  box-shadow: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
}
body .search-modal .search-block ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #ffffff;
  opacity: 1;
}
body .search-modal .search-block ::-moz-placeholder {
  /* Firefox 19+ */

  color: #ffffff;
  opacity: 1;
}
body .search-modal .search-block :-ms-input-placeholder {
  /* IE 10+ */

  color: #ffffff;
  opacity: 1;
}
body .search-modal .search-block :-moz-placeholder {
  /* Firefox 18- */

  color: #ffffff;
  opacity: 1;
}
body .search-modal .close {
  position: fixed;
  right: 50px;
  top: 50px;
  background: #ffffff;
  color: #0a0a0a;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  transition: all .5s ease;
  font-size: 18px;
}
body .search-modal .close span::before {
  font-size: 18px;
}
body .search-modal .close:hover {
  opacity: 0.8;
}
/*------------------------------------
    04. Sticky Menu CSS
--------------------------------------*/
.menu-sticky {
  background: #fff;
  margin: 0;
  z-index: 999;
  width: 100%;
  top: 0;
  position: relative;
}
@-webkit-keyframes sticky-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes sticky-animation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* ------------------------------------
    05. About Section CSS
---------------------------------------*/
.about-us {
  margin-top: 70px;
  margin-left:5px;
  font-size: 20px;
  font-weight: 500 ;
  
}
.about-us p{
  line-height: 5px;
}
.rs-about .rs-animation-shape {
  position: relative;
  z-index: 1;
}
.rs-about .rs-animation-shape .pattern {
  position: absolute;
  top: 22px;
}
.rs-about .rs-animation-shape .middle {
  position: absolute;
  left: -165px;
  top: -56px;
  z-index: -1;
}
.rs-about .rs-animation-shape .bottom-shape {
  position: absolute;
  left: -165px;
  top: 395px;
  z-index: -1;
}
.rs-about .rs-animation-shape .middle-image2 {
  position: absolute;
  top: -80px;
  left: -25px;
  z-index: -1;
}
.rs-about .shape-image {
  position: relative;
}
.rs-about .shape-image .top {
  position: absolute;
  right: 51%;
  top: -125px;
}
.rs-about .shape-image .bottom {
  position: absolute;
  right: 0;
  top: 145px;
}
.rs-about.style2 {
  background-color: #F6F7F9;
  border-radius: 0px 270px 0px 0px;
  position: relative;
}
.rs-about.style2 .image-part {
  position: absolute;
  left: 35px;
}
.rs-about.style2 .image-part img {
  max-width: 50%;
}
.rs-about.style2.modify1 {
  background-color: unset;
  border-radius: unset;
  position: unset;
}
.rs-about.style2.modify1 .images {
  left: unset;
  position: unset;
}
.rs-about.style2.modify1 .images img {
  max-width: 600px;
}
.rs-about.style2.modify2 {
  border-radius: unset;
  position: relative;
}
.rs-about.style2.modify2 .about-img img {
  max-width: 690px;
}
.rs-about.style3 {
  position: relative;
}
.rs-about.style3 .rs-animation-image {
  position: relative;
}
.rs-about.style3 .rs-animation-image .pattern-img {
  text-align: center;
  z-index: -1;
}
.rs-about.style3 .rs-animation-image .middle-img {
  position: absolute;
  left: 10px;
  top: -30px;
}
.rs-about.style4 .about-content .logo-img {
  margin-bottom: 15px;
}
.rs-about.style4 .about-content .logo-img img {
  width: 71px;
}
.rs-about.style4 .about-content .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #102B3E;
  margin-bottom: 30px;
  padding-right: 24px;
}
.rs-about.style4 .about-content .title span {
  color: #F9780F;
}
.rs-about.style4 .about-content p {
  padding-right: 121px;
  margin-bottom: 40px;
}
.rs-about.style5 {
  position: relative;
  z-index: 1;
}
.rs-about.style5 .about-bg {
  background-color: #F5F6FE;
  opacity: 0.8;
}
.rs-about.style5 .about-bg .about-animate {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -11;
}
.rs-about.home14-about-style .about-bottom-section {
  position: relative;
  z-index: 9;
}
.rs-about.home14-about-style .about-bottom-section .addon-services {
  margin: -88px 26px 0px -20px;
  padding: 15px 20px 15px 20px;
  background-image: linear-gradient(120deg, #1642fd 0%, #b12bf3 100%);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
}
.rs-about.home14-about-style .about-bottom-section .addon-services .icon-images img {
  max-width: unset;
  width: 50px;
  height: 50px;
}
.rs-about.home14-about-style .about-bottom-section .addon-services .services-text {
  margin: 0px 0px 0px 20px;
}
.rs-about.home14-about-style .about-bottom-section .addon-services .services-text .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;
  color: #ffffff;
  margin: 0 0 10px;
}
.rs-about .software-img img {
  max-width: 600px;
}
/* ------------------------------------
    06. Banner Section CSS
---------------------------------------*/
.rs-banner.main-home {
  background: url(../images/services/main-home/icons/bg-box-2.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 765px;
}
.rs-banner.main-home .content-wrap {
  position: relative;
  padding-top: 40px;
}
.rs-banner.main-home .content-wrap .it-title {
  font-size: 52px;
  line-height: 1.3;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
  padding-bottom: 20px;
}
.rs-banner.main-home .content-wrap .description .desc {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
  padding-bottom: 40px;
}
.rs-banner.style2 {
  background: url(../images/banner/banner-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
}
.rs-banner.style2 .banner-content {
  padding: 210px 0 210px;
}
.rs-banner.style2 .banner-content .sub-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.rs-banner.style2 .banner-content .title {
  font-size: 55px;
  line-height: 65px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.rs-banner.style2 .banner-content .title-small {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.rs-banner.style3 {
  background-image: linear-gradient(100deg, #03228f 34%, #0e73e4 100%);
  border-radius: 0px 0px 0px 270px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 300px 0px 270px 0px;
  position: relative;
}
.rs-banner.style3 .banner-content {
  position: relative;
  z-index: 1;
}
.rs-banner.style3 .banner-content .sub-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.rs-banner.style3 .banner-content .title {
  font-size: 52px;
  line-height: 68px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 20px;
}
.rs-banner.style3 .banner-content .desc {
  font-size: 20px;
  line-height: 34px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 55px;
}
.rs-banner.style3 .banner-content .banner-btn li {
  display: inline-block;
}
.rs-banner.style3 .images-part {
  position: absolute;
  right: 3%;
  top: 55%;
  transform: translateY(-50%);
  max-width: 48%;
}
.rs-banner.style3.modify1 {
  border-radius: unset;
  padding: 400px 0px 190px 0px;
}
.rs-banner.style3.modify1 .banner-content .title {
  font-size: 42px;
  line-height: 59px;
}
.rs-banner.style3.modify1 .banner-content .desc {
  margin-bottom: 35px;
}
.rs-banner.style3.modify1 .banner-content .banner-btn li {
  display: unset;
}
.rs-banner.style3.modify1 .images-part {
  position: absolute;
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.rs-banner.style3.modify1 .images-part img {
  max-width: 900px;
}
.rs-banner.style3.modify2 {
  background: unset;
  border-radius: unset;
  padding: 130px 0px 100px 0px;
}
.rs-banner.style3.modify2 .banner-content .title {
  color: #101010;
}
.rs-banner.style3.modify2 .banner-content .desc {
  color: #454545;
  margin-bottom: 35px;
}
.rs-banner.style3.modify2 .banner-content .banner-btn {
  position: relative;
}
.rs-banner.style3.modify2 .banner-content .banner-btn li {
  display: unset;
}
.rs-banner.style3.modify2 .banner-content .banner-btn li .rs-videos .animate-border a {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.rs-banner.style3.modify2 .banner-img img {
  max-width: 850px;
}
.rs-banner.style3.modify2 .rs-animation {
  position: absolute;
  left: 0;
  top: 90px;
}

.container.rs-bg-animation .rs-animation {
  position: absolute;
  left: 0;
  top: 90px;
}

.rs-banner.style4 .banner-content {
  padding: 250px 0 250px;
  max-width: 572px;
}
.rs-banner.style4 .banner-content .sub-title {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  display: block;
  margin-bottom: 15px;
}
.rs-banner.style4 .banner-content .title {
  font-size: 60px;
  line-height: 68px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 25px;
}
.rs-banner.style4 .banner-content .desc {
  font-size: 20px;
  line-height: 34px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 55px;
}
.rs-banner.style4 .banner-content .banner-btn {
  position: relative;
}
.rs-banner.style4 .banner-content .banner-btn li {
  display: unset;
}
.rs-banner.style4 .banner-content .banner-btn li .rs-videos .animate-border a {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.rs-banner.style5 .banner-content {
  padding: 250px 0 200px;
}
.rs-banner.style5 .banner-content .icon-seller img {
  width: 80px;
}
.rs-banner.style5 .banner-content .title {
  font-size: 52px;
  line-height: 70px;
  font-weight: 700;
  color: #101010;
}
.rs-banner.style5 .banner-content .title span {
  color: #f24c1a;
}
.rs-banner.style5 .banner-content .desc {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #454545;
  margin-bottom: 40px;
  padding-right: 188px;
}
.rs-banner.style5 .banner-img .images-part {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5.6%;
  max-width: 40%;
}
.rs-banner.style5 .banner-img .images-part img {
  animation: move-y 2s alternate infinite;
}
.rs-banner.style5 .banner-img .layer-img {
  position: absolute;
  left: 58%;
  transform: translateX(-50%);
  bottom: 30px;
}
.rs-banner.style6 {
  position: relative;
  background: url(../images/services/main-home/icons/bg-box.png);
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

}
/*
.rs-banner.style6 .banner-content {
  padding: 170px 0 200px; 
}
*/
.rs-banner.style6 .banner-content .sub-text {
  font-size: 1.7rem;
  line-height: 30px;
  font-weight: 600;
  /* color: #f24c1a; */
  display: block;
  text-transform: uppercase;
  margin-bottom: 14px;
}
.rs-banner.style6 .banner-content .title {
  font-size: 4rem;
  line-height: 75px;
  font-weight: 800;
  color: #101010;
}
.title .sub-title{
  font-size: 3rem;
  font-weight: 700;
}
.rs-banner.style6 .banner-content .title .blue-color {
  color: #0073ff;
}
.rs-banner.style6 .banner-content .title .pink-color {
  color: #f24c1a;
}
.rs-banner.style6 .banner-content .desc {
  font-size: 30px;
  line-height: 20px;
  font-weight: 500;
  color: #454545;
  margin-bottom: 50px;
  padding-right: 68px;
}
.rs-banner.style6 .images-part-new {
  /* position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);*/
  max-width: 900px; 
}
.rs-banner.style6 .images-part-new img {
  animation: move-y 2s alternate infinite;
}

.rs-banner.style7 .banner-content {
  padding: 208px 0 200px;
}
.rs-banner.style7 .banner-content .sub-title {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  color: #FD6509;
  display: block;
  margin: 0px 0px 15px 0px;
}
.rs-banner.style7 .banner-content .title {
  font-size: 45px;
  font-weight: 700;
  line-height: 1.3em;
  color: #fff;
  margin: 0px 0px 20px 0px;
}
.rs-banner.style7 .banner-content .desc {
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  color: #ffffff;
  margin: 0px 40px 54px 0px;
}

.rs-banner.style8 .banner-content {
  padding: 320px 0 360px;
}
.rs-banner.style8 .banner-content .sub-title {
  font-size: 18px;
  line-height: 32px;
  text-transform: uppercase;
  font-weight: 500;
  color: #f24c1a;
  display: block;
  margin: 0px 0px 15px 0px;
}
.rs-banner.style8 .banner-content .title {
  font-size: 52px;
  font-weight: 700;
  line-height: 70px;
  color: #102b3e;
  max-width: 820px;
  margin: 0 auto 40px;
}
.rs-banner.style8 .banner-content .title span {
  color: #f24c1a;
}
.rs-banner.style8 .banner-content .banner-btn li {
  display: inline-block;
  margin-right: 25px;
}
.rs-banner.style8 .banner-content .banner-btn li:last-child {
  margin-right: 0;
}

.rs-banner.style9 .banner-content {
  padding: 300px 0 235px;
}
.rs-banner.style9 .banner-content .sub-text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #ffffff;
  display: block;
  margin-bottom: 10px;
}
.rs-banner.style9 .banner-content .title {
  font-size: 58px;
  line-height: 73px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 25px;
}
.rs-banner.style9 .banner-content .desc {
  font-size: 18px;
  line-height: 31px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 40px;
}
.rs-banner.style9 .images-part img {
  position: relative;
  right: 3%;
  max-width: 650px;
  transform: translateY(20%);
}
.rs-banner.style9 .banner-animation .bnr-animate {
  position: absolute;
  bottom: 0;
  z-index: -11;
}
.rs-banner.style9 .banner-animation .bnr-animate.one {
  left: 48%;
  top: 19%;
}
.rs-banner.style9 .banner-animation .bnr-animate.two {
  left: 9%;
  top: 17%;
}
.rs-banner.style9 .banner-animation .bnr-animate.three {
  left: 31%;
  bottom: 28%;
}
.rs-banner.style9 .banner-animation .bnr-animate.four {
  right: 14%;
  bottom: 28%;
}
.rs-banner.style9 .banner-animation .bnr-animate.five {
  left: 36%;
  bottom: 9%;
}
.rs-banner.style9 .banner-animation .bnr-animate.six {
  left: 45%;
  bottom: 4%;
}

.rs-banner.style10 .banner-content {
  padding: 305px 0 350px;
}
.rs-banner.style10 .banner-content .sub-text {
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  display: block;
  margin: 0px 0px 15px 0px;
}
.rs-banner.style10 .banner-content .title {
  font-size: 56px;
  font-weight: 700;
  line-height: 1.3;
  color: #ffffff;
  max-width: 820px;
  margin: 0 auto 48px;
}
.rs-banner.style10 .banner-content .title span {
  color: #ff7045;
}
.rs-banner.style10 .banner-content .banner-btn li {
  display: inline-block;
  margin-right: 25px;
}
.rs-banner.style10 .banner-content .banner-btn li:last-child {
  margin-right: 0;
}
/* ------------------------------------
    07. Breadcrumbs Section CSS
---------------------------------------*/
.rs-breadcrumbs {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.rs-breadcrumbs .breadcrumbs-inner {
  padding: 140px 0 150px;
}
.rs-breadcrumbs .breadcrumbs-inner .page-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  margin-bottom: 5px;
  color: #ffffff;
}
.rs-breadcrumbs .breadcrumbs-inner .page-title.new-title {
  margin: 0 auto;
  max-width: 850px;
}
.rs-breadcrumbs .breadcrumbs-inner ul {
  padding: 0;
}
.rs-breadcrumbs .breadcrumbs-inner ul li {
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
}
.rs-breadcrumbs .breadcrumbs-inner ul li a {
  position: relative;
  padding-right: 22px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #ffffff;
}
.rs-breadcrumbs .breadcrumbs-inner ul li a:before {
  background-color: #fff;
  content: "";
  height: 15px;
  width: 1px;
  position: absolute;
  right: 9px;
  top: 2px;
  transform: rotate(26deg);
}

/* ------------------------------------
    08. Slider Section CSS
---------------------------------------*/
.rs-slider.style1 .slider-content {
  padding: 260px 0 260px;
}
.rs-slider.style1 .slider-content .sl-sub-title {
  font-size: 40px;
  line-height: 45px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 25px;
}
.rs-slider.style1 .slider-content .sl-title {
  font-size: 100px;
  line-height: 84px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 40px;
}
.rs-slider.style1 .slider-content .sl-desc {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 40px;
}
.rs-slider.style1 .slider-content .slider-btn li {
  display: inline-block;
  margin-right: 30px;
}
.rs-slider.style1 .slider-content .slider-btn li:last-child {
  margin-right: 0;
}
.rs-slider.style1 .owl-nav .owl-next,
.rs-slider.style1 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #ffffff;
  text-align: center;
  color: #101010;
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-slider.style1 .owl-nav .owl-next i:before,
.rs-slider.style1 .owl-nav .owl-prev i:before {
  content: "\f112";
  font-family: Flaticon;
}
.rs-slider.style1 .owl-nav .owl-next:hover,
.rs-slider.style1 .owl-nav .owl-prev:hover {
  background: #106eea;
  color: #ffffff;
}
.rs-slider.style1 .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-slider.style1 .owl-nav .owl-next i:before {
  content: "\f113";
}
.rs-slider.style1:hover .owl-nav .owl-next,
.rs-slider.style1:hover .owl-nav .owl-prev {
  left: 50px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-slider.style1:hover .owl-nav .owl-next {
  right: 50px;
  left: unset;
}
.rs-slider.style2 .slider-content {
  padding: 140px 0 200px;
}
.rs-slider.style2 .slider-content .sl-img {
  margin-bottom: 20px;
}
.rs-slider.style2 .slider-content .sl-img img {
  width: 90px;
  height: 90px;
}
.rs-slider.style2 .slider-content .sl-title {
  font-size: 78px;
  line-height: 80px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 4px;
  margin-bottom: 20px;
}
.rs-slider.style2 .slider-content .sl-title2 {
  font-size: 73px;
  line-height: 80px;
  font-weight: 900;
  color: #ffffff;
  margin-bottom: 30px;
}
.rs-slider.style2 .slider-content .sl-desc {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #ffffff;
  max-width: 600px;
  margin-bottom: 20px;
}

.rs-slider.style2 .owl-nav .owl-next,
.rs-slider.style2 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #ffffff;
  text-align: center;
  color: #101010;
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-slider.style2 .owl-nav .owl-next i:before,
.rs-slider.style2 .owl-nav .owl-prev i:before {
  content: "\f112";
  font-family: Flaticon;
}
.rs-slider.style2 .owl-nav .owl-next:hover,
.rs-slider.style2 .owl-nav .owl-prev:hover {
  background: #106eea;
  color: #ffffff;
}
.rs-slider.style2 .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-slider.style2 .owl-nav .owl-next i:before {
  content: "\f113";
}
.rs-slider.style2:hover .owl-nav .owl-next,
.rs-slider.style2:hover .owl-nav .owl-prev {
  left: 50px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-slider.style2:hover .owl-nav .owl-next {
  right: 50px;
  left: unset;
}
.rs-slider.style3 .owl-nav .owl-next,
.rs-slider.style3 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  border: 1px solid #eaecf1;
  background: rgba(117, 117, 117, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #787cf2;
  transition: all 0.3s ease;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}
.rs-slider.style3 .owl-nav .owl-next i,
.rs-slider.style3 .owl-nav .owl-prev i {
  margin-top: 15px;
}
.rs-slider.style3 .owl-nav .owl-next i:before,
.rs-slider.style3 .owl-nav .owl-prev i:before {
  content: "\f112";
  font-family: Flaticon;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
}
.rs-slider.style3 .owl-nav .owl-next:hover,
.rs-slider.style3 .owl-nav .owl-prev:hover {
  background: #fff;
  color: #787cf2;
  border: 1px solid #454545;
  box-shadow: 0 0 10px #eee;
}
.rs-slider.style3 .owl-nav .owl-next {
  right: -75px;
  left: unset;
}
.rs-slider.style3 .owl-nav .owl-next i:before {
  content: "\f113";
  font-size: 18px;
  font-weight: 400;
}
.rs-slider.style3 .owl-nav .owl-prev {
  right: unset;
  left: -105px;
}
.rs-slider.style3 .owl-nav .owl-prev i:before {
  content: "\f112";
  font-size: 18px;
  font-weight: 400;
}
/*------------------------------------
    09. Video Section CSS
------------------------------------*/
.rs-video-wrap {
  margin-top: -125px;
  z-index: -111;
}

.rs-video-wrap .rs-requset {
  padding: 90px 60px 90px 60px;
  background: #f4f7ff;
}
.rs-video-wrap .rs-requset .from-control {
  width: 100%;
  max-width: 100%;
  opacity: 1;
  padding: 10px 18px;
  border: 1px solid #F1F5FB;
}
.rs-video-wrap .rs-requset textarea {
  height: 120px;
}
.rs-video-wrap .rs-requset ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #454545;
  opacity: 1;
}
.rs-video-wrap .rs-requset ::-moz-placeholder {
  /* Firefox 19+ */

  color: #454545;
  opacity: 1;
}
.rs-video-wrap .rs-requset :-ms-input-placeholder {
  /* IE 10+ */

  color: #ffffff;
  opacity: 1;
}
.rs-video-wrap .rs-requset :-moz-placeholder {
  /* Firefox 18- */

  color: #454545;
  opacity: 1;
}
.rs-video-wrap.style2 {
  margin-top: -380px;
  z-index: -111;
}
.rs-video-wrap.style2 .rs-requset {
  padding: 160px 60px 160px 60px;
}
.rs-video-wrap.style3 {
  margin-top: unset;
  z-index: unset;
}
.rs-video-wrap.style3 .grdiant-bg {
  background-image: linear-gradient(160deg, #03228f 0%, #4e95ed 100%);
}
.rs-video-wrap.style3 .rs-requset {
  padding: 40px 60px;
  background: transparent;
}
.rs-video-wrap.style3 .rs-requset .from-control {
  padding: 15px 15px 15px 15px;
  border-radius: 5px 5px 5px 5px;
  color: #666666;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #EFEFEF;
  background-color: #FFFFFF;
}
.rs-video-wrap.style3 .rs-requset textarea {
  height: 120px;
}
.rs-video-wrap.style3 .rs-requset ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #666666;
  opacity: 1;
}
.rs-video-wrap.style3 .rs-requset ::-moz-placeholder {
  /* Firefox 19+ */

  color: #666666;
  opacity: 1;
}
.rs-video-wrap.style3 .rs-requset :-ms-input-placeholder {
  /* IE 10+ */

  color: #666666;
  opacity: 1;
}
.rs-video-wrap.style3 .rs-requset :-moz-placeholder {
  /* Firefox 18- */

  color: #666666;
  opacity: 1;
}
/* ------------------------------------
    10. Faq Section CSS
---------------------------------------*/
.rs-faq .faq-content .accordion {
  border-width: 12px;
}
.rs-faq .faq-content .accordion .card {
  margin-bottom: 15px;
  border: none;
  background-color: #fff;
  border-radius: 30px;
}
.rs-faq .faq-content .accordion .card:last-child {
  margin-bottom: 0;
}
.rs-faq .faq-content .accordion .card .card-header {
  display: flex;
  align-items: center;
  padding: 0 !important;
  border: none;
  background-color: #fff;
  box-shadow: unset;
}
.rs-faq .faq-content .accordion .card .card-header .card-link {
  position: relative;
  display: block;
  width: 100%;
  padding: 20px 30px 0 30px;
  height: 65px;
  line-height: 1.3;
  font-size: 18px;
  font-weight: 600;
  background: #ffffff;
  color: #101010;
  border-radius: 30px 30px 0 0;
}
.rs-faq .faq-content .accordion .card .card-header .card-link:after {
  position: absolute;
  font-family: FontAwesome;
  content: "\f068";
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  background-image: linear-gradient(250deg, #4e95ed 0%, #03228f 100%);
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 100%;
}
.rs-faq .faq-content .accordion .card .card-header .card-link.collapsed {
  border-radius: 30px 30px 30px 30px;
}
.rs-faq .faq-content .accordion .card .card-header .card-link.collapsed:after {
  color: #ffffff;
  content: "\f067";
}
.rs-faq .faq-content .accordion .card .card-body {
  color: #101010;
  border-radius: 0 0 30px 30px;
  padding: 0 30px 30px 30px !important;
  width: 100%;
  background: #ffffff;
}
/* ------------------------------------
    11. Counter Section CSS
---------------------------------------*/
.rs-counter {
  margin-top: -78px;
  position: absolute;
  min-width: 860px;
  right: 100px;
}
.rs-counter .counter-top-area .counter-list .counter-text .count-number {
  margin-bottom: 7px;
}
.rs-counter .counter-top-area .counter-list .counter-text .count-number .rs-count {
  color: #03228F;
  font-size: 48px;
  font-weight: 900;
  position: relative;
}
.rs-counter .counter-top-area .counter-list .counter-text .count-number .rs-count.plus {
  padding-right: 30px !important;
}
.rs-counter .counter-top-area .counter-list .counter-text .count-number .rs-count.plus:before {
  content: '+';
  position: absolute;
  right: 0;
}
.rs-counter .counter-top-area .counter-list .counter-text .count-number .rs-count.k {
  padding-right: 30px !important;
}
.rs-counter .counter-top-area .counter-list .counter-text .count-number .rs-count.k:before {
  content: 'k';
  position: absolute;
  right: 0;
}
.rs-counter .counter-top-area .counter-list .counter-text .title {
  font-size: 19px;
  line-height: 29px;
  color: #333333;
  font-weight: 600;
  margin: 0;
}
.rs-counter.style2 {
  margin-top: unset;
  position: unset;
  min-width: unset;
  right: unset;
}
.rs-counter.style2 .counter-top-area .counter-list {
  display: flex;
  align-items: center;
}
.rs-counter.style2 .counter-top-area .counter-list .count-icon {
  width: 80px;
  display: inline-block;
  padding-right: 15px;
}
.rs-counter.style2 .counter-top-area .counter-list .counter-text .count-number {
  margin-bottom: 7px;
}
.rs-counter.style2 .counter-top-area .counter-list .counter-text .count-number .rs-count {
  color: #FFFFFF;
  font-size: 53px;
  font-weight: 700;
  -webkit-text-fill-color: #042BA1;
  -webkit-text-stroke-width: 1px;
  position: relative;
}
.rs-counter.style2 .counter-top-area .counter-list .counter-text .count-number .rs-count.plus {
  padding-right: 22px;
}
.rs-counter.style2 .counter-top-area .counter-list .counter-text .count-number .rs-count.plus:before {
  content: '+';
  position: absolute;
  right: 0;
  top: 25px;
  font-size: 35px;
}
.rs-counter.style2 .counter-top-area .counter-list .counter-text .count-number .rs-count.k {
  padding-right: 22px;
}
.rs-counter.style2 .counter-top-area .counter-list .counter-text .count-number .rs-count.k:before {
  content: 'k';
  position: absolute;
  right: 0;
  top: 25px;
  font-size: 35px;
}
.rs-counter.style2 .counter-top-area .counter-list .counter-text .title {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}
.rs-counter.style3 {
  margin-top: unset;
  position: unset;
  min-width: unset;
  right: unset;
}
.rs-counter.style3 .counter-top-area .counter-list .counter-text .count-number .rs-count {
  color: #03228F;
  font-size: 45px;
  font-weight: 700;
  padding: 0px 0px 0px 0px;
}
.rs-counter.style3 .counter-top-area .counter-list .counter-text .count-number .rs-count.orange-color {
  color: #DD4C23;
}
.rs-counter.style3 .counter-top-area .counter-list .counter-text .title {
  font-size: 18px;
  font-weight: 600;
  color: #101010;
}
.rs-counter.style3.modify1 {
  background-image: linear-gradient(180deg, #03228f 0%, #4e95ed 100%);
}
.rs-counter.style3.modify1 .counter-top-area {
  text-align: center;
}
.rs-counter.style3.modify1 .counter-top-area .counter-list .counter-text .count-number .rs-count {
  color: #ffffff;
}
.rs-counter.style3.modify1 .counter-top-area .counter-list .counter-text .count-number .prefix {
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
}
.rs-counter.style3.modify1 .counter-top-area .counter-list .counter-text .title {
  color: #ffffff;
}
.rs-counter.style3.modify2 .counter-top-area {
  text-align: center;
}
.rs-counter.style3.modify2 .counter-top-area .counter-list .counter-text .count-number {
  padding: 0px 0px 10px 0px;
}
.rs-counter.style3.modify2 .counter-top-area .counter-list .counter-text .count-number .rs-count.purple-color {
  color: #7D88FC;
}
.rs-counter.style3.modify2 .counter-top-area .counter-list .counter-text .count-number .rs-count.blue-color {
  color: #0073FF;
}
.rs-counter.style3.modify2 .counter-top-area .counter-list .counter-text .count-number .rs-count.pink-color {
  color: #F1491A;
}
.rs-counter.style3.modify2 .counter-top-area .counter-list .counter-text .count-number .rs-count.paste-color {
  color: #39EBE6;
}
.rs-counter.style3.modify2 .counter-top-area .counter-list .counter-text .title {
  color: #454545;
  font-size: 20px;
  font-weight: 600;
}
.rs-counter.style3.modify3 {
  margin-top: -210px;
}
.rs-counter.style3.modify3 .counter-top-area {
  text-align: center;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #eee;
  padding: 70px 20px 90px 20px;
}
.rs-counter.style3.modify3 .counter-top-area .counter-list .counter-text {
  position: relative;
  z-index: 1;
}
.rs-counter.style3.modify3 .counter-top-area .counter-list .counter-text .count-number {
  padding: 0px 0px 10px 0px;
}
.rs-counter.style3.modify3 .counter-top-area .counter-list .counter-text .count-number .rs-count {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  color: #787CF2;
  padding: 0px 0px 12px 0px;
}
.rs-counter.style3.modify3 .counter-top-area .counter-list .counter-text .title {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: #454545;
  margin: 0;
}
.rs-counter.style3.modify3 .counter-top-area .counter-list .counter-text:before {
  content: '';
  position: absolute;
  right: 76px;
  top: -16px;
  width: 85px;
  height: 137px;
  background: #f9f9f9;
  z-index: -11;
}
.rs-counter.style4 {
  margin-top: -130px;
  position: unset;
  min-width: unset;
  right: unset;
}
.rs-counter.style4 .counter-top-area {
  text-align: center;
}
.rs-counter.style4 .counter-top-area .counter-list .counter-text .count-number {
  margin-bottom: 7px;
}
.rs-counter.style4 .counter-top-area .counter-list .counter-text .count-number .rs-count {
  color: #fff;
  font-size: 52px;
  font-weight: 700;
}
.rs-counter.style4 .counter-top-area .counter-list .counter-text .count-number .rs-count.plus {
  padding-right: 30px !important;
}
.rs-counter.style4 .counter-top-area .counter-list .counter-text .count-number .rs-count.plus:before {
  content: '+';
  position: absolute;
  right: 0;
}
.rs-counter.style4 .counter-top-area .counter-list .counter-text .count-number .rs-count.k {
  padding-right: 30px !important;
}
.rs-counter.style4 .counter-top-area .counter-list .counter-text .count-number .rs-count.k:before {
  content: 'k';
  position: absolute;
  right: 0;
}
.rs-counter.style4 .counter-top-area .counter-list .counter-text .title {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}
.rs-counter.style5 {
  margin-top: unset;
  position: relative;
  min-width: unset;
  right: unset;
  margin: unset;
}
.rs-counter.style5 .counter-top-area {
  padding: 15px;
}
.rs-counter.style5 .counter-top-area .images-part img {
  border-radius: 10px 10px 10px 10px;
}
.rs-counter.style5 .counter-top-area .counter-list {
  margin: -186px -20px 0px 20px;
  padding: 215px 30px 25px 20px;
  background-color: #663DEC;
  border-radius: 10px 10px 10px 10px;
}
.rs-counter.style5 .counter-top-area .counter-list .counter-text .count-number {
  margin-bottom: 7px;
}
.rs-counter.style5 .counter-top-area .counter-list .counter-text .count-number .rs-count {
  color: #FF7045;
  font-weight: 700;
}
.rs-counter.style5 .counter-top-area .counter-list .counter-text .title {
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
}
.rs-counter.style5 .counter-top-area .counter-list:hover {
  background-image: linear-gradient(180deg, #343dfb 0%, #932ff5 100%);
}
/* ------------------------------------
    12. Contact Section CSS
---------------------------------------*/
.rs-contact .contact-wrap {
  background: #ffffff;
  padding: 25px 40px 50px;
  border-radius: 10px 10px 10px 10px;
}
.rs-contact .contact-wrap .content-part {
  text-align: center;
}
.rs-contact .contact-wrap .content-part .title {
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  color: #0a0a0a;
  margin: 0;
}
.rs-contact .contact-wrap .content-part .desc {
  margin: 0;
}
.rs-contact .contact-wrap .from-control {
  padding: 12px 16px 12px 16px;
  border-radius: 5px 5px 5px 5px;
  border-style: solid;
  border-color: #02010100;
  background-color: #F6F7F9;
  color: #454545;
  width: 100%;
  opacity: 4;
}
.rs-contact .contact-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #454545;
  opacity: 1;
}
.rs-contact .contact-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: #454545;
  opacity: 1;
}
.rs-contact .contact-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: #454545;
  opacity: 1;
}
.rs-contact .contact-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: #454545;
  opacity: 1;
}
.rs-contact .contact-box {
  background-image: linear-gradient(250deg, #4e95ed 0%, #03228f 100%);
  padding: 80px 30px 80px 30px;
  border-radius: 5px 5px 5px 5px;
}
.rs-contact .contact-box .address-box {
  display: flex;
}
.rs-contact .contact-box .address-box .address-icon {
  background-image: linear-gradient(275deg, #ffffff 0%, #fcfcff 100%);
  min-width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  width: 40px;
  border-radius: 50%;
  margin-right: 23px;
}
.rs-contact .contact-box .address-box .address-icon i {
  font-size: 20px;
  line-height: 20px;
  color: #03228F;
}
.rs-contact .contact-box .address-box .address-text .label {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  display: block;
  margin-bottom: 5px;
}
.rs-contact .contact-box .address-box .address-text a {
  color: #ffffff;
}
.rs-contact .contact-box .address-box .address-text a:hover {
  color: #ccc;
}
.rs-contact .contact-box .address-box .address-text .desc {
  color: #ffffff;
}
.rs-contact .contact-box.onepage-box {
  background-image: linear-gradient(220deg, #f27c1e 0%, #dd4c23 79%);
}
.rs-contact .contact-box.onepage-box .address-box .address-icon {
  background: white-color;
}
.rs-contact .contact-box.onepage-box .address-box .address-icon i {
  color: #f2541b;
}
.rs-contact .contact-box.onepage-box.modify1 {
  background-image: linear-gradient(250deg, #9c9ff9 0%, #6e71db 100%);
}
.rs-contact .contact-box.onepage-box.modify1 .address-box .address-icon {
  background: white-color;
}
.rs-contact .contact-box.onepage-box.modify1 .address-box .address-icon i {
  color: #787cf2;
}
.rs-contact .contact-box.onepage-box.home14-onepage-style {
  background-image: linear-gradient(250deg, #932ff5 0%, #343dfb 100%);
}
.rs-contact .contact-widget .from-control {
  padding: 20px 20px 20px 20px;
  border-radius: 5px 5px 5px 5px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  background-color: #F6F7F9;
  width: 100%;
  max-width: 100%;
}
.rs-contact .contact-widget textarea {
  height: 120px;
}
.rs-contact .contact-widget ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #454545;
  opacity: 1;
}
.rs-contact .contact-widget ::-moz-placeholder {
  /* Firefox 19+ */

  color: #454545;
  opacity: 1;
}
.rs-contact .contact-widget :-ms-input-placeholder {
  /* IE 10+ */

  color: #454545;
  opacity: 1;
}
.rs-contact .contact-widget :-moz-placeholder {
  /* Firefox 18- */

  color: #454545;
  opacity: 1;
}
.rs-contact .contact-widget.onepage-style .from-control {
  background: #fff;
}
.rs-contact .map-canvas {
  overflow: hidden;
}
.rs-contact .map-canvas iframe {
  float: left;
  width: 100%;
  height: 620px;
  border: unset;
}
.rs-contact.style2 .contact-box-wrap {
  background-image: linear-gradient(270deg, #4e95ed 0%, #03228f 100%);
  border-radius: 5px 5px 5px 5px;
  padding: 90px 60px 60px 60px;
}
.rs-contact.style2 .contact-box-wrap .from-control {
  padding: 16px 16px 16px 16px;
  border-radius: 5px 5px 5px 5px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #F1F5FB;
  width: 100%;
  max-width: 100%;
}
.rs-contact.style2 .contact-box-wrap textarea {
  height: 120px;
}
.rs-contact.style2 .contact-box-wrap ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #454545;
  opacity: 1;
}
.rs-contact.style2 .contact-box-wrap ::-moz-placeholder {
  /* Firefox 19+ */

  color: #454545;
  opacity: 1;
}
.rs-contact.style2 .contact-box-wrap :-ms-input-placeholder {
  /* IE 10+ */

  color: #454545;
  opacity: 1;
}
.rs-contact.style2 .contact-box-wrap :-moz-placeholder {
  /* Firefox 18- */

  color: #454545;
  opacity: 1;
}
.rs-contact.negative-margin {
  margin-top: -315px;
}
/* ------------------------------------
    13. Cta Section CSS
---------------------------------------*/
.rs-cta.style1 .title-wrap .epx-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  color: #fff;
  margin: 0px;
}
.rs-cta.style1 span {
  font-weight: 500;
  margin: 0 0 10px;
  display: block;
  line-height: 28px;
  font-family: 'Livvic', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF;
}
.rs-cta.style1.bg-widget {
  background-image: linear-gradient(150deg, #03228f 46%, #0e73e4 100%);
}
/* ------------------------------------
    14. Skill-bar Section CSS
---------------------------------------*/
.rs-skillbar.style1 .cl-skill-bar .skillbar-title {
  font-weight: 500;
  font-size: 14px;
  color: #212121;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar {
  background: #eeeeee;
  height: 7px;
  margin: 10px 0 24px;
  position: relative;
  width: 100%;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar .skillbar-bar {
  background: #106eea;
  width: 0;
  margin-bottom: 0;
  height: 7px;
  line-height: 7px;
  background-color: transparent;
  background-image: linear-gradient(190deg, #105aad 0%, #4e95ed 100%);
  background-size: 1rem 1rem;
}
.rs-skillbar.style1 .cl-skill-bar .skillbar .skill-bar-percent {
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 0;
  top: -38px;
  font-size: 16px;
  font-weight: 700;
  color: #0a0a0a;
}
.rs-skillbar.style1.modify1 .cl-skill-bar .skillbar-title {
  text-transform: uppercase;
}
.rs-skillbar.style1.modify1 .cl-skill-bar .skillbar {
  background-color: #DFEDFE;
}
.rs-skillbar.style1.modify1 .cl-skill-bar .skillbar .skillbar-bar {
  background: #03228f;
}
.rs-skillbar.style1.modify1 .cl-skill-bar .skillbar .skill-bar-percent {
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 0;
  top: -38px;
  font-size: 16px;
  font-weight: 700;
  color: #0a0a0a;
}
.rs-skillbar.style1.modify1 .cl-skill-bar .skillbar .skill-bar-percent.white-color {
  color: #fff;
}
.rs-skillbar.style1.modify2 .cl-skill-bar .skillbar-title {
  text-transform: uppercase;
}
.rs-skillbar.style1.modify2 .cl-skill-bar .skillbar {
  background: #fff;
  border: 1px solid #0B70E1;
  height: 9px;
  border-radius: 0;
  overflow: visible;
  padding: 1px;
}
.rs-skillbar.style1.modify2 .cl-skill-bar .skillbar .skillbar-bar {
  background: #007bff;
  background-image: linear-gradient(250deg, #0b70e1 19%, #03228f 100%);
  height: 5px;
}
.rs-skillbar.style1.modify2 .cl-skill-bar .skillbar .skill-bar-percent {
  right: 70px;
  color: #454545;
  font-weight: 400;
}
.rs-skillbar.style1.modify3 {
  padding: 0 120px 0 0;
}
.rs-skillbar.style1.modify3 .cl-skill-bar .skillbar {
  height: 8px;
  line-height: 8px;
  padding: 1px 1px 1px 1px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #FF571C;
  background-color: #BF1F1F00;
  border-radius: 3px 3px 3px 3px;
}
.rs-skillbar.style1.modify3 .cl-skill-bar .skillbar .skillbar-bar {
  height: 100%;
  background: #FF571C;
}
.rs-skillbar.style1.modify3 .cl-skill-bar .skillbar .skill-bar-percent {
  right: 70px;
  color: #454545;
  font-weight: 400;
}
.rs-skillbar.style1.modify3 .cl-skill-bar .skillbar.purple-style {
  border-color: #7E06E4;
}
.rs-skillbar.style1.modify3 .cl-skill-bar .skillbar.purple-style .skillbar-bar {
  background: #7E06E4;
}
.rs-skillbar.style1.modify3 .cl-skill-bar .skillbar.blue-style {
  border-color: #0073FF;
}
.rs-skillbar.style1.modify3 .cl-skill-bar .skillbar.blue-style .skillbar-bar {
  background: #0073FF;
}
.rs-skillbar.style1.modify3.skillbar-home14 {
  padding: 0 0px 0 0;
}
.rs-skillbar.style1.modify3.skillbar-home14 .cl-skill-bar .skillbar-title {
  font-weight: 400;
  font-size: 16px;
}
.rs-skillbar.style1.modify3.skillbar-home14 .cl-skill-bar .skillbar {
  border-color: #FF571C;
}
.rs-skillbar.style1.modify3.skillbar-home14 .cl-skill-bar .skillbar .skillbar-bar {
  background: #FF7045;
}
.rs-skillbar.style1.modify3.skillbar-home14 .cl-skill-bar .skillbar .skill-bar-percent {
  right: 70px;
  color: #454545;
  font-weight: 400;
}
.rs-skillbar.style1.modify3.skillbar-home14 .cl-skill-bar .skillbar.purple-style {
  border-color: #0073FF;
}
.rs-skillbar.style1.modify3.skillbar-home14 .cl-skill-bar .skillbar.purple-style .skillbar-bar {
  background: #0073FF;
}
.rs-skillbar.style1.modify3.skillbar-home14 .cl-skill-bar .skillbar.blue-style {
  border-color: #39ECE6;
}
.rs-skillbar.style1.modify3.skillbar-home14 .cl-skill-bar .skillbar.blue-style .skillbar-bar {
  background: #39ECE6;
}
.rs-skillbar.style1.home4 .skillbar .skillbar-bar {
  background-color: #FD6509;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.rs-skillbar.style1.home4 .skillbar .skillbar-bar.paste-bg {
  background-color: #03DEA8;
}
.rs-skillbar.style1.home4 .skillbar .skillbar-bar.blue-bg {
  background-color: #0A99FD;
}
.rs-skillbar.style1.home4 .skillbar .skillbar-bar.pink-bg {
  background-color: #F00674;
}
/* ------------------------------------
    15. Choose Section CSS
---------------------------------------*/
.rs-why-choose .services-wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.rs-why-choose .services-wrap .services-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 20px 0 0;
  font-size:24px;
  font-weight:500; 
}
.rs-why-choose .services-wrap .services-item img{ 
  height: 40px;
  width:40px;
  margin-right: 5px;
} 
.rs-why-choose .services-wrap .services-icon {
  padding: 5px 5px 0 0;
}
.rs-why-choose .services-wrap .services-icon img {
  height: 55px;
}
.rs-why-choose .services-wrap .services-text {
  padding: 0px 0px 0px 15px;
}
.rs-why-choose .services-wrap .services-text .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}
.rs-why-choose .services-wrap .services-text .title a {
  color: #0a0a0a;
}
.rs-why-choose .services-wrap .services-text .title a:hover {
  color: #106eea;
}
.rs-why-choose .services-wrap .services-text .services-txt {
  margin: 0;
}
.rs-why-choose.style1 .services-wrap .services-text .title a {
  color: #ffffff;
}
.rs-why-choose.style1 .services-wrap .services-text .title a:hover {
  color: #ec6f22;
}
.rs-why-choose.style1 .services-wrap .services-text .services-txt {
  color: #ffffff;
}
.rs-why-choose .animation .top-shape {
  position: absolute;
  top: 0;
  z-index: -111;
}
.rs-why-choose .animation .bottom-shape {
  position: absolute;
  bottom: -70px;
  right: 0;
  z-index: -111;
}
.rs-why-choose .animation.style2 .top-shape {
  top: -30px;
  z-index: -111;
}
.rs-why-choose .animation.style2 .bottom-shape {
  bottom: 0px;
  right: 30px;
  z-index: -111;
}
.rs-why-choose .image-part {
  position: relative;
  z-index: 1;
}
.rs-why-choose .shape-animate .top-shape {
  position: absolute;
  top: -30px;
}
.rs-why-choose .shape-animate .bottom-shape {
  position: absolute;
  bottom: 0px;
  right: 30px;
}
.rs-why-choose.style2 .services-wrap {
  display: flex;
  align-items: center;
}
.rs-why-choose.style2 .services-wrap .services-icon {
  padding: 5px 5px 0 0;
}
.rs-why-choose.style2 .services-wrap .services-icon img {
  height: 55px;
}
.rs-why-choose.style2 .services-wrap .services-text {
  padding: 0px 0px 0px 15px;
}
.rs-why-choose.style2 .services-wrap .services-text .title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}
.rs-why-choose.style2 .services-wrap .services-text .title a {
  color: #0a0a0a;
}
.rs-why-choose.style2 .services-wrap .services-text .title a:hover {
  color: #106eea;
}
.rs-why-choose.style2 .services-wrap .services-text .services-txt {
  margin: 0;
}
.rs-why-choose.style2.modify1 .services-wrap .services-text .title a {
  color: #ffffff;
}
.rs-why-choose.style2.modify1 .services-wrap .services-text .services-txt {
  color: #ffffff;
}
.rs-why-choose.style2.modify1 .services-wrap .services-text .services-txt:hover {
  color: #ec6f22;
}
.rs-why-choose.style3 .features-content .features-list li {
  margin: 0px 0px 17px 0px;
  display: flex;
  align-items: center;
}
.rs-why-choose.style3 .features-content .features-list li i {
  font-size: 30px;
  width: 40px;
  margin-right: 10px;
  color: #0b70e1;
}
.rs-why-choose.style3 .features-content .features-list li span {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  display: block;
}
.rs-why-choose.style3 .features-wrap .sub-title {
  font-size: 19px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 25px;
}
.rs-why-choose.style3 .features-wrap .title {
  font-size: 44px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 35px;
}
.rs-why-choose.style3 .features-wrap .desc {
  font-size: 19px;
  line-height: 27px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 45px;
}
/* ------------------------------------
    16. Team Section CSS
---------------------------------------*/
.rs-team {
  background: url(../images/bg/team-bg.png);
  background-position: center center;
  background-size: cover;
  background-color: #000060;
}
.rs-team .team-item-wrap .team-wrap {
  padding: 9px 35px 25px 20px;
  background: url(../images/team/ngbng.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.rs-team .team-item-wrap .team-wrap .image-inner {
  overflow: hidden;
  border-radius: 50%;
}
.rs-team .team-item-wrap .team-wrap .image-inner a img {
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.rs-team .team-item-wrap .team-content {
  padding: 20px 20px 20px;
}
.rs-team .team-item-wrap .team-content .person-name {
  font-size: 22px;
  font-weight: 600;
  line-height: 44px;
  margin-bottom: 5px;
}
.rs-team .team-item-wrap .team-content .person-name a {
  color: #ffffff;
}
.rs-team .team-item-wrap .team-content .designation {
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 15px;
}
.rs-team .team-item-wrap .team-content .team-social li {
  display: inline-block;
  margin-right: 10px;
}
.rs-team .team-item-wrap .team-content .team-social li a i {
  font-size: 15px;
  line-height: 14px;
  font-weight: 400;
  color: #ffffff;
}
.rs-team .team-item-wrap .team-content .team-social li:last-child {
  margin-right: 0;
}
.rs-team .team-item-wrap:hover .team-wrap .image-inner a img {
  transform: scale(1.3);
}
.rs-team .owl-dots {
  text-align: center;
}
.rs-team .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease;
  background: #0B70E1;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
}
.rs-team .owl-dots .owl-dot:hover,
.rs-team .owl-dots .owl-dot.active {
  width: 40px;
  background: #0B70E1;
  opacity: 1;
}
.rs-team .owl-stage-outer {
  padding: 10px;
  margin: -10px;
}
.rs-team.onepage-team {
  background: url(../images/bg/team-bg.png);
  background-color: #F6F7F9;
}
.rs-team.onepage-team .team-item-wrap .team-content {
  padding: 20px 20px 20px;
}
.rs-team.onepage-team .team-item-wrap .team-content .person-name a {
  color: #101010;
}
.rs-team.onepage-team .team-item-wrap .team-content .designation {
  color: #101010;
}
.rs-team.onepage-team .team-item-wrap .team-content .team-social li a i {
  color: #454545;
}
.rs-team.onepage-team.change-bg {
  background-color: #F5F5F5;
}
.rs-team.onepage-team.modify1 {
  background-color: #000000;
}
.rs-team.onepage-team.modify1 .team-item-wrap .team-content {
  padding: 20px 20px 20px;
}
.rs-team.onepage-team.modify1 .team-item-wrap .team-content .person-name a {
  color: #ffffff;
}
.rs-team.onepage-team.modify1 .team-item-wrap .team-content .designation {
  color: #ffffff;
}
.rs-team.onepage-team.modify1 .team-item-wrap .team-content .team-social li a i {
  color: #ffffff;
}
.rs-team.onepage-team.modify1 .owl-dots {
  text-align: center;
}
.rs-team.onepage-team.modify1 .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease;
  background: #0B70E1;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
}
.rs-team.onepage-team.modify1 .owl-dots .owl-dot:hover,
.rs-team.onepage-team.modify1 .owl-dots .owl-dot.active {
  width: 40px;
  background: #FD6509;
  opacity: 1;
}
.rs-team.onepage-style2 {
  border-radius: 270px 0px 0px 0px;
}
.rs-team.modify1 {
  background: unset;
}
.rs-team.modify1 .team-item-wrap .team-content .person-name a {
  color: #101010;
}
.rs-team.modify1 .team-item-wrap .team-content .designation {
  color: #101010;
}
.rs-team.modify1 .team-item-wrap .team-content .team-social li a i {
  color: #454545;
}
.rs-team.style2 {
  background: unset;
}
.rs-team.style2 .team-item .images-part {
  position: relative;
  overflow: hidden;
}
.rs-team.style2 .team-item .images-part .social-icon {
  position: absolute;
  bottom: 0;
  right: -50px;
  width: 50px;
  background: #03228f;
  padding: 12px 6px 12px;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  opacity: 0;
}
.rs-team.style2 .team-item .images-part .social-icon a i {
  font-size: 15px;
  line-height: 35px;
  width: 35px;
  height: 35px;
  color: #ffffff;
  background: #03228f;
  text-align: center;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
  margin: 0;
}
.rs-team.style2 .team-item .team-content {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: #FFFFFF;
  text-align: center;
  margin: 0;
  padding: 20px;
}
.rs-team.style2 .team-item .team-content .person-name {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 2px;
  text-transform: capitalize;
  line-height: 1.3;
}
.rs-team.style2 .team-item .team-content .person-name a {
  color: #0A0A0A;
}
.rs-team.style2 .team-item .team-content .person-name a:hover {
  color: #106eea;
}
.rs-team.style2 .team-item .team-content .designation {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #555555;
  display: block;
}
.rs-team.style2 .team-item:hover .images-part .social-icon {
  right: 0;
  width: 50px;
  opacity: 1;
  visibility: visible;
}
.rs-team.style2.onepage-style3 .team-item .images-part .social-icon {
  background: #F9780F;
}
.rs-team.style2.onepage-style3 .team-item .images-part .social-icon a i {
  background: #F9780F;
}
.rs-team.style2.onepage-style3 .team-item .team-content .person-name a:hover {
  color: #F9780F;
}
.rs-team.style2.onepage-style4 {
  background: #f5f6fe;
}
.rs-team.style2.onepage-style4 .owl-stage-outer {
  padding: unset;
  margin: unset;
}
.rs-team.style2.onepage-style4 .team-item .images-part img {
  border-radius: 5px 5px 0 0;
}
.rs-team.style2.onepage-style4 .team-item .team-content .person-name {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}
.rs-team.style2.onepage-style4 .team-item .team-content .person-name a:hover {
  color: #787cf2;
}
.rs-team.style2.onepage-style4 .team-item .team-content .designation {
  font-size: 15px;
  text-transform: capitalize;
}
.rs-team.style2.onepage-style4 .owl-dots {
  text-align: center;
}
.rs-team.style2.onepage-style4 .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease;
  background: #787cf2;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
}
.rs-team.style2.onepage-style4 .owl-dots .owl-dot:hover,
.rs-team.style2.onepage-style4 .owl-dots .owl-dot.active {
  width: 40px;
  background: #787cf2;
  opacity: 1;
}
.rs-team.home14-team-style {
  background: unset;
}
.rs-team.home14-team-style .team-top-section {
  padding: 0% 0% 0% 20.5%;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap {
  padding: 0 35px 0 15px;
  min-height: 500px;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap .images-wrap img {
  border-radius: 10px;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap .team-content {
  background-image: linear-gradient(110deg, #483afa 0%, #982ef5 100%);
  text-align: center;
  color: #fff;
  margin: -310px -20px 0px 20px;
  padding: 332px 35px 0 35px;
  border-radius: 10px;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap .team-content .title-name {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 0;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap .team-content .title-name a {
  color: #ffffff;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap .team-content .title-name a:hover {
  color: #ff7045;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap .team-content .team-title {
  color: #ffffff;
  display: block;
  font-weight: 500;
  margin: 0;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap .team-content .footer-social {
  margin-top: 20px;
  transform: scaleY(0);
  transition: .5s;
  height: 0;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap .team-content .footer-social li {
  display: inline;
  margin-right: 8px;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap .team-content .footer-social li a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  color: #ffffff;
  background: #7e52f8;
  text-align: center;
  font-size: 15px;
  transition: all .8s ease;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap .team-content .footer-social li a:hover {
  background: #ff7045;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap .team-content .footer-social li:last-child {
  margin-right: 0;
}
.rs-team.home14-team-style .team-top-section .team-item-wrap.slick-current:not(:hover) .team-content .footer-social,
.rs-team.home14-team-style .team-top-section .team-item-wrap:hover .team-content .footer-social {
  transform: scaleY(1);
  height: 68px;
}
.rs-team.home14-team-style .team-top-section .slick-arrow {
  position: absolute;
  font-size: 0;
  background-image: linear-gradient(90deg, #483afa 0%, #982ef5 100%);
  border: none;
  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  z-index: 1;
  top: -23%;
}
.rs-team.home14-team-style .team-top-section .slick-arrow:before {
  content: "\f115";
  font-family: Flaticon;
  font-size: 18px;
  font-weight: 400;
}
.rs-team.home14-team-style .team-top-section .slick-arrow.slick-prev {
  right: 27%;
}
.rs-team.home14-team-style .team-top-section .slick-arrow.slick-next {
  right: 22.5%;
}
.rs-team.home14-team-style .team-top-section .slick-arrow.slick-next:before {
  content: "\f114";
}
.rs-team-Single .btm-info-team {
  position: relative;
  box-shadow: 0px 0px 32px 0px rgba(7, 28, 31, 0.05);
  background: #fff;
  margin-bottom: 70px;
  padding: 40px;
}
.rs-team-Single .btm-info-team .images-part {
  padding: 0 30px 0 0;
}
.rs-team-Single .btm-info-team .images-part img {
  border-radius: 50%;
}
.rs-team-Single .btm-info-team .con-info {
  position: relative;
}
.rs-team-Single .btm-info-team .con-info .designation-info {
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
  color: #0B70E1;
  display: block;
  text-transform: uppercase;
}
.rs-team-Single .btm-info-team .con-info .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #101010;
  margin-bottom: 10px;
}
.rs-team-Single .btm-info-team .con-info .short-desc {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #454545;
  margin-bottom: 25px;
  padding-right: 50px;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .personal-info li {
  margin: 5px 0 10px;
  padding: 0;
  display: block;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .personal-info li span i:before {
  color: #0b70e1;
  margin-right: 18px;
  position: relative;
  top: 2px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .personal-info li a {
  color: #555;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .personal-info li a:hover {
  color: #106eea;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .social-info {
  margin-top: 15px;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .social-info li {
  display: inline-block;
  margin-right: 18px;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .social-info li a {
  color: #555;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .social-info li:last-child {
  margin-right: 0;
}
.rs-team-Single .btm-info-team .con-info:before {
  content: "\f10b";
  font-size: 250px;
  position: absolute;
  right: 130px;
  color: #0a0a0a;
  font-family: "Flaticon";
  height: 100%;
  top: 250px;
  opacity: 0.02;
}
/* ------------------------------------
    17. Services Section CSS
---------------------------------------*/
.rs-services.main-home .services-item {
  margin: 0px 0px 0px 0px;
  padding: 60px 25px 50px 35px;
  background-color: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
}
.rs-services.main-home .services-item .services-icon {
  margin-bottom: 30px;
}
.rs-services.main-home .services-item .services-icon .image-part img {
  width: 80px;
  height: 80px;
}
.rs-services.main-home .services-item .services-content .services-text .services-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
}
.rs-services.main-home .services-item .services-content .services-text .services-title a {
  color: #101010;
}
.rs-services.main-home .services-item .services-content .services-text .services-title a:hover {
  color: #106eea;
}
.rs-services.main-home .services-item .services-content .services-desc p {
  color: #444;
  margin: 0;
}
.rs-services.main-home.style1 {
  position: relative;
  z-index: 111;
  margin-top: -200px;
}
.rs-services.main-home.style1 .services-item {
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 48px 0px rgba(162, 162, 162, 0.5);
  margin: 0px -10px 0px 0px;
  padding: 40px;
}
.rs-services.main-home.style1 .services-item .services-icon {
  margin-bottom: 15px;
}
.rs-services.main-home.style1 .services-item .services-icon .image-part img {
  width: 80px;
  height: 80px;
}
.rs-services.main-home.style1 .services-item .services-content .services-text .services-title {
  margin-bottom: 5px;
}
.rs-services.main-home.style2 .services-item {
  padding: 65px 35px 35px 35px;
  margin: 0px -10px 0px 0px;
  text-align: center;
  border-radius: unset;
}
.rs-services.main-home.style2 .services-item .services-icon {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin: 0 auto 42px;
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0 28px 28px 0 rgba(108, 88, 250, 0.2);
  z-index: 1;
}
.rs-services.main-home.style2 .services-item .services-icon:before {
  position: absolute;
  content: '';
  right: -1px;
  bottom: -2px;
  width: 112px;
  height: 112px;
  border-radius: 100%;
  border: 1px solid;
  transition: all 0.3s ease 0s;
  z-index: 0;
  border-color: #1443C61C;
}
.rs-services.main-home.style2 .services-item .shape-part {
  position: absolute;
  top: 30px;
  right: 76px;
  opacity: 0;
  visibility: hidden;
}
.rs-services.main-home.style2 .services-item .services-content .services-text .services-title a:hover {
  color: #061340;
}
.rs-services.main-home.style2 .services-item.active {
  box-shadow: 3px 7px 25px 0px rgba(0, 0, 0, 0.05);
  background: #ffffff;
}
.rs-services.main-home.style2 .services-item:hover {
  box-shadow: 3px 7px 25px 0px rgba(0, 0, 0, 0.05);
  background: #ffffff;
}
.rs-services.main-home.style2 .services-item:hover .services-icon {
  position: relative;
}
.rs-services.main-home.style2 .services-item:hover .services-icon:before {
  display: none;
}
.rs-services.main-home.style2 .services-item:hover .shape-part {
  opacity: 1;
  visibility: visible;
}
.rs-services.main-home.style3 .services-item {
  padding: 50px 50px 50px 50px;
  background-color: #F3F7FF;
  border-radius: 10px 10px 10px 10px;
  box-shadow: unset;
  margin: 0px -10px 0px 0px;
}
.rs-services.main-home.style3 .services-item .services-content {
  padding: unset;
  position: unset;
}
.rs-services.main-home.style3 .services-item:hover {
  background-color: #FFFFFF;
}
.rs-services.main-home.style3 .services-item:hover .services-content {
  padding: unset;
  position: unset;
}
.rs-services.main-home.style3 .services-item:hover .services-content .services-desc p {
  color: #454545;
}
.rs-services .bg-section {
  background: #ffffff;
}
.rs-services .bg-section .shape-part .left-side {
  position: absolute;
  bottom: 0px;
}
.rs-services .bg-section .shape-part .right-side {
  position: absolute;
  bottom: 0px;
  right: 0;
}
.rs-services.style2 .flip-box-inner {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000px;
}
.rs-services.style2 .flip-box-inner .flip-box-wrap .front-part .front-content-part {
  background-color: #FFFFFF;
  text-align: center;
  padding: 50px 40px 50px 40px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 3px 7px 25px 0px rgba(0, 0, 0, 0.05);
}
.rs-services.style2 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-icon-part {
  margin-bottom: 15px;
}
.rs-services.style2 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-icon-part .icon-part img {
  width: 60px;
  height: 60px;
}
.rs-services.style2 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}
.rs-services.style2 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title a {
  color: #101010;
}
.rs-services.style2 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title a:hover {
  color: #106eea;
}
.rs-services.style2 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-desc-part p {
  color: #444;
  margin: 0;
}
.rs-services.style2 .flip-box-inner .flip-box-wrap .back-front {
  transform: rotateX(180deg);
  position: absolute;
  z-index: -1;
  padding: 30px;
  border-radius: 5px;
  background-color: #562dd4;
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  height: 300px;
  background-color: transparent;
  background-position: center;
  background-clip: padding-box;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275), -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
}
.rs-services.style2 .flip-box-inner .flip-box-wrap .back-front .back-front-content .back-title-part .back-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
.rs-services.style2 .flip-box-inner .flip-box-wrap .back-front .back-front-content .back-title-part .back-title a {
  color: #ffffff;
}
.rs-services.style2 .flip-box-inner:hover .flip-box-wrap .back-front {
  transform: rotateX(0);
  z-index: 1;
  background-image: linear-gradient(90deg, #1672de 0%, #071f6b 58%);
}
.rs-services.style2 .flip-box-inner:hover .flip-box-wrap .back-front .back-front-content .back-title-part .back-title {
  color: #ffffff;
}
.rs-services.style2 .flip-box-inner:hover .flip-box-wrap .back-front .back-front-content .back-desc-part .back-desc {
  color: #ffffff;
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part {
  height: 350px;
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-icon-part {
  margin-bottom: 15px;
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-icon-part .icon-part img {
  width: 60px;
  height: 60px;
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title a {
  color: #101010;
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-title-part .title a:hover {
  color: #106eea;
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part .front-desc-part p {
  color: #444;
  margin: 0;
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part.purple-bg {
  background: url(../images/services/main-home/icons/bg-box.png);
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part.gold-bg {
  background: url(../images/services/main-home/icons/bg-box-2.png);
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part.blue-bg {
  background: url(../images/services/main-home/icons/bg-box-3.png);
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part.purple-bg,
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part.gold-bg,
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .front-part .front-content-part.blue-bg {
  background-size: cover;
  background-position: center center;
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .back-front {
  transform: rotateX(180deg);
  position: absolute;
  z-index: -1;
  padding: 30px;
  border-radius: 5px;
  background-color: #562dd4;
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  height: 350px;
  background-color: transparent;
  background-position: center;
  background-clip: padding-box;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275), -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
}
.rs-services.style2.modify1 .flip-box-inner .flip-box-wrap .back-front .back-front-content .back-title-part .back-title {
  color: #ffffff;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
.rs-services.style2.modify1 .flip-box-inner:hover .flip-box-wrap .back-front {
  transform: rotateX(0);
  z-index: 1;
  background-image: linear-gradient(90deg, #1672de 0%, #071f6b 58%);
}
.rs-services.style2.modify1 .flip-box-inner:hover .flip-box-wrap .back-front.purple-bg {
  background: #7218f9;
}
.rs-services.style2.modify1 .flip-box-inner:hover .flip-box-wrap .back-front.gold-bg {
  background: #fc821b;
}
.rs-services.style3 .services-item {
  padding: 50px 28px 50px 28px;
  background-color: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 50px 11px rgba(95, 138, 236, 0.05);
  position: relative;
  text-align: left;
}
.rs-services.style3 .services-item .services-icon {
  position: relative;
  transition: all .3s ease;
}
.rs-services.style3 .services-item .services-icon .image-part .main-img {
  height: 70px;
  width: 70px;
}
.rs-services.style3 .services-item .services-icon .image-part .hover-img {
  height: 70px;
  width: 70px;
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  transition: all .3s ease;
}
.rs-services.style3 .services-item .services-content {
  padding: 33px 0px 0px 0;
  position: relative;
}
.rs-services.style3 .services-item .services-content .services-text .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 13px;
}
.rs-services.style3 .services-item .services-content .services-text .title a {
  color: #1c1b1b;
}
.rs-services.style3 .services-item .services-content .services-desc p {
  margin: 0;
}
.rs-services.style3 .services-item .services-content .serial-number {
  position: absolute;
  right: 15px;
  top: -113px;
  font-size: 70px;
  font-weight: 600;
  line-height: 119px;
  color: #02010108;
  margin-bottom: 10px;
}
.rs-services.style3 .services-item:hover {
  background-color: #FD660A;
}
.rs-services.style3 .services-item:hover.pink-bg {
  background: #F30876;
}
.rs-services.style3 .services-item:hover.aqua-bg {
  background: #05DBEE;
}
.rs-services.style3 .services-item:hover.paste-bg {
  background: #13E1AF;
}
.rs-services.style3 .services-item:hover.purple-bg {
  background: #780FDA;
}
.rs-services.style3 .services-item:hover.green-bg {
  background: #0A99FD;
}
.rs-services.style3 .services-item:hover.blue-bg {
  background: #2c44e4;
}
.rs-services.style3 .services-item:hover.gold-bg {
  background: #f5be18;
}
.rs-services.style3 .services-item:hover.cyan-bg {
  background: #14d2f5;
}
.rs-services.style3 .services-item:hover.orange-bg {
  background: #FD660A;
}
.rs-services.style3 .services-item:hover.light-purple-bg {
  background-image: linear-gradient(90deg, #6e71db 41%, #9c9ff9 100%);
}
.rs-services.style3 .services-item:hover .services-icon {
  position: relative;
  transition: all .3s ease;
}
.rs-services.style3 .services-item:hover .services-icon .image-part .main-img {
  transform: scale(0);
}
.rs-services.style3 .services-item:hover .services-icon .image-part .hover-img {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.rs-services.style3 .services-item:hover .services-content {
  padding: 33px 0px 0px 0;
  position: relative;
}
.rs-services.style3 .services-item:hover .services-content .services-text .title a {
  color: #ffffff;
}
.rs-services.style3 .services-item:hover .services-content .services-desc p {
  color: #ffffff;
}
.rs-services.style3 .services-item:hover .services-content .serial-number {
  color: #FFFFFF26;
}
.rs-services.style3 .services-item.services-item {
  padding: 50px 36px 50px 36px;
}
.rs-services.style3.modify1 .services-item {
  text-align: center;
}
.rs-services.style3.modify1 .services-item .services-icon .image-part .hover-img {
  left: 50%;
  transform: scale(0) translateX(-50%);
}
.rs-services.style3.modify1 .services-item:hover.pink-light-bg {
  background: #f072d6;
}
.rs-services.style3.modify1 .services-item:hover.blue2-bg {
  background: #4790f2;
}
.rs-services.style3.modify1 .services-item:hover.paste2-bg {
  background: #67c565;
}
.rs-services.style3.modify1 .services-item:hover.purple2-bg {
  background: #626ff3;
}
.rs-services.style3.modify1 .services-item:hover.cyan2-bg {
  background: #4ccbe6;
}
.rs-services.style3.modify1 .services-item:hover.pink2-bg {
  background: #e65599;
}
.rs-services.style3.modify1 .services-item:hover .services-icon .image-part .main-img {
  transform: scale(0);
}
.rs-services.style3.modify1 .services-item:hover .services-icon .image-part .hover-img {
  visibility: visible;
  opacity: 1;
  transform: scale(1) translateX(-50%);
}
.rs-services.style3.modify1 .services-item:hover .services-content {
  padding: 33px 0px 0px 15px;
  position: relative;
}
.rs-services.style3.modify1 .services-item:hover .services-content .services-button a {
  color: #ffffff;
}
.rs-services.style3.modify1 .services-item:hover .services-content .services-button a:after {
  color: #ffffff;
}
.rs-services.style3.modify1 .services-item.services-item {
  padding: 50px 25px 50px 25px;
}
.rs-services.style3.modify2 .services-item {
  display: flex;
  margin: 0px -13px 0px 0px;
  padding: 35px 37px 35px!important;
  background-color: #fff;
  box-shadow: 0 0 30px #eee;
  border-radius: 3px 3px 3px 3px;
}
.rs-services.style3.modify2 .services-item .services-icon .image-part .main-img {
  max-width: unset;
}
.rs-services.style3.modify2 .services-item .services-content {
  padding: 0px 0px 0px 20px;
}
.rs-services.style3.modify2 .services-item:hover {
  background-color: #FD660A;
}
.rs-services.style3.modify2 .services-item:hover.pink-bg {
  background: #F30876;
}
.rs-services.style3.modify2 .services-item:hover.aqua-bg {
  background: #05DBEE;
}
.rs-services.style3.modify2 .services-item:hover.paste-bg {
  background: #13E1AF;
}
.rs-services.style3.modify2 .services-item:hover.purple-bg {
  background: #780FDA;
}
.rs-services.style3.modify2 .services-item:hover.green-bg {
  background: #0A99FD;
}
.rs-services.style3.modify2 .services-item:hover.blue-bg {
  background: #2c44e4;
}
.rs-services.style3.modify2 .services-item:hover.gold-bg {
  background: #f5be18;
}
.rs-services.style3.modify2 .services-item:hover.cyan-bg {
  background: #14d2f5;
}
.rs-services.style3.modify2 .services-item:hover .services-content {
  padding: 0px 0px 0px 20px;
}
.rs-services.style3.modify3 .services-item {
  background-color: #131313;
  box-shadow: unset;
}
.rs-services.style3.modify3 .services-item .services-content .services-text .title a {
  color: #ffffff;
}
.rs-services.style3.modify3 .services-item .services-content .services-desc p {
  color: #ffffff;
}
.rs-services.style3.modify3 .services-item .services-content .serial-number {
  color: #FFFFFF08;
}
.rs-services.style4 .services-item {
  text-align: center;
  padding: 50px 40px 50px 40px;
}
.rs-services.style4 .services-item .services-icon {
  margin-bottom: 20px;
}
.rs-services.style4 .services-item .services-icon img {
  width: 50px;
  height: 50px;
}
.rs-services.style4 .services-item .services-content .title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}
.rs-services.style4 .services-item .services-content .title a {
  color: #102B3E;
}
.rs-services.style4 .services-item .services-content .title a:hover {
  color: #106eea;
}
.rs-services.style4 .services-item .services-content .desc {
  margin-bottom: 15px;
}
.rs-services.style4 .hover-effect:hover .services-item:hover,
.rs-services.style4 .hover-effect:not(:hover) .active {
  background: #ffffff;
  box-shadow: 3px 7px 25px 0px rgba(0, 0, 0, 0.05);
}
.rs-services.style4.modify1 .services-item {
  text-align: left;
  display: flex;
  padding: 40px 30px 35px 30px;
}
.rs-services.style4.modify1 .services-item .services-icon {
  padding: 8px 0px 0px 0px;
  font-size: 50px;
  line-height: 50px;
}
.rs-services.style4.modify1 .services-item .services-icon img {
  max-width: unset;
}
.rs-services.style4.modify1 .services-item .services-content {
  padding: 0px 0px 0px 15px;
}
.rs-services.style4.modify1 .services-item .services-content .title a {
  color: #102B3E;
}
.rs-services.style4.modify1 .services-item .services-content .title a:hover {
  color: #032390;
}
.rs-services.style4.modify1.services3 .services-item {
  padding: 50px 25px 33px 25px;
  background-color: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  margin: 0 -10px 0 0;
}
.rs-services.style5 .services-item {
  padding: 25px 25px 25px 25px;
  background-color: #F1F6FC;
  border-radius: 4px 4px 4px 4px;
  margin: 0 -12px 0 0;
  display: flex;
}
.rs-services.style5 .services-item .services-icon {
  padding: 5px 5px 0px 0px;
}
.rs-services.style5 .services-item .services-icon img {
  height: 65px;
  width: 65px;
  max-width: unset;
}
.rs-services.style5 .services-item .services-content {
  padding: 0px 0px 0px 15px;
}
.rs-services.style5 .services-item .services-content .services-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
}
.rs-services.style5 .services-item .services-content .services-title a {
  color: #101010;
}
.rs-services.style5 .services-item .services-content .services-title a:hover {
  color: #106eea;
}
.rs-services.style5 .services-item .services-content .services-desc {
  color: #444444;
  margin-bottom: 0px;
}
.rs-services.style6 .services-item {
  padding: 0px 44px 0px;
  text-align: center;
}
.rs-services.style6 .services-item .services-icon {
  padding-bottom: 35px;
}
.rs-services.style6 .services-item .services-icon img {
  transition: all ease .3s;
}
.rs-services.style6 .services-item .services-content .services-title .title {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  margin: 0px 20px 8px 20px;
}
.rs-services.style6 .services-item .services-content .services-title .title a {
  color: #102B3E;
}
.rs-services.style6 .services-item .services-content .services-title .title a:hover {
  color: #106eea;
}
.rs-services.style6 .services-item:hover .services-icon img {
  transform: scale(1.1) rotate(4deg);
}
.rs-services.style7 .services-item .services-img {
  overflow: hidden;
  border-radius: 5px;
}
.rs-services.style7 .services-item .services-img a img {
  transition: all .8s ease;
  transform: scale(1);
}
.rs-services.style7 .services-item .services-content {
  padding-top: 25px;
}
.rs-services.style7 .services-item .services-content .services-title .title {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  margin: 0 0 10px 0;
}
.rs-services.style7 .services-item .services-content .services-title .title a {
  color: #102b3e;
}
.rs-services.style7 .services-item .services-content .services-title .title a:hover {
  color: #f2541b;
}
.rs-services.style7 .services-item .services-content .services-txt {
  margin-bottom: 20px;
  padding-right: 34px;
}
.rs-services.style7 .services-item:hover .services-img a img {
  transform: scale(1.1);
}
.rs-services.style7 .animation-layer {
  position: relative;
}
.rs-services.style7 .animation-layer .shape-layer {
  position: absolute;
  right: -6%;
  bottom: -58px;
}
.rs-services.style8 .services-item {
  padding: 67px 35px 65px 35px;
  background-color: #E0F0FD;
  border-radius: 0px 0px 0px 0px;
}
.rs-services.style8 .services-item .services-img {
  margin-bottom: 25px;
}
.rs-services.style8 .services-item .services-img img {
  height: 80px;
  width: 80px;
  max-width: unset;
}
.rs-services.style8 .services-item .services-content .services-title .title {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  color: #0A0A0A;
  margin-bottom: 15px;
}
.rs-services.style8 .services-item .services-content .services-title .title:hover {
  color: #787CF2;
}
.rs-services.style8 .services-item .services-content .services-txt {
  color: #444444;
  margin: 0;
}
.rs-services.style8 .services-item.gray-light-bg {
  background-color: #D9F3F6;
}
.rs-services.style8 .services-item.pink-bg {
  background-color: #F0E4FB;
}
.rs-services.style9 .services-top-section {
  margin-top: -525px;
  padding: 520px 0px 110px 0px;
  position: relative;
}
.rs-services.style9 .services-top-section .services-item {
  padding: 15px;
}
.rs-services.style9 .services-top-section .services-item .image-part img {
  border-radius: 10px 10px 10px 10px;
}
.rs-services.style9 .services-top-section .services-item .addon-services {
  margin: -220px -20px 0px 20px;
  padding: 265px 35px 20px 35px;
  background-color: #6A40F9;
  border-radius: 10px 10px 10px 10px;
}
.rs-services.style9 .services-top-section .services-item .addon-services .services-icon {
  margin-bottom: 22px;
}
.rs-services.style9 .services-top-section .services-item .addon-services .services-icon img {
  max-width: unset;
  width: 50px;
  height: 50px;
}
.rs-services.style9 .services-top-section .services-item .addon-services .services-text .title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  margin: 0 0 10px;
}
.rs-services.style9 .services-top-section .services-item .addon-services .services-text .title a {
  color: #ffffff;
}
.rs-services.style9 .services-top-section .services-item .addon-services .services-text .title a:hover {
  color: #ff7045;
}
.rs-services.style9 .services-top-section .services-item .addon-services:hover {
  background-image: linear-gradient(180deg, #343dfb 0%, #932ff5 100%);
}
.rs-services .shape-animation {
  position: relative;
}
.rs-services .shape-animation .shape-part {
  position: absolute;
  left: -50px;
  bottom: 150px;
}

.rs-services-single .services-add .title {
  font-size: 24px;
  line-height: 42px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}
.rs-services-single .services-add .contact a {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #ffffff;
}
.rs-services-single .brochures {
  background-color: #F0F5FF;
  padding: 35px 30px 45px 30px;
  border-radius: 5px 5px 5px 5px;
}
.rs-case-studies-single .services-add {
  background-image: linear-gradient(250deg, #0b70e1 19%, #03228f 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  border-radius: 5px;
  padding: 50px 35px 53px;
}
.rs-case-studies-single .services-add .title {
  font-size: 24px;
  line-height: 42px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}
.rs-case-studies-single .services-add .contact a {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #ffffff;
}
.rs-case-studies-single .brochures {
  background-color: #F0F5FF;
  padding: 35px 30px 45px 30px;
  border-radius: 5px 5px 5px 5px;
}
.rs-case-studies-single .services-img img {
  border-radius: 5px;
}
.rs-case-studies-single .ps-informations ul {
  margin: 0;
  list-style: none;
  text-align: left;
  padding: 0;
}
.rs-case-studies-single .ps-informations ul li {
  color: #fff;
}
.rs-case-studies-single .ps-informations ul li + li {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid rgba(54, 54, 54, 0.1);
}
.rs-case-studies-single .ps-informations ul li span {
  width: 150px;
  display: inline-block;
  font-weight: 600;
}
.rs-case-studies-single .ps-informations {
  padding: 40px 30px 46px;
  border-radius: 5px;
  background-image: linear-gradient(250deg, #0b70e1 19%, #03228f 100%);
}
.rs-case-studies-single .ps-informations .info-title {
  padding-bottom: 10px;
  color: #fff;
}
/* ------------------------------------
    18. Process Section CSS
---------------------------------------*/
.rs-process .rs-addon-number .number-text .number-area {
  display: block;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.4;
  color: #03228f;
}
.rs-process .rs-addon-number .number-text .number-title .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: #101010;
  margin-bottom: 10px;
}
.rs-process .rs-addon-number .number-text .number-txt {
  margin: 0;
  padding-right: 34px;
}
.rs-process.modify1 {
  background-image: linear-gradient(140deg, #03228f 62%, #0e73e4 100%);
  border-radius: 270px 0px 0px 0px;
}
.rs-process.modify1 .rs-addon-number .number-text {
  margin: 0px 25px 0px 30px;
  padding: 0px 48px 11px 48px;
  background-color: #182AEE45;
  border-radius: 10px 10px 50px 10px;
  position: relative;
}
.rs-process.modify1 .rs-addon-number .number-text .number-area {
  font-size: 41px;
  font-weight: 600;
  line-height: 62px;
  position: relative;
  color: #ffffff;
  display: inline-block;
  width: 70px;
  height: 70px;
  left: -70px;
  top: -30px;
  text-align: center;
  background: #06D3E5;
  transition: all 0.3s ease 0s;
  border-radius: 50%;
}
.rs-process.modify1 .rs-addon-number .number-text .number-area.green-bg {
  background: #3BCD4C;
}
.rs-process.modify1 .rs-addon-number .number-text .number-area.plum-bg {
  background: #9439E9;
}
.rs-process.modify1 .rs-addon-number .number-text .number-area.pink-bg {
  background: #f30876;
}
.rs-process.modify1 .rs-addon-number .number-text .number-title .title {
  color: #ffffff;
  margin-bottom: 15px;
}
.rs-process.modify1 .rs-addon-number .number-text .number-txt {
  color: #ffffff;
  margin-bottom: 40px;
  padding-right: 0;
}
.rs-process.style2 .addon-process .process-wrap .process-img {
  padding: 10px 10px 10px 10px;
}
.rs-process.style2 .addon-process .process-wrap .process-img img {
  width: 100%;
  height: auto;
}
.rs-process.style2 .addon-process .process-wrap .process-text {
  text-align: center;
  padding: 15px 0 0;
}
.rs-process.style2 .addon-process .process-wrap .process-text .title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #0a0a0a;
  margin-bottom: 0;
}
.rs-process.style2 .addon-process .process-wrap .process-text .title:hover {
  color: #061340;
}
.rs-process.style3 .rs-addon-number {
  margin: 0px -10px 0px 0px;
  padding: 37px 30px 37px 38px;
  background-color: #FFFFFF;
  border-bottom: 7px solid #095fd0;
}
.rs-process.style4 .rs-addon-number {
  margin: 0px -10px 0px 0px;
  padding: unset;
  background-color: unset;
}
.rs-process.style4 .rs-addon-number .number-text .number-area {
  display: inline-block;
  width: 50px;
  line-height: 50px;
  z-index: 1;
  position: relative;
  transition: all 0.3s ease 0s;
  text-align: center;
  width: 64px;
  height: 54px;
  top: -20px;
}
.rs-process.style4 .rs-addon-number .number-text .number-area:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: -15px;
  left: 0;
  z-index: -1;
  transform: rotate(45deg);
  transition: all 0.3s ease 0s;
  border-radius: 50px 50px 50px 50px;
  background: #A0B3F8;
}
.rs-process.style5 .rs-addon-number .number-text .number-area {
  color: #ffffff;
}
.rs-process.style5 .rs-addon-number .number-text .number-title .title {
  color: #ffffff;
}
.rs-process.style5 .rs-addon-number .number-text .number-txt {
  color: #ffffff;
}
.rs-process.style6 .tab-area .nav {
  border-bottom: unset;
}
.rs-process.style6 .tab-area .nav li .tab-item {
  padding: 20px 30px;
  display: block;
  position: relative;
  transition: all ease .6s;
}
.rs-process.style6 .tab-area .nav li .tab-item h3 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #102b3e;
  margin-bottom: 8px;
  text-decoration: underline;
}
.rs-process.style6 .tab-area .nav li .tab-item p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #101010;
  height: 0;
  opacity: 0;
  margin: 0;
  transition: all ease .4s;
}
.rs-process.style6 .tab-area .nav li .tab-item.active {
  background: #f8f6ff;
}
.rs-process.style6 .tab-area .nav li .tab-item.active h3 {
  color: #f2541b;
}
.rs-process.style6 .tab-area .nav li .tab-item.active p {
  height: auto;
  opacity: 1;
  transition: all ease .8s;
}
.rs-process .shape-animation {
  position: relative;
}
.rs-process .shape-animation .shape-process {
  position: absolute;
  left: 180px;
  top: 50px;
}
/*------------------------------------
    19. Pricing Section CSS
------------------------------------*/
.rs-pricing {
  margin-top: -150px;
  position: relative;
}
.rs-pricing .pricing-table {
  padding: 27px 40px 0px 40px;
  box-shadow: 0 0 30px #eee;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  position: relative;
  text-align: center;
  margin: 0px 7px 0px 0px;
}
.rs-pricing .pricing-table .pricing-badge {
  top: 30px;
  left: 0px;
  padding: 10px 23px 11px 23px;
  background-color: #1273EB;
  border-radius: 0px 30px 30px 0px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  color: #fff;
  line-height: 1;
  z-index: 1;
}
.rs-pricing .pricing-table .pricing-badge.white-color-bg {
  background: #ffffff;
  color: #106eea;
}
.rs-pricing .pricing-table .pricing-badge.secondary-bg {
  background: #03228f;
}
.rs-pricing .pricing-table .pricing-icon {
  margin-bottom: 60px;
}
.rs-pricing .pricing-table .pricing-icon img {
  width: 110px;
}
.rs-pricing .pricing-table .pricing-table-price {
  margin-bottom: 30px;
}
.rs-pricing .pricing-table .pricing-table-price .pricing-table-bags .pricing-currency {
  font-size: 25px;
  font-weight: 600;
  color: #101010;
}
.rs-pricing .pricing-table .pricing-table-price .pricing-table-bags .table-price-text {
  font-size: 52px;
  font-weight: 600;
  color: #101010;
}
.rs-pricing .pricing-table .pricing-table-price .pricing-table-bags .table-period {
  color: #435761;
  font-size: 16px;
  font-weight: 500;
  display: block;
}
.rs-pricing .pricing-table .pricing-table-body {
  margin-bottom: 10px;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #EBEBEB;
  padding: 18px 15px 4px 15px;
}
.rs-pricing .pricing-table .pricing-table-body li {
  text-align: left;
  padding: 9px 0px 9px 0px;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 400;
}
.rs-pricing .pricing-table .pricing-table-body li i {
  float: right;
  margin-right: 0;
  color: #106eea;
}
.rs-pricing .pricing-table.primary-bg {
  background-image: linear-gradient(70deg, #0b70e1 0%, #03228f 100%);
}
.rs-pricing .pricing-table.primary-bg .pricing-table-price .pricing-table-bags .pricing-currency {
  color: #ffffff;
}
.rs-pricing .pricing-table.primary-bg .pricing-table-price .pricing-table-bags .table-price-text {
  color: #ffffff;
}
.rs-pricing .pricing-table.primary-bg .pricing-table-price .pricing-table-bags .table-period {
  color: #ffffff;
}
.rs-pricing .pricing-table.primary-bg .pricing-table-body {
  border-color: #1F71C1;
}
.rs-pricing .pricing-table.primary-bg .pricing-table-body li {
  color: #ffffff;
}
.rs-pricing .pricing-table.primary-bg .pricing-table-body li i {
  color: #ffffff;
}
.rs-pricing .pricing-table.light-gray-bg {
  background-color: #F3F7FF;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #EBEBEB;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
}
.rs-pricing .pricing-table.light-gray-bg .pricing-table-price .pricing-table-bags .pricing-currency {
  color: #03228f;
}
.rs-pricing .pricing-table.light-gray-bg .pricing-table-price .pricing-table-bags .table-price-text {
  color: #03228f;
}
.rs-pricing .pricing-table.light-gray-bg .pricing-table-body li i {
  color: #101010;
}
.rs-pricing .pricing-table.new-style {
  box-shadow: unset;
  background: unset;
  border-style: solid;
  border-color: #EBEBEB;
  border-width: 1px 1px 1px 1px;
}
.rs-pricing .pricing-table.new-style2 {
  background: unset;
  border-style: solid;
  border-color: #EBEBEB;
  border-width: 1px 1px 1px 1px;
}
.rs-pricing.style2 {
  margin-top: unset;
  position: unset;
}
.rs-pricing.style2.modify1 .pricing-table .pricing-table-body li i {
  color: #03228f;
}
.rs-pricing.style2.modify1 {
  background-color: unset;
}
/*------------------------------------
    20. Technology Section CSS
------------------------------------*/
.rs-technology .technology-item {
  text-align: center;
  padding: 20px 40px 20px 40px;
}
.rs-technology .technology-item a {
  overflow: hidden;
}
.rs-technology.style2 .technology-wrap .rs-grid-figure {
  position: relative;
  text-align: center;
  padding: 45px 30px 45px 30px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #a7acb4;
  border-radius: 4px 4px 4px 4px;
  transition: all ease .4s;
}
.rs-technology.style2 .technology-wrap .rs-grid-figure .logo-img {
  overflow: hidden;
}
.rs-technology.style2 .technology-wrap .rs-grid-figure .logo-img a {
  overflow: hidden;
  display: inline-block;
  position: relative;
}
.rs-technology.style2 .technology-wrap .rs-grid-figure .logo-img a .hover-img {
  transition: all ease .4s;
  filter: grayscale(100%);
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  visibility: hidden;
}
.rs-technology.style2 .technology-wrap .rs-grid-figure .logo-img a .main-img {
  transition: all ease .4s;
  filter: grayscale(100%);
}
.rs-technology.style2 .technology-wrap .logo-title {
  position: relative;
  text-align: center;
  padding: 30px 0px 0px 0px;
}
.rs-technology.style2 .technology-wrap .logo-title .title {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  margin: 36px 0px 0px 0px;
}
.rs-technology.style2 .technology-wrap .logo-title:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  height: 40px;
  width: 1px;
  transform: translateX(-50%);
  background-color: #a7acb4;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.rs-technology.style2 .technology-wrap .logo-title:after {
  content: '';
  position: absolute;
  top: 40px;
  left: 50.3%;
  height: 12px;
  border-radius: 50%;
  width: 12px;
  transform: translateX(-50%);
  background-color: #a7acb4;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.rs-technology.style2 .technology-wrap:hover .rs-grid-figure {
  border-color: #fff;
  background-color: #fff;
}
.rs-technology.style2 .technology-wrap:hover .rs-grid-figure .logo-img a .hover-img {
  display: block;
  opacity: 3;
  visibility: visible;
  transform: translateY(0%);
  filter: grayscale(0%);
}
.rs-technology.style2 .technology-wrap:hover .rs-grid-figure .logo-img a .main-img {
  display: block;
  visibility: hidden;
  transform: translateY(100%);
}
.rs-technology.style2 .technology-wrap:hover .logo-title:before {
  background-color: #fff;
}
.rs-technology.style2 .technology-wrap:hover .logo-title:after {
  background-color: #fff;
}
/*------------------------------------
    21. Industry Section CSS
------------------------------------*/
.rs-industry .all-services {
  display: inline-block;
}
.rs-industry .all-services .services-item {
  padding: 10px;
  width: 20%;
  float: left;
}
.rs-industry .all-services .services-item .services-wrap {
  padding: 35px 27px 35px 27px;
  background: #ffffff;
  box-shadow: 0px 0px 50px 2px rgba(0, 0, 0, 0.03);
  text-align: center;
}
.rs-industry .all-services .services-item .services-wrap .services-icon {
  margin-bottom: 15px;
}
.rs-industry .all-services .services-item .services-wrap .services-icon img {
  height: 65px;
  width: 65px;
}
.rs-industry .all-services .services-item .services-wrap .services-text .title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #0a0a0a;
  margin-bottom: 0;
}
.rs-industry .all-services .services-item .services-wrap .services-text .title:hover {
  color: #061340;
}
/*------------------------------------
    22. Project Section CSS
------------------------------------*/
.rs-project.style1 .owl-stage-outer {
  padding-bottom: 70px;
}
.rs-project.style1 .project-item {
  position: relative;
  overflow: visible;
}
.rs-project.style1 .project-item .project-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.rs-project.style1 .project-item .project-img img {
  width: 100%;
  transition: 1.3s all ease;
  border-radius: 5px;
}
.rs-project.style1 .project-item .project-content {
  background: #fff;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
  position: absolute;
  bottom: -60px;
  left: 35px;
  right: 35px;
  padding: 34px 0;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  z-index: 10;
  text-align: center;
}
.rs-project.style1 .project-item .project-content .title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 8px;
}
.rs-project.style1 .project-item .project-content .title a {
  color: #101010;
}
.rs-project.style1 .project-item .project-content .category {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  display: block;
}
.rs-project.style1 .project-item .project-content .category a {
  color: #106eea;
}
.rs-project.style1 .project-item:hover .project-img img {
  transform: scale(1.3);
}
.rs-project.style1 .project-item:hover .project-content {
  bottom: -50px;
}
.rs-project.style1 .owl-nav .owl-next,
.rs-project.style1 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  background: #ffffff;
  text-align: center;
  color: #101010;
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-project.style1 .owl-nav .owl-next i:before,
.rs-project.style1 .owl-nav .owl-prev i:before {
  content: "\f111";
  font-family: Flaticon;
}
.rs-project.style1 .owl-nav .owl-next:hover,
.rs-project.style1 .owl-nav .owl-prev:hover {
  color: #106eea;
}
.rs-project.style1 .owl-nav .owl-next {
  right: -50px;
  left: unset;
}
.rs-project.style1 .owl-nav .owl-next i:before {
  content: "\f110";
}
.rs-project.style1:hover .owl-nav .owl-next,
.rs-project.style1:hover .owl-nav .owl-prev {
  left: -50px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-project.style1:hover .owl-nav .owl-next {
  right: -50px;
  left: unset;
}
.rs-project.style2 .project-item {
  position: relative;
  overflow: hidden;
}
.rs-project.style2 .project-item .project-img img {
  width: 100%;
  border-radius: 5px;
}
.rs-project.style2 .project-item .project-content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 40%;
  text-align: center;
  z-index: 10;
  padding: 25px;
  opacity: 0;
  -webkit-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
  border-radius: 5px;
  z-index: 1;
}
.rs-project.style2 .project-item .project-content .title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 8px;
}
.rs-project.style2 .project-item .project-content .title a {
  color: #ffffff;
}
.rs-project.style2 .project-item .project-content .category {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  display: block;
}
.rs-project.style2 .project-item .project-content .category a {
  color: #ffffff;
}
.rs-project.style2 .project-item .project-content:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease 0s;
  border-radius: 5px;
}
.rs-project.style2 .project-item:hover .project-content {
  top: 0;
  opacity: 1;
  z-index: 1;
}
.rs-project.style2 .owl-nav .owl-next,
.rs-project.style2 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  background: #ffffff;
  text-align: center;
  color: #101010;
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-project.style2 .owl-nav .owl-next i:before,
.rs-project.style2 .owl-nav .owl-prev i:before {
  content: "\f111";
  font-family: Flaticon;
}
.rs-project.style2 .owl-nav .owl-next:hover,
.rs-project.style2 .owl-nav .owl-prev:hover {
  color: #106eea;
}
.rs-project.style2 .owl-nav .owl-next {
  right: -50px;
  left: unset;
}
.rs-project.style2 .owl-nav .owl-next i:before {
  content: "\f110";
}
.rs-project.style2:hover .owl-nav .owl-next,
.rs-project.style2:hover .owl-nav .owl-prev {
  left: -50px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-project.style2:hover .owl-nav .owl-next {
  right: -50px;
  left: unset;
}
.rs-project.style3 {
  background-image: linear-gradient(150deg, #03228f 46%, #0e73e4 100%);
  border-radius: 0px 270px 0px 0px;
  padding: 110px 70px 120px 70px;
}
.rs-project.style3 .project-item {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 5px;
}
.rs-project.style3 .project-item .project-img a img {
  transition: 1.3s all ease;
  border-radius: 5px;
}
.rs-project.style3 .project-item .project-content {
  position: absolute;
  padding: 24px 30px;
  opacity: 1;
  z-index: 10;
  top: 0;
  border: none;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
  width: 100%;
}
.rs-project.style3 .project-item .project-content .portfolio-inner {
  position: absolute;
  bottom: 30px;
  right: 40px;
  left: 40px;
  display: flex;
  flex-wrap: wrap;
  transition: all .5s ease;
  width: 100%;
}
.rs-project.style3 .project-item .project-content .portfolio-inner .title {
  margin-top: 8px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 8px;
  transform: translateY(0);
  order: 2;
  flex: 0 0 100%;
}
.rs-project.style3 .project-item .project-content .portfolio-inner .title a {
  color: #ffffff;
}
.rs-project.style3 .project-item .project-content .portfolio-inner .category {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  display: block;
  flex: 0 0 100%;
  transform: translateY(0px);
}
.rs-project.style3 .project-item .project-content .portfolio-inner .category a {
  color: #ffffff;
}
.rs-project.style3 .project-item .project-content:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all .3s ease 0s;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.rs-project.style3 .project-item:hover .project-img a img {
  transform: scale(1.2);
}
.rs-project.style3 .project-item:hover .project-content {
  opacity: 1;
}
.rs-project.style3 .project-item:hover .project-content .portfolio-inner {
  bottom: 50px;
}
.rs-project.style3 .owl-nav .owl-next,
.rs-project.style3 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  background: #ffffff;
  text-align: center;
  color: #101010;
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-project.style3 .owl-nav .owl-next i:before,
.rs-project.style3 .owl-nav .owl-prev i:before {
  content: "\f111";
  font-family: Flaticon;
}
.rs-project.style3 .owl-nav .owl-next:hover,
.rs-project.style3 .owl-nav .owl-prev:hover {
  color: #106eea;
}
.rs-project.style3 .owl-nav .owl-next {
  right: -50px;
  left: unset;
}
.rs-project.style3 .owl-nav .owl-next i:before {
  content: "\f110";
}
.rs-project.style3:hover .owl-nav .owl-next,
.rs-project.style3:hover .owl-nav .owl-prev {
  left: -50px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-project.style3:hover .owl-nav .owl-next {
  right: -50px;
  left: unset;
}
.rs-project.style3.modify1 {
  background-image: unset;
  border-radius: unset;
  padding: 100px 0;
  margin-left: -80px;
}
.rs-project.style3.modify1.mod {
  padding: 120px 0;
}
.rs-project.style3.modify1.mod .project-item .project-img a img {
  width: 380px;
  max-width: 380px;
}
.rs-project.style3.modify1 .owl-nav .owl-next,
.rs-project.style3.modify1 .owl-nav .owl-prev {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  background: unset;
  color: #fff;
  border: 1px solid #fff;
  transform: translateY(-50%);
  transition: 0.6s ease all;
  z-index: 9;
  text-align: center;
  visibility: visible;
  opacity: 1;
}
.rs-project.style3.modify1 .owl-nav .owl-next i:before,
.rs-project.style3.modify1 .owl-nav .owl-prev i:before {
  content: "\f111";
  font-family: Flaticon;
  font-size: 18px;
  font-weight: 400;
}
.rs-project.style3.modify1 .owl-nav .owl-next:hover,
.rs-project.style3.modify1 .owl-nav .owl-prev:hover {
  color: #ccc;
}
.rs-project.style3.modify1 .owl-nav .owl-next {
  top: 350px;
  right: 1704px;
}
.rs-project.style3.modify1 .owl-nav .owl-next i:before {
  content: "\f110";
}
.rs-project.style3.modify1 .owl-nav .owl-prev {
  left: -470px;
  top: 350px;
}
.rs-project.style3.modify1.case-style3 {
  margin-left: unset;
  padding: 120px 0;
}
.rs-project.style4 .project-item {
  position: relative;
  overflow: hidden;
}
.rs-project.style4 .project-item .project-img img {
  border-radius: 5px;
}
.rs-project.style4 .project-item .project-content {
  position: absolute;
  padding: 30px 20px 30px;
  opacity: 0;
  z-index: 1;
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
  width: 86%;
  z-index: 9;
  background: #ffffff;
  transition: 0.6s;
}
.rs-project.style4 .project-item .project-content .category {
  margin: 0;
}
.rs-project.style4 .project-item .project-content .category a {
  font-size: 16px;
  color: #0B70E1;
}
.rs-project.style4 .project-item .project-content .title {
  font-size: 20px;
  font-weight: 700;
}
.rs-project.style4 .project-item .project-content .title a {
  color: #0F0F0F;
}
.rs-project.style4 .project-item:hover .project-content {
  opacity: 1;
  bottom: 30px;
}
.rs-project.style5 .project-item {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.rs-project.style5 .project-item .project-img img {
  border-radius: 5px;
}
.rs-project.style5 .project-item .project-content {
  opacity: 0;
  visibility: hidden;
}
.rs-project.style5 .project-item .project-content .p-icon {
  position: absolute;
  top: 40px;
  right: 0px;
  bottom: 20px;
  text-align: right;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s ease;
  z-index: 11;
}
.rs-project.style5 .project-item .project-content .p-icon a {
  display: block;
}
.rs-project.style5 .project-item .project-content .p-icon a i:before {
  color: #1C1B1B;
}
.rs-project.style5 .project-item .project-content .project-inner {
  position: absolute;
  bottom: 0px;
  left: 25px;
  transition: all 0.3s ease;
}
.rs-project.style5 .project-item .project-content .project-inner .title {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.rs-project.style5 .project-item .project-content .project-inner .title a {
  color: #ffffff;
}
.rs-project.style5 .project-item .project-content .project-inner .title a:hover {
  color: #106eea;
}
.rs-project.style5 .project-item .project-content .project-inner .category {
  font-size: 22px;
  line-height: 37px;
  font-weight: 600;
  display: block;
}
.rs-project.style5 .project-item .project-content .project-inner .category a {
  color: #ffffff;
}
.rs-project.style5 .project-item .project-content .project-inner .category a:hover {
  color: #106eea;
}
.rs-project.style5 .project-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1C232F;
  opacity: 0;
  visibility: hidden;
  transition: .3s all ease;
}
.rs-project.style5 .project-item:hover .project-content {
  opacity: 1;
  visibility: visible;
}
.rs-project.style5 .project-item:hover .project-content .p-icon {
  right: 25px;
}
.rs-project.style5 .project-item:hover .project-content .p-icon a {
  display: block;
}
.rs-project.style5 .project-item:hover .project-content .p-icon a i:before {
  color: #1C1B1B;
}
.rs-project.style5 .project-item:hover .project-content .project-inner {
  bottom: 20px;
}
.rs-project.style5 .project-item:hover:before {
  opacity: 0.5;
  visibility: visible;
}
.rs-project.style6 .project-item {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}
.rs-project.style6 .project-item .project-img {
  position: relative;
}
.rs-project.style6 .project-item .project-img img {
  border-radius: 5px;
}
.rs-project.style6 .project-item .project-img .plus-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  color: #101010;
  background: #fff;
  position: absolute;
  right: 30px;
  top: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.rs-project.style6 .project-item .project-img .plus-icon i {
  font-size: 14px;
  color: #101010;
}
.rs-project.style6 .project-item .project-content {
  margin-top: 25px;
}
.rs-project.style6 .project-item .project-content .title {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 5px;
}
.rs-project.style6 .project-item .project-content .title a {
  color: #101010;
}
.rs-project.style6 .project-item .project-content .title a:hover {
  color: #106eea;
}
.rs-project.style6 .project-item .project-content .category {
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
  display: block;
}
.rs-project.style6 .project-item .project-content .category a {
  color: #106eea;
}
.rs-project.style6 .project-item:hover .project-img .plus-icon {
  opacity: 1;
  visibility: visible;
}
.rs-project.style6.modify1 .project-item .project-img .plus-icon {
  background: #F9780F;
}
.rs-project.style6.modify1 .project-item .project-img .plus-icon i {
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
}
.rs-project.style6.modify1 .project-item .project-content {
  margin-top: 25px;
}
.rs-project.style6.modify1 .project-item .project-content .title {
  font-size: 23px;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: 5px;
  text-decoration: underline;
}
.rs-project.style6.modify1 .project-item .project-content .title a {
  color: #101010;
}
.rs-project.style6.modify1 .project-item .project-content .title a:hover {
  color: #106eea;
}
.rs-project.style6.modify2 .project-item .project-img .plus-icon {
  background: #1273eb;
}
.rs-project.style6.modify2 .project-item .project-img .plus-icon i {
  font-size: 24px;
  line-height: 50px;
  font-weight: 300;
  color: #ffffff;
}
.rs-project.style6.modify2 .project-item .project-content {
  margin-top: 25px;
}
.rs-project.style6.modify2 .project-item .project-content .title {
  font-size: 25px;
  font-weight: 700;
  line-height: 53px;
  margin-bottom: 0;
}
.rs-project.style6.modify2 .project-item .project-content .title a {
  color: #101010;
}
.rs-project.style6.modify2 .project-item .project-content .title a:hover {
  color: #106eea;
}
.rs-project.style6.modify3 .project-item {
  overflow: hidden;
  border-radius: 5px;
}
.rs-project.style6.modify3 .project-item .project-img {
  position: relative;
  overflow: hidden;
}
.rs-project.style6.modify3 .project-item .project-img a img {
  transition: all .8s ease;
  transform: scale(1);
}
.rs-project.style6.modify3 .project-item .project-content {
  margin-top: 25px;
}
.rs-project.style6.modify3 .project-item .project-content .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 5px;
}
.rs-project.style6.modify3 .project-item .project-content .title a {
  color: #191821;
}
.rs-project.style6.modify3 .project-item .project-content .title a:hover {
  color: #787CF2;
}
.rs-project.style6.modify3 .project-item .project-content .category {
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
  display: block;
}
.rs-project.style6.modify3 .project-item .project-content .category a {
  color: #454545;
}
.rs-project.style6.modify3 .project-item:hover .project-img a img {
  transform: scale(1.1);
  border-radius: 5px;
}
.rs-project.style7 .project-item {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.rs-project.style7 .project-item .project-img img {
  transform: scale(1.2);
  transition: 0.6s;
  border-radius: 5px;
}
.rs-project.style7 .project-item .project-content {
  position: absolute;
  padding: 24px 30px;
  opacity: 0;
  z-index: 10;
  top: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
  transform: scale(1.3);
  transition: 0.4s;
}
.rs-project.style7 .project-item .project-content .title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 8px;
}
.rs-project.style7 .project-item .project-content .title a {
  color: #ffffff;
}
.rs-project.style7 .project-item .project-content .title a:hover {
  color: #106eea;
}
.rs-project.style7 .project-item .project-content .category {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  display: block;
}
.rs-project.style7 .project-item .project-content .category a {
  color: #ffffff;
}
.rs-project.style7 .project-item .project-content .category a:hover {
  color: #106eea;
}
.rs-project.style7 .project-item .project-content:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}
.rs-project.style7 .project-item:hover .project-img img {
  transform: scale(1);
}
.rs-project.style7 .project-item:hover .project-content {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.rs-project.style7 .project-item:hover .project-content:before {
  opacity: 1;
  visibility: visible;
}
.rs-project.style8 .project-item {
  position: relative;
  border-radius: 5px 5px 5px 5px;
  overflow: hidden;
}
.rs-project.style8 .project-item .project-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.rs-project.style8 .project-item .project-img img {
  width: 100%;
  transition: 1.3s all ease;
}
.rs-project.style8 .project-item .project-content {
  position: absolute;
  padding: 17px 25px 15px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  left: 0;
  bottom: -100px;
  text-align: left;
  width: 100%;
  z-index: 11;
  transition: 0.6s;
  height: 100%;
}
.rs-project.style8 .project-item .project-content .project-inner {
  position: absolute;
  bottom: -100px;
  transition: 0.5s;
  left: 30px;
}
.rs-project.style8 .project-item .project-content .project-inner .title {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.rs-project.style8 .project-item .project-content .project-inner .title a {
  color: #ffffff;
}
.rs-project.style8 .project-item .project-content .project-inner .title a:hover {
  color: #106eea;
}
.rs-project.style8 .project-item .project-content .project-inner .category {
  font-size: 22px;
  line-height: 37px;
  font-weight: 600;
  display: block;
}
.rs-project.style8 .project-item .project-content .project-inner .category a {
  color: #ffffff;
}
.rs-project.style8 .project-item .project-content .project-inner .category a:hover {
  color: #106eea;
}
.rs-project.style8 .project-item .project-content:before {
  content: '';
  position: absolute;
  bottom: -150px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.4s;
  border-radius: 5px;
  transition: 0.6s;
}
.rs-project.style8 .project-item:hover .project-img img {
  transform: scale(1.2);
}
.rs-project.style8 .project-item:hover .project-content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
.rs-project.style8 .project-item:hover .project-content .project-inner {
  bottom: 30px;
}
.rs-project.style8 .project-item:hover .project-content .project-inner .title {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.rs-project.style8 .project-item:hover .project-content .project-inner .title a {
  color: #ffffff;
}
.rs-project.style8 .project-item:hover .project-content .project-inner .title a:hover {
  color: #106eea;
}
.rs-project.style8 .project-item:hover .project-content .project-inner .category {
  font-size: 22px;
  line-height: 37px;
  font-weight: 600;
  display: block;
}
.rs-project.style8 .project-item:hover .project-content .project-inner .category a {
  color: #ffffff;
}
.rs-project.style8 .project-item:hover .project-content .project-inner .category a:hover {
  color: #106eea;
}
.rs-project.style8 .project-item:hover .project-content:before {
  opacity: 1;
  bottom: 0;
  visibility: visible;
}
.rs-project.style8.modify1 .project-item .project-content {
  z-index: 1;
}
.rs-project.style8.modify1 .project-item .project-content .project-inner .title a {
  color: #ffffff;
}
.rs-project.style8.modify1 .project-item .project-content .project-inner .title a:hover {
  color: #ffffff;
}
.rs-project.style8.modify1 .project-item .project-content .project-inner .category a {
  color: #ffffff;
}
.rs-project.style8.modify1 .project-item .project-content .project-inner .category a:hover {
  color: #f2541b;
}
.rs-project.style9 .project-item {
  position: relative;
  border-radius: 5px 5px 5px 5px;
  overflow: hidden;
}
.rs-project.style9 .project-item .project-content {
  position: absolute;
  width: 100%;
  bottom: -105%;
  left: 40px;
  transition: 0.4s;
}
.rs-project.style9 .project-item .project-content .project-inner .title {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 5px;
}
.rs-project.style9 .project-item .project-content .project-inner .title a {
  color: #ffffff;
}
.rs-project.style9 .project-item .project-content .project-inner .title a:hover {
  color: #106eea;
}
.rs-project.style9 .project-item .project-content .project-inner .category {
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
  display: block;
  margin-bottom: 25px;
}
.rs-project.style9 .project-item .project-content .project-inner .category a {
  color: #ffffff;
}
.rs-project.style9 .project-item .project-content .project-inner .category a:hover {
  color: #106eea;
}
.rs-project.style9 .project-item .project-content .p-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  background: #fff;
  font-size: 24px;
}
.rs-project.style9 .project-item .project-content .p-icon a {
  display: block;
}
.rs-project.style9 .project-item .project-content .p-icon i {
  color: #101010;
}
.rs-project.style9 .project-item:before {
  background: #03228f;
  content: "";
  position: absolute;
  bottom: -105%;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}
.rs-project.style9 .project-item:hover .project-content {
  bottom: 40px;
}
.rs-project.style9 .project-item:hover:before {
  bottom: 0;
  opacity: .8;
  visibility: visible;
}
.rs-project.style10 .project-item {
  position: relative;
  overflow: hidden;
}
.rs-project.style10 .project-item .project-img img {
  border-radius: 5px;
}
.rs-project.style10 .project-item .project-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 30%;
  left: 0;
  text-align: center;
  z-index: 10;
  padding: 25px;
  opacity: 0;
  -webkit-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
  border-radius: 5px;
}
.rs-project.style10 .project-item .project-content .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 6px;
}
.rs-project.style10 .project-item .project-content .title a {
  color: #ffffff;
}
.rs-project.style10 .project-item .project-content .title a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.rs-project.style10 .project-item .project-content .category {
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
}
.rs-project.style10 .project-item .project-content .category a {
  color: #ffffff;
}
.rs-project.style10 .project-item .project-content:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: all .3s ease 0s;
  border-radius: 5px;
}
.rs-project.style10 .project-item:hover .project-content {
  opacity: 1;
  top: 0;
}
.rs-project.style10 .project-item:hover .project-content:before {
  opacity: 1;
}
.rs-project.style10 .owl-nav .owl-next,
.rs-project.style10 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #ffffff;
  text-align: center;
  color: #101010;
  transition: all 0.5s;
  transition-delay: 0.70s;
  opacity: 0;
  visibility: hidden;
}
.rs-project.style10 .owl-nav .owl-next i:before,
.rs-project.style10 .owl-nav .owl-prev i:before {
  content: "\f111";
  font-family: Flaticon;
  font-size: 18px;
}
.rs-project.style10 .owl-nav .owl-next:hover,
.rs-project.style10 .owl-nav .owl-prev:hover {
  background: #106eea;
  color: #ffffff;
}
.rs-project.style10 .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-project.style10 .owl-nav .owl-next i:before {
  content: "\f110";
}
.rs-project.style10:hover .owl-nav .owl-next,
.rs-project.style10:hover .owl-nav .owl-prev {
  left: -5%;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-project.style10:hover .owl-nav .owl-next {
  right: -5%;
  left: unset;
}
/*------------------------------------
    23. Progress Section CSS
------------------------------------*/
.progress-pie-part .box {
  width: 100%;
  display: block;
  text-align: center;
  color: #0a0a0a;
}
.progress-pie-part .box .chart {
  position: relative;
}
.progress-pie-part .box .chart span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: 600;
  color: #102b3e;
}
.progress-pie-part .box .title {
  font-size: 22px;
  line-height: 37px;
  font-weight: 600;
  color: #102b3e;
  margin: 14px 0 0;
}
/*------------------------------------
    24. Case Study Section CSS
------------------------------------*/
.rs-case-study.primary-background {
  background: linear-gradient(90deg, #03228f 40%, #0e73e4 90%);
}
/*------------------------------------
    25. Blog Section CSS
------------------------------------*/
.rs-blog .blog-item {
  transition: all 500ms ease;
  border-radius: 5px;
  box-shadow: 0 6px 25px rgba(12, 89, 219, 0.09);
  margin-top: 15px;
  padding: 15px;
  background: #fff;
}
.rs-blog .blog-item .image-wrap {
  position: relative;
}
.rs-blog .blog-item .image-wrap .post-categories {
  position: absolute;
  z-index: 11;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}
.rs-blog .blog-item .image-wrap .post-categories li {
  display: inline-block;
}
.rs-blog .blog-item .image-wrap .post-categories li a {
  border-radius: 30px;
  color: #ffffff;
  background: #03228f;
  background: -moz-linear-gradient(left, #03228f 0%, #03228f 0%, #03228f 26%, #4e95ed 100%, #2989d8 100%, #207cca 100%, #0b70e1 100%);
  background: -webkit-linear-gradient(left, #03228f 0%, #03228f 0%, #03228f 26%, #4e95ed 100%, #2989d8 100%, #207cca 100%, #0b70e1 100%);
  background: linear-gradient(to right, #03228f 0%, #03228f 0%, #03228f 26%, #4e95ed 100%, #2989d8 100%, #207cca 100%, #0b70e1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#03228f', endColorstr='#0b70e1', GradientType=1);
  transition: 0.4s;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px;
  display: block;
}
.rs-blog .blog-item .image-wrap .post-categories li a:hover {
  background: linear-gradient(to left, #03228f 0%, #03228f 0%, #03228f 26%, #4e95ed 100%, #2989d8 100%, #207cca 100%, #0b70e1 100%);
}
.rs-blog .blog-item .blog-content {
  padding: 30px 15px 18px 15px;
  background: #fff;
}
.rs-blog .blog-item .blog-content .blog-meta {
  margin-bottom: 10px;
  display: flex;
}
.rs-blog .blog-item .blog-content .blog-meta .date {
  font-size: 14px;
  color: #454545;
}
.rs-blog .blog-item .blog-content .blog-meta .date i {
  color: #106eea;
  padding-right: 5px;
}
.rs-blog .blog-item .blog-content .blog-meta .admin {
  font-size: 15px;
  font-weight: 500;
  color: #454545;
  margin-left: 25px;
}
.rs-blog .blog-item .blog-content .blog-meta .admin i {
  color: #106eea;
  padding-right: 5px;
}
.rs-blog .blog-item .blog-content .blog-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 12px;
  line-height: 1.4;
}
.rs-blog .blog-item .blog-content .blog-title a {
  color: #101010;
}
.rs-blog .blog-item .blog-content .blog-title a:hover {
  color: #106eea;
}
.rs-blog .blog-item .blog-content .desc {
  margin-bottom: 15px;
}
.rs-blog .owl-stage-outer {
  padding-bottom: 10px;
  margin-top: -10px;
}
.rs-blog .blog-item:hover {
  transform: translateY(-10px);
}
.rs-blog.style2 .blog-item {
  transition: all 500ms ease;
  border-radius: 5px;
  box-shadow: 0 6px 25px rgba(12, 89, 219, 0.09);
  margin-top: 15px;
  padding: 15px;
}
.rs-blog.style2 .blog-item .image-wrap .post-categories li a {
  border-radius: 8px 8px 8px 8px;
  background-image: linear-gradient(150deg, #da4511 0%, #e7b723 100%);
}
.rs-blog.style2 .blog-item .blog-content {
  padding: 30px 15px 18px 15px;
  background: #fff;
}
.rs-blog.style2 .blog-item .blog-content .blog-meta .date {
  font-size: 14px;
  color: #454545;
}
.rs-blog.style2 .blog-item .blog-content .blog-meta .date i {
  color: #106eea;
  padding-right: 5px;
}
.rs-blog.style2 .blog-item .blog-content .blog-meta .admin {
  font-size: 15px;
  font-weight: 500;
  color: #454545;
  margin-left: 25px;
}
.rs-blog.style2 .blog-item .blog-content .blog-meta .admin i {
  color: #106eea;
  padding-right: 5px;
}
.rs-blog.style2 .blog-item .blog-content .blog-title a {
  color: #101010;
}
.rs-blog.style2 .blog-item .blog-content .blog-title a:hover {
  color: #FF5600;
}
.rs-blog.style2 .owl-stage-outer {
  padding: 20px;
  margin: -20px;
}
.rs-blog.style3 .blog-item .image-wrap .post-categories li a {
  border-radius: 3px 3px 3px 3px;
  background-image: linear-gradient(120deg, #6e71db 0%, #9c9ff9 100%);
}
.rs-blog.style3 .blog-item .blog-content .blog-meta .date i {
  color: #787cf2;
}
.rs-blog.style3 .blog-item .blog-content .blog-meta .admin i {
  color: #787cf2;
  padding-right: 5px;
}
.rs-blog.style3 .blog-item .blog-content .blog-title a {
  color: #101010;
}
.rs-blog.style3 .blog-item .blog-content .blog-title a:hover {
  color: #787cf2;
}
.rs-inner-blog .blog-item {
  background: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 15px;
  transition: 0.3s;
}
.rs-inner-blog .blog-item .blog-img {
  position: relative;
}
.rs-inner-blog .blog-item .blog-img a img {
  border-radius: 10px;
}
.rs-inner-blog .blog-item .blog-img .post-categories {
  position: absolute;
  z-index: 11;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}
.rs-inner-blog .blog-item .blog-img .post-categories li {
  display: inline-block;
}
.rs-inner-blog .blog-item .blog-img .post-categories li a {
  border-radius: 30px;
  color: #ffffff;
  background: #03228f;
  background: -moz-linear-gradient(left, #03228f 0%, #03228f 0%, #03228f 26%, #4e95ed 100%, #2989d8 100%, #207cca 100%, #0b70e1 100%);
  background: -webkit-linear-gradient(left, #03228f 0%, #03228f 0%, #03228f 26%, #4e95ed 100%, #2989d8 100%, #207cca 100%, #0b70e1 100%);
  background: linear-gradient(to right, #03228f 0%, #03228f 0%, #03228f 26%, #4e95ed 100%, #2989d8 100%, #207cca 100%, #0b70e1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#03228f', endColorstr='#0b70e1', GradientType=1);
  transition: 0.4s;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px;
  display: block;
}
.rs-inner-blog .blog-item .blog-img .post-categories li a:hover {
  background: linear-gradient(to left, #03228f 0%, #03228f 0%, #03228f 26%, #4e95ed 100%, #2989d8 100%, #207cca 100%, #0b70e1 100%);
}
.rs-inner-blog .blog-item .blog-content {
  padding: 25px 15px 25px;
  overflow: hidden;
}
.rs-inner-blog .blog-item .blog-content .blog-title {
  margin: 4px 0 10px;
  font-size: 26px;
  line-height: 35px;
  font-weight: 700;
}
.rs-inner-blog .blog-item .blog-content .blog-title a {
  color: #0a0a0a;
}
.rs-inner-blog .blog-item .blog-content .blog-title a:hover {
  color: #106eea;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate {
  overflow: hidden;
  margin: 0 0 5px;
  padding: 0 0 12px;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li {
  margin-right: 15px;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li .blog-date {
  display: inline-block;
  font-weight: 400;
  font-size: 13px;
  color: #999;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li .blog-date i {
  color: #0B70E1;
  margin-right: 3px;
  font-size: 14px;
  font-weight: 600;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li .author {
  display: inline-block;
  padding: 0;
  margin-right: 0;
  line-height: normal;
  color: #999;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li .author i {
  color: #0B70E1;
  margin-right: 3px;
  font-size: 14px;
  font-weight: 600;
}
.rs-inner-blog .blog-item .blog-content .blog-meta .btm-cate li:last-child {
  margin-right: 0;
}
.rs-inner-blog .blog-item .blog-content .blog-desc {
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  color: #454545;
  margin-bottom: 20px;
}
.rs-inner-blog .widget-area .widget-title .title {
  color: #101010;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  position: relative;
  z-index: 1;
  padding-bottom: 12px;
  margin: 0;
}
.rs-inner-blog .widget-area .widget-title .title:before {
  content: "";
  position: absolute;
  border: 0;
  width: 50px;
  height: 2px;
  background: #106eea;
  z-index: 1;
  margin-left: 0;
  bottom: 0;
  left: 0;
}
.rs-inner-blog .widget-area .search-widget {
  background: #fff;
  padding: 40px 30px 40px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}
.rs-inner-blog .widget-area .search-widget .search-wrap {
  position: relative;
}
.rs-inner-blog .widget-area .search-widget .search-wrap [type=search] {
  border: 1px solid #ddd;
  color: #444444;
  padding: 12px 17px;
  width: 100%;
  border-radius: 5px;
  position: relative;
}
.rs-inner-blog .widget-area .search-widget .search-wrap button {
  background: transparent;
  border: medium none;
  color: #454545;
  padding: 11px 15px 12px;
  position: absolute;
  display: block;
  right: 0px;
  top: 0;
  z-index: 10;
  font-size: 20px;
  border-radius: 0 5px 5px;
}
.rs-inner-blog .widget-area .search-widget .search-wrap button i:before {
  font-weight: 600;
  font-size: 18px;
}
.rs-inner-blog .widget-area .search-widget .search-wrap button:hover {
  color: #106eea;
}
.rs-inner-blog .widget-area .recent-posts {
  background: #fff;
  padding: 40px 30px;
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-img {
  width: 100px;
  float: left;
  padding-right: 15px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc {
  display: block;
  font-size: 12px;
  color: #888;
  overflow: hidden;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc a {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  color: #0a0a0a;
  display: block;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc a:hover {
  color: #106eea;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc .date {
  display: block;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc .date i {
  margin-right: 5px;
}
.rs-inner-blog .widget-area .recent-posts .recent-post-widget .post-desc .date i:before {
  font-size: 12px;
}
.rs-inner-blog .widget-area .categories {
  background: #fff;
  padding: 40px 30px 40px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.rs-inner-blog .widget-area .categories li {
  margin-top: 13px;
  padding-top: 13px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.rs-inner-blog .widget-area .categories li a {
  font-size: 15px;
  font-weight: 500;
  color: #101010;
}
.rs-inner-blog .widget-area .categories li a:hover {
  color: #106eea;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta {
  display: flex;
  align-items: center;
  padding: 0 0 30px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta li .p-date {
  color: #555;
  font-size: 14px;
  margin-right: 8px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta li .p-date i {
  font-size: 13px;
  margin-right: 3px;
  color: #0B70E1;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta li .p-date i:before {
  margin-right: 5px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .Post-cate {
  list-style: none;
  display: inline-block;
  padding-right: 10px;
  color: #555;
  font-size: 14px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .Post-cate .tag-line i {
  color: #0B70E1;
  margin-right: 3px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .Post-cate .tag-line i:before {
  margin-right: 4px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .Post-cate .tag-line a {
  font-weight: 400;
  color: #555;
  font-size: 14px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .Post-cate .tag-line a:hover {
  color: #106eea;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .post-comment {
  color: #555;
  font-size: 14px;
}
.rs-inner-blog .blog-details .blog-full .single-post-meta .post-comment i:before {
  color: #0B70E1;
}
.rs-inner-blog .blog-details .blog-full .comment-title {
  font-size: 24px;
  margin-bottom: 40px;
  margin-top: 35px;
}
.rs-inner-blog .blog-details .blog-full .comment-body {
  display: flex;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
  margin-bottom: 25px;
}
.rs-inner-blog .blog-details .blog-full .comment-body .rstheme-logo {
  margin-right: 28px;
}
.rs-inner-blog .blog-details .blog-full .comment-body .rstheme-logo img {
  border-radius: 50%;
  max-width: unset;
}
.rs-inner-blog .blog-details .blog-full .comment-body .comment-meta span {
  display: block;
}
.rs-inner-blog .blog-details .blog-full .comment-body .comment-meta span a {
  color: #0a0a0a;
  font-weight: 700;
  line-height: 22px;
}
.rs-inner-blog .blog-details .blog-full .comment-body .comment-meta a {
  font-size: 14px;
  color: #909090;
}
.rs-inner-blog .blog-details .blog-full .comment-note .from-control {
  width: 100%;
  padding: 15px 20px;
  background-color: #F6F7F9;
  border: none;
  border-radius: 5px;
}
.rs-inner-blog .blog-details .blog-full .comment-note textarea {
  height: 160px;
}
.rs-inner-blog .blog-details .blog-full .comment-note ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #454545;
  opacity: 1;
}
.rs-inner-blog .blog-details .blog-full .comment-note ::-moz-placeholder {
  /* Firefox 19+ */

  color: #454545;
  opacity: 1;
}
.rs-inner-blog .blog-details .blog-full .comment-note :-ms-input-placeholder {
  /* IE 10+ */

  color: #454545;
  opacity: 1;
}
.rs-inner-blog .blog-details .blog-full .comment-note :-moz-placeholder {
  /* Firefox 18- */

  color: #454545;
  opacity: 1;
}
/*------------------------------------
    26. Features Section CSS
------------------------------------*/
.rs-feature .icon-box-area {
  padding: 50px 40px 41px 40px;
  background-image: linear-gradient(90deg, #f69f6b 41%, #fbbb6a 100%);
  border-radius: 5px 5px 5px 5px;
}
.rs-feature .icon-box-area .box-inner .icon-area {
  margin-bottom: 15px;
}
.rs-feature .icon-box-area .box-inner .content-part .title {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 0;
}
.rs-feature .icon-box-area .box-inner .content-part .title a {
  color: #fff;
}
.rs-feature .icon-box-area.blue-bg {
  background-image: linear-gradient(90deg, #559cea 41%, #6ba3cb 100%);
}
.rs-feature .icon-box-area.purple-bg {
  background-image: linear-gradient(90deg, #a040f3 41%, #a86ae3 100%);
}
.rs-feature .icon-box-area.pink-bg {
  background-image: linear-gradient(90deg, #f954a1 41%, #f2a1c6 100%);
}
/*------------------------------------
    27. Call Action Section CSS
------------------------------------*/
.rs-call-action .action-wrap {
  text-align: center;
  margin: 0 auto;
  max-width: 500px;
}
.rs-call-action .action-wrap .icon-img {
  margin-bottom: 20px;
}
.rs-call-action .action-wrap .icon-img img {
  width: 120px;
}
.rs-call-action .action-wrap .title {
  font-size: 36px;
  line-height: 50px;
  font-weight: 700;
  color: #ffffff;
  text-decoration: underline;
  margin-bottom: 15px;
}
.rs-call-action .action-wrap .title .watermark {
  color: #FBAB9A;
}
.rs-call-action.style2 .action-wrap {
  max-width: 520px;
}
.rs-call-action.style2 .action-wrap .title {
  font-size: 42px;
  color: #102b3e;
}
/*------------------------------------
    28. Partner Section CSS
------------------------------------*/
.rs-partner {
  background-image: linear-gradient(330deg, #00005b 0%, #020275 90%);
}
.rs-partner .partner-item .logo-img a {
  overflow: hidden;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.rs-partner .partner-item .logo-img a .hover-logo {
  transition: all 0.3s ease-in-out;
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  visibility: hidden;
  filter: grayscale(100%);
}
.rs-partner .partner-item .logo-img a .main-logo {
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
  display: block;
}
.rs-partner .partner-item .logo-img a:hover .hover-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  opacity: 3;
  visibility: visible;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
  filter: grayscale(0%);
}
.rs-partner .partner-item .logo-img a:hover .main-logo {
  display: block;
  visibility: hidden;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  filter: grayscale(100%);
}
.rs-partner.style2 {
  box-shadow: 0px 8px 50px 0px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  background-image: unset;
}
.rs-partner.style2.modify1 {
  box-shadow: unset;
  background: unset;
}
.rs-partner.style2.modify1 .title {
  color: #717070;
  font-size: 18px;
  font-weight: 600;
}
.rs-partner.style3 {
  background-image: unset;
  background: url(../images/bg/partners-bg.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-color: #00005B;
}
.rs-partner.style4 {
  box-shadow: unset;
  background-image: unset;
}
.rs-partner.style4.modify1 .partner-item {
  padding: 0 10px;
}
.rs-partner.style5 {
  background-image: linear-gradient(150deg, #03228f 46%, #0e73e4 100%);
}
/* ------------------------------------
    29. Testimonial Section CSS
---------------------------------------*/
.rs-testimonial.main-home .testi-item .author-desc {
  background: #ffffff;
  padding: 70px 40px 80px 40px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: -2px 16px 44px -32px rgba(0, 0, 0, 0);
}
.rs-testimonial.main-home .testi-item .author-desc .desc {
  text-align: center;
  position: relative;
  color: #333333;
  padding: 61px 0px 10px 0px;
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
}
.rs-testimonial.main-home .testi-item .author-desc .desc .quote {
  width: 50px;
  top: -13px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.rs-testimonial.main-home .testi-item .author-desc .author-img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 80px;
}
.rs-testimonial.main-home .testi-item .author-desc .author-img img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.rs-testimonial.main-home .testi-item .author-part {
  text-align: center;
  padding-top: 70px;
}
.rs-testimonial.main-home .testi-item .author-part .name {
  color: #0A0A0A;
  font-size: 22px;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
}
.rs-testimonial.main-home .owl-dots {
  text-align: center;
  margin-top: 47px;
}
.rs-testimonial.main-home .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease;
  background: #0B70E1;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
}
.rs-testimonial.main-home .owl-dots .owl-dot:hover,
.rs-testimonial.main-home .owl-dots .owl-dot.active {
  width: 40px;
  background: #0B70E1;
  opacity: 1;
}
.rs-testimonial.main-home .owl-stage-outer {
  padding: 45px 0;
  margin: -45px 0;
}
.rs-testimonial.main-home.style2 .testi-item .author-desc {
  background-image: linear-gradient(150deg, #000060 0%, #1e1eaa 98%);
  box-shadow: unset;
}
.rs-testimonial.main-home.style2 .testi-item .author-desc .desc {
  color: #ffffff;
  font-size: 20px;
  padding: 70px 50px 35px 50px;
}
.rs-testimonial.main-home.style2 .testi-item .author-part .name {
  color: #ffffff;
}
.rs-testimonial.main-home.style2 .testi-item .author-part .designation {
  color: #ffffff;
}
.rs-testimonial.main-home.style2.modify1 .overlayer {
  position: relative;
}
.rs-testimonial.main-home.style2.modify1 .overlayer .testi-item .author-desc {
  background-color: #03228F75;
  background-image: unset;
  box-shadow: unset;
  border-radius: unset;
}
.rs-testimonial.main-home.style2.modify1 .overlayer:after {
  background-color: #020E38;
  opacity: 0.64;
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.rs-testimonial.main-home.style4 {
  padding: 120px 0px 120px 0px;
  border-radius: 270px 0px 0px 0px;
}
.rs-testimonial.main-home.style4 .testi-item {
  padding: unset;
  position: relative;
}
.rs-testimonial.main-home.style4 .testi-item .author-desc {
  background: #ffffff;
  padding: 40px 40px 80px 43px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: -2px 16px 44px -32px rgba(0, 0, 0, 0);
}
.rs-testimonial.main-home.style4 .testi-item .author-desc .desc {
  text-align: unset;
  position: unset;
  color: #333333;
  padding: 73px 0px 10px 0px;
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
}
.rs-testimonial.main-home.style4 .testi-item .author-desc .desc .quote {
  top: 44px;
  position: absolute;
  left: 7%;
  transform: unset;
}
.rs-testimonial.main-home.style4 .testi-item .testimonial-content {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: -40px;
}
.rs-testimonial.main-home.style4 .testi-item .testimonial-content .author-img img {
  width: 90px;
  height: 90px;
  border-radius: 50px 50px 50px 50px;
}
.rs-testimonial.main-home.style4 .testi-item .testimonial-content .author-part {
  padding-left: 15px;
  text-align: unset;
  padding-top: unset;
}
.rs-testimonial.main-home.style4.modify1 {
  border-radius: unset;
  position: relative;
}
.rs-testimonial.main-home.style4.modify1 .modify-gap {
  padding-right: 220px;
  padding-left: 50px;
}
.rs-testimonial.main-home.style4.modify1 .testi-img img {
  max-width: 900px;
}
.rs-testimonial.main-home.style4.modify2 .testi-item .author-desc {
  padding: 40px 40px 160px 43px;
}
.rs-testimonial.main-home.style4.modify2 .testi-item .testimonial-content {
  bottom: 50px;
  left: 34px;
}
.rs-testimonial.style3 .testi-item {
  text-align: center;
}
.rs-testimonial.style3 .testi-item .author-desc {
  background-image: linear-gradient(310deg, #0024ba 19%, #000060 65%);
  padding: 100px 45px 40px 45px;
  border-radius: 15px 15px 15px 15px;
  box-shadow: -2px 16px 44px -32px rgba(0, 0, 0, 0);
  position: relative;
}
.rs-testimonial.style3 .testi-item .author-desc .desc {
  font-size: 18px;
  line-height: 31px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 0;
}
.rs-testimonial.style3 .testi-item .author-desc .desc .quote {
  width: 50px;
  top: 36px;
  position: absolute;
  left: 44%;
}
.rs-testimonial.style3 .testi-item .author-desc:before {
  top: 100%;
  left: 15%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #061340;
  border-width: 12px;
  margin-left: -30px;
  left: 55%;
  z-index: 1;
}
.rs-testimonial.style3 .testi-item .testi-content .images-wrap img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: 25px auto 18px;
}
.rs-testimonial.style3 .testi-item .testi-content .name {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #101010;
  display: block;
  margin-bottom: 7px;
}
.rs-testimonial.style3 .testi-item .testi-content .name:hover {
  color: #106eea;
}
.rs-testimonial.style3 .testi-item .testi-content .designation {
  color: #878787;
  margin-bottom: 0px;
}
.rs-testimonial.style3 .owl-dots {
  text-align: center;
  margin-top: 47px;
}
.rs-testimonial.style3 .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease;
  background: #0B70E1;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
}
.rs-testimonial.style3 .owl-dots .owl-dot:hover,
.rs-testimonial.style3 .owl-dots .owl-dot.active {
  width: 40px;
  background: #0B70E1;
  opacity: 1;
}
.rs-testimonial.style3 .owl-stage-outer {
  padding: 10px;
  margin: -10px;
}
.rs-testimonial.style4 .testi-item {
  background-color: #FDFDFD;
  border-radius: 5px 5px 5px 5px;
  padding: 60px 60px 60px 60px;
}
.rs-testimonial.style4 .testi-item .testi-content {
  display: flex;
}
.rs-testimonial.style4 .testi-item .testi-content .images-wrap img {
  width: 60px;
  height: 60px;
  border-radius: 4px 4px 4px 4px;
}
.rs-testimonial.style4 .testi-item .testi-content .testi-information {
  padding-left: 15px;
  position: relative;
}
.rs-testimonial.style4 .testi-item .testi-content .testi-information .testi-name {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
}
.rs-testimonial.style4 .testi-item .testi-content .testi-information .testi-title {
  display: block;
  color: #878787;
}
.rs-testimonial.style4 .testi-item .item-content-basic .desc {
  color: #555555;
  padding: 30px 0px 0px 0px;
}
.rs-testimonial.style4 .testi-item .item-content-basic .desc .quote {
  width: 54px;
  top: 55px;
  position: absolute;
  left: 71%;
  z-index: 9;
}
.rs-testimonial.style4 .owl-nav .owl-next,
.rs-testimonial.style4 .owl-nav .owl-prev {
  position: absolute;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  color: #FBFBFB;
  border: 1px solid #fff;
  transform: translateY(-50%);
  transition: 0.6s ease all;
  z-index: 9;
  text-align: center;
}
.rs-testimonial.style4 .owl-nav .owl-next i:before,
.rs-testimonial.style4 .owl-nav .owl-prev i:before {
  content: "\f111";
  font-family: Flaticon;
  font-size: 18px;
  font-weight: 400;
}
.rs-testimonial.style4 .owl-nav .owl-next:hover,
.rs-testimonial.style4 .owl-nav .owl-prev:hover {
  color: #ffffff;
}
.rs-testimonial.style4 .owl-nav .owl-next {
  top: 249px;
  left: -63%;
}
.rs-testimonial.style4 .owl-nav .owl-next i:before {
  content: "\f110";
}
.rs-testimonial.style4 .owl-nav .owl-prev {
  left: -75%;
  top: 249px;
}
.rs-testimonial.style5 .testi-item {
  background-image: linear-gradient(140deg, #03228f 0%, #4e95ed 100%);
  padding: 70px 60px 70px 60px;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
}
.rs-testimonial.style5 .testi-item p {
  color: #fff;
  font-size: 18px;
  line-height: 35px;
  margin-bottom: 0;
  padding: 30px 35px 20px 35px;
}
.rs-testimonial.style5 .testi-item .testi-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rs-testimonial.style5 .testi-item .testi-content .testi-img {
  padding-right: 15px;
}
.rs-testimonial.style5 .testi-item .testi-content .testi-img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.rs-testimonial.style5 .testi-item .testi-content .author-part {
  text-align: left;
}
.rs-testimonial.style5 .testi-item .testi-content .author-part .name {
  font-size: 16px;
  line-height: 35px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 0;
  display: block;
}
.rs-testimonial.style5 .testi-item .testi-content .author-part .designation {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  display: block;
}
.rs-testimonial.style6 .testi-item {
  background-color: #fff;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 10px 0px rgba(232, 232, 232, 0.5);
  padding: 92px 0px 90px 0px;
  margin: 0 -15px 0 0;
}
.rs-testimonial.style6 .testi-item .testi-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 34px;
}
.rs-testimonial.style6 .testi-item .testi-content .images-wrap {
  width: 90px;
  height: 90px;
}
.rs-testimonial.style6 .testi-item .testi-content .images-wrap img {
  border-radius: 50px 50px 50px 50px;
}
.rs-testimonial.style6 .testi-item .testi-content .testi-information {
  padding: 0 0 0 25px;
  text-align: left;
}
.rs-testimonial.style6 .testi-item .testi-content .testi-information .testi-name {
  color: #102B3E;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
}
.rs-testimonial.style6 .testi-item .testi-content .testi-information .testi-title {
  color: #404A50;
  padding-bottom: 10px;
  display: block;
}
.rs-testimonial.style6 .testi-item .testi-content .testi-information .rating img {
  width: 100px;
}
.rs-testimonial.style6 .testi-item .item-content-basic {
  padding: 0px 40px 0px 40px;
  text-align: center;
}
.rs-testimonial.style6 .testi-item .item-content-basic p {
  color: #272525;
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
  line-height: 35px;
}
.rs-testimonial.style6 .testi-item .item-content-basic span img {
  width: 32px;
  top: 82%;
  position: absolute;
  left: 47%;
  z-index: 9;
}
.rs-testimonial.style6 .owl-dots {
  text-align: center;
  margin-top: 47px;
}
.rs-testimonial.style6 .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease;
  background: #032390;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
}
.rs-testimonial.style6 .owl-dots .owl-dot:hover,
.rs-testimonial.style6 .owl-dots .owl-dot.active {
  width: 40px;
  background: #032390;
  opacity: 1;
}
.rs-testimonial.style7 {
  background: url(../images/bg/testimonial-bg.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-testimonial.style7 .testi-main {
  margin: 0px 0px 0px 0px;
  padding: 0px 100px 0px 150px;
}
.rs-testimonial.style7 .testi-main .testi-item .testi-content {
  display: flex;
  align-items: center;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0 0 30px #eee;
  margin: 40px 37px 30px 100px;
  padding: 50px 30px 50px 50px;
}
.rs-testimonial.style7 .testi-main .testi-item .testi-content .images-wrap img {
  border-radius: 3px;
  height: 263px;
  max-width: 263px;
  width: 263px;
  margin-left: -150px;
}
.rs-testimonial.style7 .testi-main .testi-item .testi-content .testi-information {
  padding-left: 45px;
}
.rs-testimonial.style7 .testi-main .testi-item .testi-content .testi-information p {
  color: #102B3E;
  font-size: 22px;
  font-weight: 500;
  font-style: italic;
  line-height: 35px;
}
.rs-testimonial.style7 .testi-main .testi-item .testi-content .testi-information .testi-name {
  color: #102B3E;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
}
.rs-testimonial.style7 .testi-main .testi-item .testi-content .testi-information .testi-title {
  color: #404A50;
}
.rs-testimonial.style7 .owl-nav .owl-next,
.rs-testimonial.style7 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  background: linear-gradient(90deg, #03228f 10%, #0e73e4 100%);
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;
}
.rs-testimonial.style7 .owl-nav .owl-next i,
.rs-testimonial.style7 .owl-nav .owl-prev i {
  margin-top: 15px;
}
.rs-testimonial.style7 .owl-nav .owl-next i:before,
.rs-testimonial.style7 .owl-nav .owl-prev i:before {
  content: "\f115";
  font-family: Flaticon;
  font-size: 18px;
  font-weight: 400;
}
.rs-testimonial.style7 .owl-nav .owl-next:hover,
.rs-testimonial.style7 .owl-nav .owl-prev:hover {
  background: linear-gradient(0deg, #03228f 10%, #0e73e4 100%);
  color: #ffffff;
}
.rs-testimonial.style7 .owl-nav .owl-next {
  right: -75px;
  left: unset;
}
.rs-testimonial.style7 .owl-nav .owl-next i:before {
  content: "\f114";
  font-size: 18px;
  font-weight: 400;
}
.rs-testimonial.style7 .owl-nav .owl-prev {
  right: unset;
  left: -105px;
}
.rs-testimonial.style7 .owl-nav .owl-prev i:before {
  content: "\f115";
  font-size: 18px;
  font-weight: 400;
}
.rs-testimonial.style8 .testi-item {
  background: #fff;
  padding: 50px 35px;
  border-radius: 4px;
}
.rs-testimonial.style8 .testi-item .item-content-basic span img {
  width: 100px;
}
.rs-testimonial.style8 .testi-item .item-content-basic p {
  font-size: 20px;
  line-height: 35px;
  font-weight: 500;
  color: #101010;
  margin-bottom: 32px;
  padding-top: 20px;
}
.rs-testimonial.style8 .testi-item .testi-information {
  display: flex;
  align-items: center;
}
.rs-testimonial.style8 .testi-item .testi-information .img-part img {
  border-radius: 50%;
}
.rs-testimonial.style8 .testi-item .testi-information .testi-content {
  padding-left: 25px;
}
.rs-testimonial.style8 .testi-item .testi-information .testi-content .testi-name {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: #101010;
  margin-bottom: 2px;
}
.rs-testimonial.style8 .testi-item .testi-information .testi-content .testi-title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #454545;
  margin-bottom: 0;
}
.rs-testimonial.style8 .owl-dots {
  text-align: center;
  margin-top: 47px;
}
.rs-testimonial.style8 .owl-dots .owl-dot {
  width: 15px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 30px;
  background: #0d58ba;
  border: none;
  transition: all 0.3s ease;
}
.rs-testimonial.style8 .owl-dots .owl-dot:hover,
.rs-testimonial.style8 .owl-dots .owl-dot.active {
  width: 40px;
  opacity: 1;
  background: #f2541b;
}
.rs-testimonial.style8 .owl-stage-outer {
  padding: 10px;
  margin: -10px;
}
.rs-testimonial.style9 .testi-item {
  display: flex;
  background-color: #fff;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 15px 0px #eee;
  padding: 40px 40px 15px 35px;
}
.rs-testimonial.style9 .testi-item .testi-content {
  display: block;
  margin-right: 30px;
  flex: 0 0 30%;
  padding-top: 12px;
  max-width: 30%;
}
.rs-testimonial.style9 .testi-item .testi-content .images-wrap img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-bottom: 12px;
}
.rs-testimonial.style9 .testi-item .testi-content .testi-information .testi-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  color: #191821;
}
.rs-testimonial.style9 .testi-item .testi-content .testi-information .testi-title {
  color: #404A50;
}
.rs-testimonial.style9 .testi-item .testi-content .testi-information .ratings {
  margin-top: 10px;
}
.rs-testimonial.style9 .testi-item .testi-content .testi-information .ratings img {
  width: 100px;
}
.rs-testimonial.style9 .testi-item .item-content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  color: #454545;
}
.rs-testimonial.style9 .owl-dots {
  text-align: center;
  margin-top: 47px;
}
.rs-testimonial.style9 .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease;
  background: #787cf2;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
}
.rs-testimonial.style9 .owl-dots .owl-dot:hover,
.rs-testimonial.style9 .owl-dots .owl-dot.active {
  width: 40px;
  background: #787cf2;
  opacity: 1;
}
.rs-testimonial.style9 .owl-stage-outer {
  padding: 45px 0;
  margin: -45px 0;
}
.rs-testimonial.style10 {
  padding-left: 15%;
  padding-top: 120px;
  padding-bottom: 80px;
  margin-top: -370px;
  position: relative;
}
.rs-testimonial.style10 .slick-list {
  padding: 15px 300px 15px 15px !important;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slider.slider-for2 .testi-item {
  display: flex;
  align-items: center;
  vertical-align: middle;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 0 25px #eee;
  padding: 30px 30px 30px 35px;
  margin: 40px 45px 40px 100px;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slider.slider-for2 .testi-item .images-wrap {
  position: relative;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slider.slider-for2 .testi-item .images-wrap img {
  max-width: 263px;
  width: 231px !important;
  height: 314px !important;
  margin-left: -150px;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slider.slider-for2 .testi-item .item-content {
  padding: 0px 0px 0px 30px;
  position: relative;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slider.slider-for2 .testi-item .item-content span img {
  top: 74%;
  position: absolute;
  left: 73.5%;
  opacity: 0.5;
  width: 60px;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slider.slider-for2 .testi-item .item-content p {
  font-size: 16px;
  font-style: italic;
  margin: 0 27px 15px 0;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slider.slider-for2 .testi-item .item-content .testi-informations .testi-name {
  font-size: 18px;
  color: #0A0A0A;
  font-weight: 600;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slider.slider-for2 .testi-item .item-content .testi-informations .testi-title {
  font-size: 15px;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slick-arrow {
  position: absolute;
  font-size: 0;
  background-image: linear-gradient(90deg, #483afa 0%, #982ef5 100%);
  border: none;
  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  z-index: 1;
  top: -18%;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slick-arrow:before {
  content: "\f115";
  font-family: Flaticon;
  font-size: 18px;
  font-weight: 400;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slick-arrow.slick-prev {
  right: 27%;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slick-arrow.slick-next {
  right: 22.5%;
}
.rs-testimonial.style10 .testi-top-section .slick-part .slick-arrow.slick-next:before {
  content: "\f114";
}
/*----------------------------------------
    30. Shop Section CSS
----------------------------------------*/
.rs-shop-part .woocommerce-result-count {
  font-size: 15px;
  line-height: 26px;
  color: #454545;
  font-weight: 400;
  margin: 0;
}
.rs-shop-part .from-control {
  float: right;
  font-size: 15px;
  color: #454545;
  font-weight: 400;
  vertical-align: top;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.rs-shop-part .product-list .image-product {
  position: relative;
}
.rs-shop-part .product-list .image-product .overley i {
  position: absolute;
  bottom: 150px;
  right: 13px;
  background: transparent;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}
.rs-shop-part .product-list .image-product .overley i:before {
  font-size: 30px;
  color: #ffffff;
  font-weight: 400;
  background: #106eea;
  border: none ;
  padding: 8px 10px 8px;
  height: 45px;
  width: 45px;
  line-height: 45px;
  border-radius: 5px;
}
.rs-shop-part .product-list .image-product .onsale {
  font-size: 13px;
  color: #ffffff;
  font-weight: 700;
  line-height: 40px;
  background: #106eea;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 13px;
  right: 13px;
}
.rs-shop-part .product-list .content-desc .loop-product-title {
  margin: 0;
  font-size: 18px;
  line-height: 50px;
  color: #454545;
  font-weight: 700;
}
.rs-shop-part .product-list .content-desc .loop-product-title a {
  color: #101010;
  font-size: 18px;
  line-height: 52px;
  font-weight: 600;
}
.rs-shop-part .product-list .content-desc .loop-product-title a:hover {
  color: #106eea;
}
.rs-shop-part .product-list .content-desc .price {
  font-size: 20px;
  line-height: 30px;
  color: #106eea;
  font-weight: 700;
  margin: 0;
}
.rs-shop-part .product-list .content-desc .price del {
  opacity: 0.6;
  padding-right: 10px;
}
.rs-shop-part .product-list:hover .image-product .overley i {
  opacity: 1;
  visibility: visible;
  bottom: 13px;
}
/*----------------------------------------
    31. Single Shop Section CSS
----------------------------------------*/
.rs-single-shop .single-product-image .images-single {
  z-index: 1 !important;
}
.rs-single-shop .single-product-image img {
  width: 100%;
}
.rs-single-shop .single-price-info .product-title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 12px;
}
.rs-single-shop .single-price-info .single-price {
  color: #106eea;
  font-weight: 600;
}
.rs-single-shop .single-price-info .some-text {
  margin-top: 15px;
}
.rs-single-shop .single-price-info form {
  margin-bottom: 30px;
}
.rs-single-shop .single-price-info form input {
  height: 40px;
  width: 70px;
  line-height: 40px;
  text-align: center;
  padding-left: 10px;
  border: 1px solid rgba(54, 54, 54, 0.1);
  outline: none;
}
.rs-single-shop .single-price-info p.category {
  margin: 0;
  padding-top: 25px;
  border-top: 1px solid #e6e6e6;
  font-size: 14px;
}
.rs-single-shop .single-price-info p.category span {
  font-weight: 700;
  padding-right: 10px;
}
.rs-single-shop .single-price-info p.category a {
  color: #454545;
}
.rs-single-shop .single-price-info p.category a:hover {
  color: #106eea;
}
.rs-single-shop .single-price-info .tag {
  margin: 0;
}
.rs-single-shop .single-price-info .tag span {
  font-weight: 700;
}
.rs-single-shop .tab-area {
  margin-top: 50px;
}
.rs-single-shop .tab-area ul.nav-tabs {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 32px;
}
.rs-single-shop .tab-area ul.nav-tabs li {
  margin-right: 3px;
}
.rs-single-shop .tab-area ul.nav-tabs li .nav-link {
  padding: 10px 30px;
  display: inline-block;
  border-radius: 4px 4px 0 0;
  font-size: 18px;
  background: #f0f0f0;
  color: #101010;
  font-weight: 600;
}
.rs-single-shop .tab-area ul.nav-tabs li .nav-link:hover,
.rs-single-shop .tab-area ul.nav-tabs li .nav-link.active {
  background: #106eea;
  color: #ffffff;
}
.rs-single-shop .tab-area ul.nav-tabs li:last-child {
  margin-right: 0;
}
.rs-single-shop .tab-area .tab-content .tab-pane .tab-title {
  font-weight: 700;
  margin-bottom: 34px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .dsc-p {
  margin: 0;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .top-area {
  margin-bottom: 5px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .top-area p {
  margin-bottom: 23px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .top-area h6 {
  font-size: 15px;
  font-weight: 600;
  color: #454545;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form {
  max-width: 600px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form p.comment-notes {
  margin-bottom: 15px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .placeholder-cntrl label {
  font-weight: 600;
  display: block;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .placeholder-cntrl input {
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .placeholder-cntrl textarea {
  width: 100%;
  padding: 10px 15px;
  height: 75px;
  border: 1px solid rgba(54, 54, 54, 0.1);
  margin-bottom: 20px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings {
  font-weight: 600;
  margin-bottom: 18px;
  display: block;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings ul li {
  display: inline;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings ul li i {
  color: #106eea;
  font-weight: normal;
}
/*----------------------------------------
    32. Cart Section CSS
----------------------------------------*/
.rs-cart .cart-wrap table.cart-table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  border-collapse: collapse;
}
.rs-cart .cart-wrap table.cart-table td,
.rs-cart .cart-wrap table.cart-table th {
  padding: 25px;
  text-align: center;
  border: 1px solid #ccc;
}
.rs-cart .cart-wrap table.cart-table th {
  border: none;
  font-size: 18px;
  padding: 25px;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
}
.rs-cart .cart-wrap table.cart-table td {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 1px solid #e6e6e6;
}
.rs-cart .cart-wrap table.cart-table .product-remove a {
  margin: 0 auto;
  color: #101010;
  display: block;
  border-radius: 100%;
  border: 1px solid #ddd;
  font-size: 16px;
  font-weight: 400;
  height: 20px;
  width: 20px;
  line-height: 17px;
  text-align: center;
}
.rs-cart .cart-wrap table.cart-table .product-remove a:hover {
  background: #ff0000;
  color: #ffffff;
}
.rs-cart .cart-wrap table.cart-table .product-thumbnail {
  min-width: 32px;
}
.rs-cart .cart-wrap table.cart-table .product-thumbnail a img {
  width: 80px;
  height: auto;
}
.rs-cart .cart-wrap table.cart-table .product-name a {
  color: #454545;
  font-weight: 700;
}
.rs-cart .cart-wrap table.cart-table .product-name a:hover {
  color: #106eea;
}
.rs-cart .cart-wrap table.cart-table .product-price {
  font-weight: 700;
}
.rs-cart .cart-wrap table.cart-table .product-quantity input {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 0 0 0 10px;
  max-width: 65px;
  margin: 0 auto;
  outline: none;
}
.rs-cart .cart-wrap table.cart-table .action .coupon {
  float: left;
}
.rs-cart .cart-wrap table.cart-table .action .coupon input {
  box-sizing: border-box;
  border: 1px solid #d3ced2;
  padding: 6px 6px 5px;
  margin: 0 4px 0 0;
  outline: none;
  width: 320px;
  border-radius: 4px;
  height: 45px;
  margin-right: 20px;
  text-align: left;
  padding-left: 22px;
}
.rs-cart .cart-collaterals {
  width: 100%;
  overflow: hidden;
}
.rs-cart .cart-collaterals .cart-totals {
  float: right;
  width: 48%;
}
.rs-cart .cart-collaterals .cart-totals .title {
  font-weight: 700;
  color: #454545;
  text-transform: capitalize;
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table {
  text-align: left;
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr th {
  font-size: 18px;
  font-weight: 700;
}
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr td,
.rs-cart .cart-collaterals .cart-totals table.cart-total-table tr th {
  padding: 30px 10px;
}
/*----------------------------------------
    33. Checkout Section CSS
----------------------------------------*/
.rs-checkout .checkout-title {
  margin-bottom: 30px;
}
.rs-checkout .checkout-title h3 {
  font-size: 26px;
  margin: 0;
}
.rs-checkout .coupon-toggle .accordion .card {
  border: unset;
  border-top: 3px solid #106eea;
  border-radius: 0;
}
.rs-checkout .coupon-toggle .accordion .card .card-header {
  border: none;
  margin: 0;
  border-radius: unset;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title {
  margin: 0;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title span i {
  margin-right: 10px;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title button {
  background: unset;
  border: none;
  color: #106eea;
  transition: all 0.3s ease;
  outline: none;
  cursor: pointer;
}
.rs-checkout .coupon-toggle .accordion .card .card-header .card-title button:hover {
  color: #106eea;
}
.rs-checkout .coupon-toggle .accordion .card .card-body {
  border: 1px solid #d3ced2;
  padding: 20px 20px 50px 20px;
  margin-top: 2em;
  text-align: left;
}
.rs-checkout .coupon-toggle .accordion .card .card-body .coupon-code-input {
  width: 47%;
  float: left;
  margin-right: 50px;
}
.rs-checkout .coupon-toggle .accordion .card .card-body .coupon-code-input input {
  width: 100%;
  height: 45px;
  outline: none;
  padding: 10px 18px;
  color: #454545;
  border: 1px solid rgba(54, 54, 54, 0.1);
}
.rs-checkout .full-grid {
  margin-top: 25px;
}
.rs-checkout .full-grid .form-content-box {
  margin-bottom: 50px;
}
.rs-checkout .full-grid .form-content-box .form-group label {
  line-height: 2;
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}
.rs-checkout .full-grid .form-content-box .form-group select {
  color: #666666;
  opacity: .5;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #ccc;
  border-radius: 0;
  height: 45px;
  line-height: 45px;
  cursor: pointer;
  width: 100%;
  outline: none;
}
.rs-checkout .full-grid .form-content-box .form-group textarea {
  height: 4em;
  line-height: 1.5;
  display: block;
  box-shadow: none;
  width: 100%;
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 10px 18px;
  margin: 0;
  outline: none;
}
.rs-checkout .full-grid .form-content-box .form-group .form-control-mod {
  border: 1px solid rgba(54, 54, 54, 0.1);
  height: 45px;
  padding: 10px 18px;
  width: 100%;
  margin: 0;
  outline: none;
  line-height: normal;
  border-radius: unset;
}
.rs-checkout .full-grid .form-content-box .form-group .form-control-mod.margin-bottom {
  margin-bottom: 10px !important;
}
.rs-checkout .full-grid .ordered-product table {
  width: 100%;
}
.rs-checkout .full-grid .ordered-product table tr th {
  border: 1px solid #ccc;
  padding: 9px 12px;
}
.rs-checkout .full-grid .ordered-product table tr td {
  border: 1px solid #ccc;
  padding: 6px 12px;
}
.rs-checkout .full-grid .payment-method .top-area {
  border-bottom: 1px solid #d3ced2;
}
.rs-checkout .full-grid .payment-method .top-area .payment-co {
  margin-bottom: 20px;
}
.rs-checkout .full-grid .payment-method .top-area .payment-co span {
  font-weight: 600;
  margin-right: 10px;
}
.rs-checkout .full-grid .payment-method .top-area .p-msg {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  margin: 1em 0 2em;
  font-size: .92em;
  border-radius: 2px;
  line-height: 1.5;
  background-color: #f9f9f9;
}
.rs-checkout .full-grid .payment-method .top-area .p-msg:before {
  content: '';
  display: block;
  border: 1em solid #f9f9f9;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  top: -0.75em;
  left: 0;
  margin: -1em 0 0 2em;
}
/*----------------------------------------
    34. Account Section CSS
----------------------------------------*/
.rs-my-account .title {
  font-size: 42px;
  line-height: 52px;
  font-weight: 600;
  color: #101010;
  margin: 0;
}
.rs-my-account .rs-login {
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 20px;
  text-align: left;
  border-radius: 5px;
}
.rs-my-account .rs-login .form-group label {
  display: block;
  font-size: 15px;
  line-height: 30px;
  font-weight: 600;
  color: #454545;
}
.rs-my-account .rs-login .form-group label span {
  color: #106eea;
}
.rs-my-account .rs-login .form-group input {
  width: 100%;
  margin: 0;
  outline: 0;
  line-height: normal;
  border: 1px solid rgba(54, 54, 54, 0.1);
  height: 45px;
}
.rs-my-account .rs-login .last-password a {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #106eea;
}
.rs-my-account .rs-login p {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #454545;
  margin-bottom: 14px;
}
.rs-my-account .rs-login p a {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #106eea;
}
/*------------------------------------
    35. Footer Section CSS
------------------------------------*/

.footer-at-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px !important;
  margin: 0px !important;
}
.footer-at-bottom li {
  margin-right: 5px;
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    color: #555;
    text-align: center;
    font-size: 15px;
    transition: all .8s ease;
    background: #f7e3c1;
}
.rs-footer {
   background: url(../images/bg/footer-bg.png);
  background-color: #F5F6F9;
  background-size: cover;
  background-repeat: no-repeat;
 /* background-position: center; */
}

.rs-footer .footer-top {
  padding: 92px 0 65px;
}
.rs-footer .footer-top .footer-logo img {
  height: 40px;
}
.rs-footer .footer-top .widget-title {
  font-size: 22px;
  line-height: 40px;
  font-weight: 600;
  color: #101010;
  margin-bottom: 25px;
}
.rs-footer .footer-top .site-map li {
  margin-bottom: 12px;
}
.rs-footer .footer-top .site-map li a {
  font-size: 16px;
  color: #454545;
}
.rs-footer .footer-top .site-map li a:hover {
  color: #106eea;
}
.rs-footer .footer-top .footer-social li {
  display: inline;
  margin-right: 5px;
}
.rs-footer .footer-top .footer-social li a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  color: #555;
  text-align: center;
  font-size: 15px;
  transition: all .8s ease;
  background: rgba(255, 255, 255, 0.9);
}
.rs-footer .footer-top .footer-social li a i {
  line-height: 35px;
}
.rs-footer .footer-top .footer-social li a:hover {
  color: #ffffff;
  background: #106eea;
}
.rs-footer .footer-top .footer-social li:last-child {
  margin: 0;
}
.rs-footer .footer-top .address-widget li {
  padding: 0px 0px 16px 40px;
  position: relative;
}
.rs-footer .footer-top .address-widget li .desc a {
  color: #454545;
}
.rs-footer .footer-top .address-widget li .desc a:hover {
  color: #106eea;
}
.rs-footer .footer-top .address-widget li i {
  color: #106eea;
  position: absolute;
  left: 0px;
}
.rs-footer .footer-top .address-widget li i:before {
  font-size: 20px;
}
.rs-footer .footer-top .widget-desc {
  margin: 0;
}
.rs-footer .footer-top p {
  margin: 30px 0 0;
  position: relative;
}
.rs-footer .footer-top p input[type=email] {
  border: none;
  width: 91%;
  font-size: 13px;
  padding: 16px 60px 16px 20px;
  margin: 0;
  color: #0a0a0a;
  overflow: hidden;
  border-radius: 30px 0 0 30px;
  background: #fff;
  box-shadow: 0 0 25px #eeeeee;
}
.rs-footer .footer-top p input[type="submit"] {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  background-image: linear-gradient(90deg, #03228f 0%, #0e73e4 100%);
  border-radius: 50%;
  padding: 15px 28px 15px;
  font-family: "Flaticon";
  font-size: 0;
  border: none;
}
.rs-footer .footer-top p i {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 12px 20px;
  pointer-events: none;
  color: #ffffff;
}
.rs-footer .footer-top p ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #454545;
  opacity: 1;
}
.rs-footer .footer-top p ::-moz-placeholder {
  /* Firefox 19+ */

  color: #454545;
  opacity: 1;
}
.rs-footer .footer-top p :-ms-input-placeholder {
  /* IE 10+ */

  color: #454545;
  opacity: 1;
}
.rs-footer .footer-top p :-moz-placeholder {
  /* Firefox 18- */

  color: #454545;
  opacity: 1;
}
.rs-footer .footer-bottom {
  padding: 20px 0;
  background: rgba(255, 255, 255, 0.4);
}
.rs-footer .footer-bottom .copyright p {
  margin: 0;
}
.rs-footer .footer-bottom .copyright p a {
  color: #454545;
}
.rs-footer .footer-bottom .copyright p a:hover {
  color: #106eea;
}
.rs-footer .footer-bottom .copy-right-menu li {
  display: inline-block;
  position: relative;
  padding: 0px 19px 0 25px;
}
.rs-footer .footer-bottom .copy-right-menu li a {
  color: #454545;
}
.rs-footer .footer-bottom .copy-right-menu li a:hover {
  color: #106eea;
}
.rs-footer .footer-bottom .copy-right-menu li:before {
  display: block;
  content: "";
  position: absolute;
  font-size: 18px;
  background: #106eea;
  left: 0;
  top: 50%;
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  border-radius: 50%;
}
.rs-footer .footer-bottom .copy-right-menu li:first-child:before {
  display: none;
}
.rs-footer.style1 .footer-top .widget-title {
  color: #102b3e;
}
.rs-footer.style1 .footer-top .site-map li a:hover {
  color: #f2541b;
}
.rs-footer.style1 .footer-top .footer-social li a:hover {
  background: #f2541b;
}
.rs-footer.style1 .footer-top .address-widget li .desc a:hover {
  color: #f2541b;
}
.rs-footer.style1 .footer-top .address-widget li i {
  color: #f2541b;
}
.rs-footer.style1 .footer-top p input[type="submit"] {
  background-image: linear-gradient(180deg, #dd4c23 19%, #f27c1e 100%);
}
.rs-footer.style1 .footer-bottom .copyright p a:hover {
  color: #f2541b;
}
.rs-footer.style1 .footer-bottom .copy-right-menu li a:hover {
  color: #f2541b;
}
.rs-footer.style1 .footer-bottom .copy-right-menu li:before {
  background: #f2541b;
}
.rs-footer.style1.home14-footer-style .footer-bottom .copyright p a:hover,
.rs-footer.style1.home14-footer-style .footer-top .address-widget li .desc a:hover,
.rs-footer.style1.home14-footer-style .footer-top .address-widget li i,
.rs-footer.style1.home14-footer-style .footer-top .site-map li a:hover {
  color: #ff7045;
}
.rs-footer.style1.home14-footer-style .footer-top p input[type="submit"],
.rs-footer.style1.home14-footer-style .footer-bottom .copy-right-menu li:before,
.rs-footer.style1.home14-footer-style .footer-top .footer-social li a:hover {
  background: #ff7045;
}
.rs-footer.style2 {
  background: url(../images/bg/footer-bg2.jpg);
  background-color: #F5F6F9;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.rs-footer.style2 .footer-top {
  padding: 92px 0 65px;
}
.rs-footer.style2 .footer-top .footer-logo img {
  height: 40px;
}
.rs-footer.style2 .footer-top .widget-title {
  color: #ffffff;
}
.rs-footer.style2 .footer-top .site-map li a {
  color: #ffffff;
}
.rs-footer.style2 .footer-top .site-map li a:hover {
  color: #03228f;
}
.rs-footer.style2 .footer-top .footer-social li {
  display: inline;
  margin-right: 5px;
}
.rs-footer.style2 .footer-top .footer-social li a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  color: #555;
  text-align: center;
  font-size: 15px;
  transition: all .8s ease;
  background: rgba(255, 255, 255, 0.9);
}
.rs-footer.style2 .footer-top .footer-social li a:hover {
  color: #ffffff;
  background: #106eea;
}
.rs-footer.style2 .footer-top .footer-social li:last-child {
  margin: 0;
}
.rs-footer.style2 .footer-top .address-widget li {
  padding: 0px 0px 16px 40px;
  position: relative;
}
.rs-footer.style2 .footer-top .address-widget li .desc {
  color: #ffffff;
}
.rs-footer.style2 .footer-top .address-widget li .desc a {
  color: #ffffff;
}
.rs-footer.style2 .footer-top .address-widget li .desc a:hover {
  color: #03228f;
}
.rs-footer.style2 .footer-top .widget-desc {
  margin: 0;
}
.rs-footer.style2 .footer-top p input[type=email] {
  box-shadow: unset;
}
.rs-footer.style2 .footer-bottom {
  background: transparent;
  border-color: rgba(80, 90, 100, 0.56) !important;
  border-top: 1px solid;
}
.rs-footer.style2 .footer-bottom .copyright p {
  color: #ffffff;
}
.rs-footer.style2 .footer-bottom .copyright p a {
  color: #ffffff;
}
.rs-footer.style2 .footer-bottom .copyright p a:hover {
  color: #03228f;
}
.rs-footer.style2 .footer-bottom .copy-right-menu li a {
  color: #ffffff;
}
.rs-footer.style2.modify1 {
  background: unset;
  background-color: unset;
}
.rs-footer.style2.modify1 .footer-top .site-map li a:hover {
  color: #1273EB;
}
.rs-footer.style2.modify1 .footer-top .address-widget li i:before {
  color: #fd6509;
}
.rs-footer.style2.modify1 .footer-top .address-widget li .desc a:hover {
  color: #1273EB;
}
.rs-footer.style2.modify1 .footer-bottom {
  background: #000000;
  border-color: unset;
  border-top: unset;
}
.rs-footer.style2.modify1 .footer-bottom .copyright p {
  color: #ffffff;
}
.rs-footer.style2.modify1 .footer-bottom .copyright p a {
  color: #ffffff;
}
.rs-footer.style2.modify1 .footer-bottom .copyright p a:hover {
  color: #1273EB;
}
.rs-footer.style2.modify1 .footer-bottom .copy-right-menu li a {
  color: #ffffff;
}
.rs-footer.style2.modify1 .footer-bottom .copy-right-menu li a:hover {
  color: #1273EB;
}
.rs-footer.style3 {
  background: #F5F6F9;
  background-size: cover;
}
.rs-footer.style3 .footer-top .widget-title {
  color: #102b3e;
}
.rs-footer.style3 .footer-top .site-map li a:hover {
  color: #1273EB;
}
.rs-footer.style3 .footer-top .footer-social li a:hover {
  background: #787cf2;
}
.rs-footer.style3 .footer-top .address-widget li .desc a:hover {
  color: #1273EB;
}
.rs-footer.style3 .footer-top .address-widget li i {
  color: #1273EB;
}
.rs-footer.style3 .footer-top p input[type="submit"] {
  background: #787cf2;
}
.rs-footer.style3 .footer-top p input[type="submit"]:hover {
  opacity: .9;
}
.rs-footer.style3 .footer-bottom .copyright p a:hover {
  color: #1273EB;
}
.rs-footer.style3 .footer-bottom .copy-right-menu li a:hover {
  color: #1273EB;
}
.rs-footer.style3 .footer-bottom .copy-right-menu li:before {
  background: #787cf2;
}
.rs-footer.style3.onepage-style4 {
  background: url(../images/bg/footer-bg.png);
  background-color: #F5F6F9;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/* -----------------------
    36. Error Page CSS
--------------------------*/
.page-error {
  background: url(../images/bg/error.jpg);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.page-error .error-not-found {
  position: relative;
  background: #cfd0ef;
  width: 650px;
  padding: 50px;
  height: 650px;
  border-radius: 50%;
  margin: 0 auto;
  text-align: center;
}
.page-error .error-not-found .error-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.page-error .error-not-found .title {
  font-size: 35px;
  line-height: 52px;
  font-weight: 700;
  color: #101010;
  margin-bottom: 30px;
}
.page-error .error-not-found .title span {
  font-size: 251px;
  line-height: 171px;
  font-weight: 700;
  color: #101010;
  display: block;
  padding-bottom: 70px;
}
/* -----------------------
    37. Scroll Up CSS
--------------------------*/
#scrollUp {
  text-align: center;
  bottom: 40px;
  cursor: pointer;
  display: none;
  position: fixed;
  right: 20px;
  z-index: 999;
  border-radius: 50px 50px 4px 4px;
}
#scrollUp i {
  background-image: linear-gradient(90deg, #03228f 0%, #0e73e4 100%);
  border-radius: 50%;
  height: 40px;
  font-size: 24px;
  font-weight: 600;
  width: 42px;
  color: #fff;
  line-height: 36px;
  transition: all 0.3s ease;
  margin-left: 2px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}
#scrollUp i:hover {
  opacity: 0.8;
}
#scrollUp.oranage i {
  background-image: linear-gradient(180deg, #dd4c23 19%, #f27c1e 100%);
}
#scrollUp.oranage2 i {
  background: #ff7045;
}
#scrollUp.purple-color i {
  background: #787cf2;
}
/* ------------------------------------
    38. Preloader CSS
---------------------------------------*/
.loader {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0;
  top: 0;
  z-index: 999999;
}
.loader .loader-container {
  width: 50px;
  height: 50px;
  background-color: #106eea;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader.orange-loader .loader-container {
  background: #ff7045;
}
@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
/* ------------------------------------
    39. Animation Part
---------------------------------------*/
/*Pulse Border Animation*/
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.move-y {
  animation: move-y 2s alternate infinite;
  -webkit-animation: move-y 2s alternate infinite;
}
@keyframes move-y {
  0% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
    -webkit-transform: translate3d(0, 10px, 0);
  }
}
.dance3 {
  -webkit-animation: dance3 8s alternate infinite;
}
@keyframes dance3 {
  0% {
    -webkit-transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
.dance {
  -webkit-animation: dance 2s alternate infinite;
}
@keyframes dance {
  0% {
    -webkit-transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
.dance2 {
  -webkit-animation: dance2 4s alternate infinite;
}
@keyframes dance2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(15px, -15px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -15px, 15px);
  }
}
.watermark-animate {
  -webkit-animation: watermark-animate 8s infinite;
  animation: watermark-animate 8s infinite;
  animation-direction: alternate-reverse;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes watermark-animate {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
.rs-rain-animate {
  position: relative;
}
.rs-rain-animate .container {
  position: relative;
  z-index: 1;
}
.rs-rain-animate .line-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: calc(100%);
  pointer-events: none;
  overflow: hidden;
  z-index: 0;
  max-width: 1240px;
}
.rs-rain-animate .line-inner .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: calc(50%);
  background: #fff;
  z-index: 1;
}
.rs-rain-animate .line-inner .line:after {
  content: "";
  display: block;
  position: absolute;
  height: 80px;
  width: 3px;
  top: -20%;
  left: -1px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 39%, #1273eb 130%, #1273eb 59%);
  background-color: rgba(0, 0, 0, 0);
  -webkit-animation: rain-line 13s 0s linear infinite;
  animation: rain-line 13s 0s linear infinite;
  animation-delay: 0s;
}
.rs-rain-animate .line-inner .line:nth-child(1) {
  left: 2px !important;
}
.rs-rain-animate .line-inner .line:nth-child(1):after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.rs-rain-animate .line-inner .line:nth-child(3) {
  left: unset !important;
  right: 2px;
}
.rs-rain-animate .line-inner .line:nth-child(3):after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
.rs-rain-animate .line-inner.style2 .line {
  background: rgba(255, 255, 255, 0.1);
}
.rs-rain-animate .line-inner.style2 .line:after {
  background-color: #fff;
}
.rs-rain-animate .line-inner.orange-dark .line {
  background: rgba(255, 255, 255, 0.1);
}
.rs-rain-animate .line-inner.orange-dark .line:after {
  background-image: linear-gradient(220deg, #f27c1e 0%, #dd4c23 79%);
}
@keyframes rain-line {
  0% {
    top: -20%;
  }
  100% {
    top: 100%;
  }
}
@-webkit-keyframes rain-line {
  0% {
    top: -20%;
  }
  100% {
    top: 100%;
  }
}
.up-down {
  animation: up-down 3s alternate infinite;
  -webkit-animation: up-down 3s alternate infinite;
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
    -webkit-transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(30px);
    -webkit-transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
.horizontal {
  animation-name: horizontal;
  animation-duration: 12s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.horizontal.new-style {
  animation-duration: 15s;
}
@keyframes horizontal {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}
.vertical {
  animation-name: vertical;
  animation-duration: 12s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.vertical.new-style {
  animation-duration: 15s;
}
@keyframes vertical {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
.rotated-style {
  animation: rotated-style 5s linear infinite;
}
@keyframes rotated-style {
  0% {
    -webkit-transform: translate(0) rotate(0);
    transform: translate(0) rotate(0);
  }
  25% {
    transform: translate(-10px, 10px) rotate(10deg);
  }
  50% {
    transform: translate(5px, -5px) rotate(25deg);
  }
  75% {
    transform: translate(15px, -5px) rotate(15deg);
  }
  100% {
    transform: translate(0) rotate(0);
  }
}
.fadeinup {
  animation-duration: 2s;
  animation-name: fadeInUp;
}
@keyframes fadeinup {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeinup2 {
  animation-duration: 3s;
  animation-name: fadeInUp;
}
@keyframes fadeinup2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  00% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRight {
  animation-duration: 2s;
  animation-name: fadeInRight;
}
@keyframes  {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
